import axios, { AxiosInstance } from "axios";

const useAxios: AxiosInstance = axios.create({
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
  timeout: 8000,
});

export { useAxios };
