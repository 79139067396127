import { Grid, Stack, Typography } from "@mui/material";

interface IProps {
  icon: any;
  text: string;
  width: any;
  notification?: any;
  onClick: VoidFunction;
}

export function CardJourney({
  icon,
  text,
  width,
  notification,
  onClick,
}: IProps) {
  return (
    <Stack
      width={width ? width : "100%"}
      minWidth={{ xs: "120px", sm: "120px" }}
      gap={2}
      height={{ xs: "100px", md: "120px" }}
      alignItems="flex-start"
      justifyContent="center"
      border="1px solid"
      borderRadius="10px"
      borderColor="neutral400.main"
      padding={{ xs: 1, md: 2 }}
      onClick={onClick}
      boxShadow={1}
      sx={{
        backgroundColor: "neutral0.main",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <Grid container>
        <Grid item xs={9}>
          {icon}
        </Grid>
        <Grid item xs={3}>
          {notification && (
            <Stack
              width="20px"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              sx={{ backgroundColor: "error700.main" }}
            >
              <Typography
                fontWeight={600}
                variant="body1"
                color="neutral0.main"
              >
                {notification}
              </Typography>
            </Stack>
          )}
        </Grid>
      </Grid>

      <Typography
        variant="body2"
        width={{ xs: "75px", sm: "fit-content" }}
        // sx={{
        //   overflow: "hidden",
        //   textOverflow: "ellipsis",
        // }}
      >
        {text}
      </Typography>
    </Stack>
  );
}
