import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";

import { useState } from "react";
import dayjs from "dayjs";
import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import { GenericTimeline } from "../GenericTimeline";
import { AnamneseHistoryAccordion } from "./AnamneseHistoryAccordion";

export function AnamneseHistory({ selectedField }: any) {
  const [filter, setFilter] = useState<any>(2);
  const { response } = useQuestionnaireContext();
  const responseEntries = response?.entry?.map((el: any) => el?.resource);
  const anamneseResponses = responseEntries?.map((el: any) => {
    const awnserDate = el?.authored;
    const filterItens = el?.item?.filter((e: any) => e?.text === "Anamnese");
    const displayReferences = el?.item?.filter(
      (e: any) => e?.linkId === "displayReferences"
    )?.[0]?.item;
    const displayInfo = {
      healthcare: displayReferences?.[0]?.answer?.[0]?.valueString || "",
      practitioner: displayReferences?.[1]?.answer?.[0].valueString || "",
    };
    return { awnserDate, filterItens, displayInfo };
  });

  const awnsers = anamneseResponses?.map((el: any) => {
    const awnserDate = el.awnserDate;
    const displayInfo = el.displayInfo;
    const filterResponse = el?.filterItens?.[0]?.item;
    return { awnserDate, filterResponse, displayInfo };
  });
  // const filterByItem = filterGeral?.map((el: any) => {
  //   console.log("el", el);
  //   const auxAwnser = el?.filter((e: any) => e.linkId === selectedField);

  //   return auxAwnser;
  // });

  // filter === 1 ? filterByItem : filterGeral;

  return (
    <Stack>
      {/* <Stack
        justifyContent="right"
        direction="row"
        width="100%"
        alignItems="center"
        spacing={1}
      >
        <FormLabel component="legend">Exibir:</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          aria-label="position"
          onChange={(e) => setFilter(Number(e?.target?.value))}
          defaultValue={1}
          value={filter}
        >
          <FormControlLabel
            key={1}
            value={1}
            control={<Radio />}
            label="Item"
          />
          <FormControlLabel
            key={2}
            value={2}
            control={<Radio />}
            label="Geral"
          />
        </RadioGroup>
      </Stack> */}
      <GenericTimeline
        data={awnsers}
        component={(data: any, index: number) => (
          <AnamneseHistoryAccordion
            filter={filter}
            data={data}
            index={index}
            selectedField={selectedField}
          />
        )}
      />
    </Stack>
  );
}
