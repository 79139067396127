import { toast } from "react-toastify";
import { endpoints } from "../../../../configs/Settings";
import { HTTPResponseStatus } from "../../../../utils/enum";

import { useAuth } from "../../../../utils/useAuth";
import { useAxios } from "../../../../utils/useAxios";
import { auxiliarData } from "../../AuxiliarData";

export async function PostDocumentReference(
  objData: any,
  encounter: any,
  refs: any,
  signed?: boolean
) {
  let url = `${endpoints.XDSB_domain}/mhd/fhir`;

  const data = auxiliarData.DocumentReference(objData, encounter, refs, signed);
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+xml",
  };

  const response = await useAxios
    .post(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (
        response.status !== HTTPResponseStatus.Created &&
        response.status !== HTTPResponseStatus.Success
      ) {
        throw new Error(`PostDocumentReference: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("PostDocumentReference ", error);
      // toast.error(`PostDocumentReference Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
