import {
  Box,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useSessionContext } from "../../../contexts/SessionContext";
import { CardRow, EditableCardRow } from "../../../components/common";

import { useEffect, useState } from "react";
import { oids } from "../../../configs/Settings";
import { GetPractitionerRole } from "../../../services/fhir";
import { useDict } from "../../../utils/useDict";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";

interface Estado {
  id: string;
  roles: any;
  specialty: any;
  crm?: string | undefined;
}

export function CardProfessional({
  setIsEditPracititionerRolesOpen,
  setSelectPractitionerRole,
}: any) {
  const { user, roles } = useSessionContext();

  const { organization, organizationList } = useOrganizationContext();
  const [info, setInfo] = useState<Estado[]>([]);

  const selectedPractitionerRole = info.find((el: any) =>
    organization?.id?.includes(el.id.split("-")[0])
  );

  useEffect(() => {
    async function assyncCall() {
      const practitionerRolesReponse = await GetPractitionerRole({
        practitioner: `${oids.cpf}-${user.username}`,
      });
      setInfo(
        practitionerRolesReponse.entry.map((el: any) => {
          return {
            id: el.resource.id,
            roles: el.resource.code,
            specialty: el.resource.specialty,
            crm: el.resource?.identifier?.find((id: any) => id.system === "crm")
              ?.value,
          };
        })
      );
    }
    assyncCall();
  }, []);
  return (
    <Stack
      sx={{
        border: "1px solid",
        borderColor: "neutral700.main",
        borderRadius: "15px",
      }}
      width="100%"
      paddingTop={2}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{ paddingY: 3, paddingLeft: 5, paddingRight: 3 }}
        spacing={1}
      >
        <Typography width="80%" variant="h5" fontWeight="500" paddingBottom={3}>
          Informações Profissionais
        </Typography>
        <Box sx={{ minWidth: 220 }}>
          <FormControl fullWidth>
            <Select
              value={organization?.id?.split("-")[1]}
              // onChange={handleChange}
              inputProps={{ readOnly: true }}
              IconComponent={"span"}
            >
              {roles?.map((el: any) => {
                const auxOrganization = organizationList?.find(
                  (e: fhir5.BundleEntry<fhir5.Organization>) =>
                    e?.resource?.id ===
                    el?.organization?.reference?.split("/")[1]
                );
                return (
                  <MenuItem
                    value={
                      el.organization?.reference?.split("/")[1]?.split("-")[1]
                    }
                  >
                    {auxOrganization?.resource?.alias?.[0] ||
                      auxOrganization?.resource?.name ||
                      el?.organization?.display ||
                      "-"}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Stack>
      <CardRow title="CRM" titleContext={selectedPractitionerRole?.crm} />
      <Divider sx={{ marginLeft: 5 }} />
      <CardRow
        title="Atividade"
        titleContext={selectedPractitionerRole?.roles
          .map((el: any) => useDict.roles[el?.coding[0].system])
          .join(", ")}
      />
      <Divider sx={{ marginLeft: 5 }} />
      <EditableCardRow
        title="Especialidade"
        titleContext={selectedPractitionerRole?.specialty
          ?.map((el: any) => el?.coding[0].display)
          .join(", ")}
        onClick={() => {
          setSelectPractitionerRole(selectedPractitionerRole);
          setIsEditPracititionerRolesOpen(true);
        }}
      />
      <Divider sx={{ marginLeft: 5 }} />
    </Stack>
  );
}
