import { ArrowForward } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";

interface IProps {
  onClick: VoidFunction;
}

export function AuxiliarButtonHealthMedicine({ onClick }: IProps) {
  return (
    <IconButton sx={{ marginTop: 1 }} onClick={onClick}>
      <Typography variant="body2" color="primary700.main">
        Saiba mais
      </Typography>
      <ArrowForward fontSize="small" sx={{ color: "primary700.main" }} />
    </IconButton>
  );
}
