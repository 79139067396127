import { ServiceRequest } from "./patientSummaryAccordions/ServiceRequest/ServiceRequest";
import { useFhirData } from "../../../utils/useFhirData";
import { Prescriptions } from "./patientSummaryAccordions/Prescriptions/Prescriptions";
import { Diagnosis } from "./patientSummaryAccordions/Diagnosis/Diagnosis";
import { QuestionnaireResponse } from "./patientSummaryAccordions/QuestionnaireResponse/QuestionnaireResponse";

import { useState } from "react";
import { IframeDialog } from "./IframeDialog";
import { Allergies } from "./patientSummaryAccordions/Allergies/Allergies";
import { ProcedureRequest } from "./patientSummaryAccordions/ProcedureRequest/ProcedureRequest";
import { Documents } from "./patientSummaryAccordions/Documents/Documents";
import { useSessionContext } from "../../../contexts/SessionContext";

export function PatientSummary({ summary, setRefetch }: any) {
  const { access } = useSessionContext();
  const [open, setOpen] = useState<boolean>(false);
  const [binaryUrl, setBinaryUrl] = useState<string>("");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setBinaryUrl("");
    setOpen(false);
  };

  const prescriptionDocuments = useFhirData.extractPatientSummaryResource(
    summary,
    "DocumentReference",
    "prescricao"
  );

  const medicalNoteData = useFhirData.extractPatientSummaryResource(
    summary,
    "DocumentReference",
    "prescricao"
  )?.[0]?.entry;

  const solicitationData = useFhirData.extractPatientSummaryResource(
    summary,
    "DocumentReference",
    "service-request"
  )?.[0]?.entry;

  const NoteReportData = useFhirData.extractPatientSummaryResource(
    summary,
    "DocumentReference",
    "atestado"
  )?.[0]?.entry;

  const resultsData = useFhirData.extractPatientSummaryResource(
    summary,
    "DocumentReference",
    "resultado"
  );

  return (
    <>
      {access?.type === "professional" ? (
        <QuestionnaireResponse
          title="Atendimentos"
          data={useFhirData.extractPatientSummaryResource(
            summary,
            "QuestionnaireResponse"
          )}
        />
      ) : (
        <> </>
      )}
      <Prescriptions
        handleOpen={handleOpen}
        setBinaryUrl={setBinaryUrl}
        title="Medicamentos"
        data={
          useFhirData.extractPatientSummaryResource(
            summary,
            "MedicationRequest"
          )?.[0]?.entry
        }
        documentReference={prescriptionDocuments?.[0]?.entry?.filter(
          (el: any) => el?.resource?.resourceType === "DocumentReference"
        )}
      />

      <ServiceRequest
        setRefetch={setRefetch}
        handleOpen={handleOpen}
        setBinaryUrl={setBinaryUrl}
        title="Exames"
        exames={useFhirData.extractPatientSummaryResource(
          summary,
          "ServiceRequest"
        )}
        data={useFhirData.extractPatientSummaryResource(
          summary,
          "DocumentReference",
          "service-request"
        )}
        resultsData={resultsData}
      />
      <Diagnosis
        title="Diagnósticos"
        data={useFhirData.extractPatientSummaryResource(summary, "Condition")}
      />

      <Allergies
        title="Alergias"
        data={useFhirData.extractPatientSummaryResource(
          summary,
          "AllergyIntolerance"
        )}
      />

      <ProcedureRequest
        handleOpen={handleOpen}
        setBinaryUrl={setBinaryUrl}
        title="Procedimentos"
        procedimentos={useFhirData.extractPatientSummaryResource(
          summary,
          "ServiceRequest"
        )}
        data={useFhirData.extractPatientSummaryResource(
          summary,
          "DocumentReference",
          "service-request"
        )}
        resultsData={resultsData}
      />

      <Documents
        sign
        handleOpen={handleOpen}
        setBinaryUrl={setBinaryUrl}
        title="Documentos"
        medicalNoteData={medicalNoteData}
        solicitationData={solicitationData}
        resultsData={useFhirData.extractPatientSummaryResource(
          summary,
          "DocumentReference",
          "resultado"
        )}
        NoteReportData={NoteReportData}
        data={summary}
      />
      {access?.type === "professional" ? (
        <Documents
          handleOpen={handleOpen}
          setBinaryUrl={setBinaryUrl}
          title="Assinadura pendente"
          medicalNoteData={medicalNoteData?.filter((e: any) => {
            return (
              e?.resource?.securityLabel?.[0]?.coding?.[0]?.code !==
              "ProofOfOrigin"
            );
          })}
          solicitationData={solicitationData?.filter((e: any) => {
            return (
              e?.resource?.securityLabel?.[0]?.coding?.[0]?.code !==
              "ProofOfOrigin"
            );
          })}
          resultsData={useFhirData.extractPatientSummaryResource(
            summary,
            "DocumentReference",
            "resultado"
          )}
          NoteReportData={NoteReportData?.filter((e: any) => {
            return (
              e?.resource?.securityLabel?.[0]?.coding?.[0]?.code !==
              "ProofOfOrigin"
            );
          })}
          data={summary}
        />
      ) : (
        <></>
      )}

      {open && (
        <IframeDialog
          handleClose={handleClose}
          isOpen={open}
          data={binaryUrl}
        />
      )}
    </>
  );
}
