import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

export async function ChangePractitionerRoleActive(body: any, status: boolean) {
  let url = `${endpoints.ADMFHIR}/PractitionerRole/${body?.id}`;

  const data = body;

  data.active = status;

  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .put(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`UpdatePractitionerRoleStatus: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("ChangePractitionerRoleActive ", error);
      toast.error(
        `ChangePractitionerRoleActive Error: ${error?.response?.status}`
      );
      throw new Error();
    });
  if (response) return response;
}
