import { PrivateMainLayout } from "../../components/layout";
import {
  LoadingContent,
  PatientDashboard,
  ProfessionalDashboard,
} from "./components";
import { useSessionContext } from "../../contexts/SessionContext";
import { isMobile } from "react-device-detect";
import { Stack } from "@mui/material";
import { MobileBarNavigation } from "../../components/layout/private/main/MobileBarNavigation";

export function Home() {
  const { access, loading } = useSessionContext();
  return (
    <>
      <PrivateMainLayout dashboard noSidebar={isMobile} mobileView={isMobile}>
        {!loading && access.type === "patient" && <PatientDashboard />}
        {!loading && access.type === "professional" && (
          <ProfessionalDashboard />
        )}
        {(access.type === undefined || access.type === null || loading) && (
          <LoadingContent />
        )}
      </PrivateMainLayout>
      <Stack width="100%" sx={{ display: { xs: "block", lg: "none" } }} mt={5}>
        <MobileBarNavigation />
      </Stack>
    </>
  );
}
