import dayjs from "dayjs";

import { useFhirData } from "../../../utils/useFhirData";
import { useFormatter } from "../../../utils/useFormatter";
import { DataSkeleton } from "../../../components/common";
import { GenericTable } from "../../../components/GenericTable/GenericTable";
import { AppointmentsHistoryActions } from "./AppointmentsHistoryActions";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { WhatsApp } from "@mui/icons-material";

interface IProps {
  data: fhir5.Bundle<fhir5.Appointment> | undefined;
  loading: boolean;
  patients: fhir5.BundleEntry<fhir5.Patient>[] | undefined;
}

const columns = [
  { column: "Data", rowName: "date", width: "100px" },
  { column: "Hora", rowName: "hour", width: "75px" },
  { column: "Profissional", rowName: "practitioner" },
  { column: "Paciente", rowName: "patient" },
  { column: "Telefone", rowName: "contact" },
];

export function AppointmentsHistory({ data, loading, patients }: IProps) {
  const { formatDateTimeToHour } = useFormatter;
  const { formatNameDisplay } = useFormatter;

  if (loading) {
    return <DataSkeleton />;
  }

  const rows = data?.entry?.map((el) => {
    const practitioner = useFhirData.findParticipant(
      el?.resource?.participant,
      "Practitioner"
    );

    const patient = patients?.find((patient: any) => {
      const auxId = patient?.resource?.id;
      const auxReference = useFhirData.findParticipant(
        el?.resource?.participant,
        "Patient"
      )?.reference;

      return auxReference.includes(auxId);
    });

    const contact = patient?.resource?.telecom;

    const phone = contact?.find((e: any) => e.system === "phone")?.value;

    const hour = el?.resource?.start
      ? formatDateTimeToHour(el?.resource?.start)
      : "-";
    const date = el?.resource?.start
      ? dayjs(el?.resource?.start)?.format("DD/MM/YYYY ")
      : "-";

    return {
      date,
      hour,
      practitioner: formatNameDisplay(practitioner?.display),
      patient: formatNameDisplay(patient?.resource?.name?.[0]?.text || ""),
      ...(phone && {
        contact: (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
          >
            <Typography mt={0.5}>
              {useFormatter.formatPhoneNumber(phone || "")}
            </Typography>
            <Tooltip title={useFormatter.formatPhoneNumber(phone || "")}>
              <IconButton
                color="primary"
                onClick={() =>
                  window.open(
                    `https://web.whatsapp.com/send?phone=+55${phone}`,
                    "_blank"
                  )
                }
              >
                <WhatsApp />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      }),
      patientId: patient?.resource?.id,
    };
  });

  return (
    <GenericTable
      tableColumns={columns}
      tableRows={rows}
      actions={(rowData: any) => <AppointmentsHistoryActions row={rowData} />}
    />
  );
}
