import { Text, View, StyleSheet } from "@react-pdf/renderer";
import dayjs from "dayjs";

const styles = StyleSheet.create({
  sectionMedication: {
    padding: 40,
    height: "100%",
    flexDirection: "column",
    flexGrow: 1,
  },
  headerMedication: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    height: 80,
    textTransform: "uppercase",
    marginBottom: 20,
  },
  contentMedication: {
    // flexGrow: 1,
    marginBottom: 10,
  },
  footerMedication: {
    height: 80,
    marginBottom: 20,
  },
  titleMedication: {
    fontSize: 22,
    width: 240,
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
    textTransform: "uppercase",
  },
  text: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
    padding: "3px 0px",
  },
  textResponse: {
    fontSize: 12,
    fontFamily: "Helvetica",
    textTransform: "uppercase",
    // backgroundColor: "#e6e6e6",
  },
  textBold: {
    fontSize: 8,
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
  },
  textAuxiliar: {
    fontSize: 6,
    fontFamily: "Helvetica",
    textTransform: "lowercase",
  },
  centerText: {
    textAlign: "center",
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
  },
  pageBreak: {
    marginTop: 20,
    borderTop: "1px solid black",
    paddingTop: 20,
  },
  box: {
    marginBottom: 20,
  },
});

export function MedicationSpecialControl({
  filterSpecialMedication,
  dataPdf,
}: any) {
  const crm = dataPdf?.[0]?.professionalData?.identifier?.[0]?.value
    .split("/")[1]
    .slice(2);

  const ufCrm = dataPdf?.[0]?.professionalData?.identifier?.[0]?.value
    .split("/")[1]
    .slice(0, 2);

  return (
    <>
      {filterSpecialMedication.length > 0 &&
        filterSpecialMedication.map((el: any, index: number) => (
          <View style={styles.sectionMedication} key={index}>
            <View style={styles.headerMedication}>
              {/* <View style={{ width: 100 }}></View> */}
              <Text style={styles.titleMedication}>
                RECEITUÁRIO DE CONTROLE ESPECIAL
              </Text>
              {/* <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 100,
                  height: 36,
                  border: "1px solid #000",
                  gap: "1px",
                  marginRight: "-10px",
                }}
              >
                <Text style={styles.textBold}>Via digital</Text>
                <Text style={styles.textAuxiliar}>Validar em:</Text>
                <Text style={styles.textAuxiliar}>
                  https://assinaturadigital.iti.gov.br
                </Text>
              </View> */}
            </View>

            <View style={styles.contentMedication}>
              <View
                style={{
                  height: "20vh",
                  border: "1px solid #000",
                  marginBottom: 10,
                }}
              >
                <View
                  style={{
                    height: "2vh",
                    borderBottom: "1px solid #000",
                    marginTop: "1px",
                  }}
                >
                  <Text style={styles.centerText}>
                    IDENTIFICAÇÃO DO EMITENTE
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <View
                    style={{
                      width: "60%",
                      display: "flex",
                      justifyContent: "flex-start",
                      rowGap: "5px",
                    }}
                  >
                    <Text style={styles.text}>
                      NOME COMPLETO:
                      <Text style={styles.textResponse}>
                        {dataPdf?.[0]?.professionalData?.practitioner.display ||
                          ""}
                      </Text>
                    </Text>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <Text style={styles.text}>
                        CRM:{" "}
                        <Text style={styles.textResponse}>{crm || ""}</Text>
                      </Text>
                      <Text style={styles.text}>
                        UF:{" "}
                        <Text style={styles.textResponse}>{ufCrm || ""}</Text>
                      </Text>
                    </View>
                    <Text style={styles.text}>
                      CIDADE:{" "}
                      <Text style={styles.textResponse}>
                        {dataPdf?.[0]?.location?.address.city || "-"}
                      </Text>
                    </Text>
                    <Text style={styles.text}>
                      DATA:
                      <Text style={styles.textResponse}>
                        {dayjs().format("DD/MM/YYYY") || "-"}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "40%",
                      height: "16vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        height: "8vh",
                        width: "100%",
                        border: "1px solid #000",
                      }}
                    ></View>
                    <Text style={styles.text}>ASSINATURA MÉDICO(A)</Text>
                  </View>
                </View>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 20,
                }}
              >
                <Text style={styles.text}>
                  PACIENTE:
                  <Text style={styles.textResponse}>
                    {dataPdf?.[0]?.patientData || ""}
                  </Text>
                </Text>

                <Text style={styles.text}>PRESCRIÇÃO:</Text>
                <View
                  style={{
                    height: "28vh",
                    width: "100%",
                    backgroundColor: "#e6e6e6",
                  }}
                >
                  <View style={{ marginTop: "10px" }}>
                    <Text style={styles.text}>
                      NOME DO PRODUTO:
                      <Text style={styles.textResponse}>{el.name}</Text>
                    </Text>
                    <Text style={styles.text}>
                      MODO DE USAR:
                      <Text style={styles.textResponse}> {el.form} </Text>
                    </Text>
                    {el.note && (
                      <Text style={styles.text}>
                        OBS:
                        <Text style={styles.textResponse}> {el.note} </Text>
                      </Text>
                    )}
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.footerMedication}>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <View
                  style={{
                    border: "1px solid #000",
                    width: "100%",
                    height: "25vh",
                    marginRight: "5px",
                  }}
                >
                  <View style={{ borderBottom: "1px solid #000" }}>
                    <Text style={styles.centerText}>
                      IDENTIFICAÇÃO DO COMPRADOR
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      rowGap: "1px",
                    }}
                  >
                    <Text style={styles.text}>
                      NOME COMPLETO:
                      <Text style={styles.textResponse}></Text>
                    </Text>
                    <Text style={styles.text}>
                      RG: <Text style={styles.textResponse}></Text>
                    </Text>
                    <Text style={styles.text}>
                      ORGÃO EMISSOR:
                      <Text style={styles.textResponse}></Text>
                    </Text>
                    <Text style={styles.text}>
                      ENDEREÇO COMPLETO:
                      <Text style={styles.textResponse}></Text>
                    </Text>
                    <br />
                    <br />
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginRight: "40px",
                      }}
                    >
                      <Text style={styles.text}>
                        CIDADE:
                        <Text style={styles.textResponse}></Text>
                      </Text>
                      <Text style={styles.text}>
                        UF: <Text style={styles.textResponse}></Text>
                      </Text>
                    </View>

                    <Text style={styles.text}>
                      TELEFONE:
                      <Text style={styles.textResponse}></Text>
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    border: "1px solid #000",
                    width: "100%",
                    height: "25vh",
                    marginLeft: "5px",
                  }}
                >
                  <View
                    style={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "2px",
                    }}
                  >
                    <Text style={styles.centerText}>
                      IDENTIFICAÇÃO DO FORNECEDOR
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "24vh",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        rowGap: "3px",
                      }}
                    >
                      <Text style={styles.text}>NOME FARMACÊUTICO(A):</Text>
                      <br />
                      <br />
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginRight: "40px",
                        }}
                      >
                        <Text style={styles.text}>CRF:</Text>
                        <Text style={styles.text}>UF:</Text>
                      </View>
                      <Text style={styles.text}>NOME FARMÁCIA:</Text>
                      <Text style={styles.text}>ENDEREÇO:</Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginRight: "40px",
                        }}
                      >
                        <Text style={styles.text}>CIDADE:</Text>
                        <Text style={styles.text}>UF:</Text>
                      </View>
                      <Text style={styles.text}>CNPJ:</Text>
                      <Text style={styles.text}>TELEFONE:</Text>
                    </View>
                  </View>
                  <View style={{ marginBottom: "5px" }}>
                    <Text style={styles.centerText}>
                      ASSINATURA FARMACÊUTICO(A)
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        ))}
    </>
  );
}
