import React from "react";
import { Stack, Typography } from "@mui/material";

import logo from "../../../assets/fluxmed-logo-horizontal.png";
import {
  ContentBoxTitle,
  PrimaryButton,
  PrimaryLink,
} from "../../../components/common";
import { FormInputValidationNumber } from "../../signup/components/forminputinvalidationnumber";

export function VerificationCode({ onClick, control, loading }: any) {
  return (
    <Stack
      height="100%"
      justifyContent="center"
      alignItems="center"
      width="100%"
      spacing={4}
    >
      <img src={logo} alt="Logo fluxmed" width={185} height={44} />
      <ContentBoxTitle>Verificação necessária</ContentBoxTitle>
      <Typography
        variant="body1"
        sx={{ color: "neutral600.main" }}
        textAlign="center"
      >
        {/* Colocar e-mail inserido na outra etapa */}
        Para continuar, conclua esta etapa de verificação. Enviamos um código
        para o telefone cadastrado. Insira-o abaixo.
      </Typography>
      <FormInputValidationNumber
        name="verificationCode"
        control={control}
        label=""
        pattern={[1 - 9]}
        autoFocus
      />

      <PrimaryButton
        width="100%"
        height="52px"
        onClick={onClick}
        loading={loading}
      >
        Continuar
      </PrimaryButton>
      <Stack direction={{ sm: "column", md: "row" }} alignItems="center">
        <Typography variant="body1">Lembra da sua senha? &ensp;</Typography>
        <PrimaryLink link="/signin" text="Entrar" />
      </Stack>
    </Stack>
  );
}
