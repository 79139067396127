import { Dialog, Divider, IconButton, Stack, Typography } from "@mui/material";
import { PrivateMainLayoutMobile } from "../layout/private/main/PrivateMainLayoutMobile";
import { Close } from "@mui/icons-material";

interface MobileProps {
  title: string;
  open: boolean;
  onClose: any;
  children: any;
}

export function ScreenMobile({ title, open, onClose, children }: MobileProps) {
  return (
    <Dialog fullScreen open={open}>
      <PrivateMainLayoutMobile>
        <Stack
          direction="column"
          alignItems="flex-start"
          width="100%"
          sx={{ backgroundColor: "neutral0.main" }}
          height="100%"
          overflow="auto"
        >
          <Stack
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography
              variant="subtitle2"
              color="neutral700.main"
              fontWeight={600}
            >
              {title}
            </Typography>
            <IconButton sx={{ color: "neutral1000.main" }} onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
          <Divider sx={{ width: "100%", background: "neutral400.main" }} />
          {children}
        </Stack>
      </PrivateMainLayoutMobile>
    </Dialog>
  );
}
