import { IconButton, TableCell, Tooltip } from "@mui/material";
import { Description } from "@mui/icons-material";

export function QuestionnaireResponseActions({
  row,
  handleOpen,
  setResponseData,
}: any) {
  return (
    <TableCell align="center">
      <Tooltip title="Acessar">
        <IconButton
          onClick={() => {
            handleOpen();
            setResponseData(row);
          }}
        >
          <Description />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
}
