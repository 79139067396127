import React from "react";
import { PrivateMainLayout } from "../../components/layout";
import { Form } from "./components/form";

export function ProfessionalRegister() {
  return (
    <PrivateMainLayout noSidebar>
      <Form />
    </PrivateMainLayout>
  );
}
