import { toast } from "react-toastify";
import { endpoints } from "../../../../configs/Settings";
import { HTTPResponseStatus } from "../../../../utils/enum";
import { useAuth } from "../../../../utils/useAuth";
import { useAxios } from "../../../../utils/useAxios";

export async function PostQuestionnaireResponse(objData: any) {
  let url = `${endpoints.EHRPOSTER}/QuestionnaireResponse`;

  const data = objData;

  const subjectId = await useAuth.getSubjectId();
  const organizationId = await useAuth.getOrganizationId();

  const headers = {
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
    Accept: "application/fhir+json",
    "x-user-token": await useAuth.getXUserToken(),
    ...(subjectId && { "subject-id": subjectId }),
    ...(organizationId && { "organization-id": organizationId }),
  };

  const response = await useAxios
    .post(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (
        response.status !== HTTPResponseStatus.Created &&
        response.status !== HTTPResponseStatus.Success
      ) {
        throw new Error(`PostQuestionnaireResponse: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("PostQuestionnaireResponse ", error);
      toast.error(
        `PostQuestionnaireResponse Error: ${error?.response?.status}`
      );
      throw new Error();
    });
  if (response) return response;
}
