import { Business, Person } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { ScreenDetailsScheduleMobile } from "./ScreenDetailsScheduleMobile";
import { useFormatter } from "../../utils/useFormatter";
import dayjs from "dayjs";
import ScheduleCancelDialog from "./ScheduleCancelDialog";
import VideoConferencia from "../videoConferencia/VideoConferencia";
import { useSessionContext } from "../../contexts/SessionContext";
import { useFhirData } from "../../utils/useFhirData";
import { useLocationContext } from "../../contexts/LocationContext";

export function ScheduleCardsMobile({
  data,
  selected,
  setRefetch,
  onClick,
}: any) {
  const [openDetailsSchedule, setOpenDetailsSchedule] =
    useState<boolean>(false);

  const [scheduleCancelDialog, setScheduleCancelDialog] =
    useState<boolean>(false);

  const { user } = useSessionContext();
  const { location } = useLocationContext();

  const locationTeleconsulting = useFhirData.virtualService(
    location?.find((loc: any) =>
      loc?.resource?.id.includes(data?.locationId?.id)
    )?.resource
  );

  return (
    <>
      <Box
        minWidth="280px"
        width="100%"
        sx={{
          border: "1px solid",
          borderColor: "neutral400.main",
          borderRadius: "5px",
          boxShadow: 3,
        }}
      >
        <Stack sx={{ backgroundColor: "primary300.main", padding: "12px" }}>
          <Typography variant="body1">
            Consulta de {data?.healthcare}
          </Typography>
          <Typography variant="body1">
            <li>
              {dayjs(data?.startTime).format("DD MMM YYYY - HH:mm") + " horas"}
            </li>
          </Typography>
        </Stack>

        <Stack padding="12px">
          <Stack direction="row" gap={1}>
            <Business />
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                noWrap
                sx={{ width: "70%", textOverflow: "ellipsis" }}
                variant="body2"
                color="neutral700.main"
              >
                {useFormatter.formatNameDisplay(data.location)}
              </Typography>
              <Typography
                variant="body2"
                color="primary300.main"
                onClick={() => {
                  setOpenDetailsSchedule(true);
                  onClick();
                }}
              >
                Mais...
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" mb={1} gap={1} alignItems="center">
            <Person />
            <Typography variant="body2" color="neutral700.main">
              {data?.practitioner}
            </Typography>
          </Stack>
          {locationTeleconsulting && (
            <VideoConferencia
              roomName={`Teleconsulta-${data?.id}`}
              displayName={user.name}
              email={user?.email?.[0]}
            />
          )}
        </Stack>
      </Box>
      <ScreenDetailsScheduleMobile
        open={openDetailsSchedule}
        isVirtual={locationTeleconsulting}
        onClose={() => setOpenDetailsSchedule(false)}
        setScheduleCancelDialog={() => setScheduleCancelDialog(true)}
        data={data}
      />
      <ScheduleCancelDialog
        appointment={selected?.[0]}
        isOpen={scheduleCancelDialog}
        setIsOpen={setScheduleCancelDialog}
        setRefetch={setRefetch}
      />
    </>
  );
}
