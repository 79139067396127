import { Fade, Stack, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export function SideBarItem({ data, isOpen, selected }: any) {
  return (
    <Tooltip
      placement="right"
      title={data.text}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
    >
      <Link
        to={data.path}
        key={`sidebar-link-${data.text}`}
        style={{ textDecoration: "none" }}
      >
        <Stack
          direction="row"
          sx={
            selected && {
              backgroundColor: "primary700.main",
              borderRadius: "8px",
            }
          }
          alignItems="center"
          spacing={1}
          p={1}
          ml={data?.subitem && isOpen ? 5 : 0}
        >
          <data.icon
            sx={{
              color: selected ? "neutral0.main" : "neutral700.main",
              width: data?.subitem && "18px",
            }}
          />

          <Fade in={isOpen} appear={false}>
            <Typography
              fontWeight={500}
              color={selected ? "neutral0.main" : "neutral700.main"}
              variant="body1"
              noWrap
            >
              {data.text}
            </Typography>
          </Fade>
        </Stack>
      </Link>
    </Tooltip>
  );
}
