import { Box, Chip, Paper, Stack, Typography, styled } from "@mui/material";
import { PrimaryButton } from "../../../components/common";
import { Check } from "@mui/icons-material";
import { SubscriptionService } from "../../../services/subscription/SubscriptionService";
import { useSessionContext } from "../../../contexts/SessionContext";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";

const subscriptionService = new SubscriptionService();

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.neutral0.main,
  width: "100%",
  alignItems: "center",
  maxWidth: "100%",
  height: "fit-content",
  borderRadius: "30px",
  [theme.breakpoints.down("md")]: {
    padding: "0%",
  },
  [theme.breakpoints.up("xs")]: {
    height: "100%",
  },
}));

interface IProps {
  title: string;
  textContent: string;
  price: number;
  period: string;
  freeTrial: string;
  setPaymentLink: (link: string) => void;
  handleNext: () => void;
}

export function SubscriptionCard({
  title,
  textContent,
  price,
  period,
  freeTrial,
  setPaymentLink,
  handleNext,
}: IProps) {
  const { user } = useSessionContext();

  const organizationValue = localStorage.getItem("organizationValue");

  const handleSubscription = async () => {
    const subscriptionData = {
      email: user.email[0],
      reason: title,
      price: price,
      period: period,
      organizationId: `${organizationValue}`,
      freeTrial: freeTrial,
    };

    try {
      const paymentLink = await subscriptionService.createSubscription(
        subscriptionData
      );

      if (paymentLink) {
        setPaymentLink(paymentLink);
        handleNext();
      } else {
        console.log("Não foi possível obter o link de pagamento.");
      }
    } catch (error) {
      console.error("Erro ao criar a assinatura:", error);
    }
    localStorage.removeItem("operation");
    localStorage.removeItem("organizationValue");
  };

  return (
    <CustomBox>
      <Paper elevation={3} sx={{ padding: "24px", borderRadius: "10px" }}>
        <Stack spacing={4}>
          <Stack alignItems="center" spacing={2}>
            <Stack>
              <Typography variant="h6" fontWeight={600}>
                {title}
              </Typography>
            </Stack>
            <Typography
              variant="body1"
              color="neutral700.main"
              width="300px"
              textAlign="center"
            >
              {textContent}
            </Typography>
            <Stack direction="row">
              <Typography variant="h5" color="primary.main" fontWeight="600">
                R$
              </Typography>
              <Typography variant="h2" color="neutral700.main" fontWeight={600}>
                {price.toFixed(2).replace(".", ",")}
              </Typography>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <PrimaryButton
              width="100%"
              height="40px"
              onClick={handleSubscription}
            >
              Escolher
            </PrimaryButton>
            <Stack alignItems="flex-start">
              <Stack direction="row" spacing={1} alignItems="center">
                <Check sx={{ color: "primary.main", fontSize: 32 }} />
                <Typography>
                  Acesso completo a todas as funcionalidades.
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Check sx={{ color: "primary.main", fontSize: 32 }} />
                <Typography>
                  {freeTrial} dias grátis para experimentar.
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Check sx={{ color: "primary.main", fontSize: 32 }} />
                <Typography>Cadastro ilimitado.</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </CustomBox>
  );
}
