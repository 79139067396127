import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Stack,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

import dayjs from "dayjs";
import { GenericTable } from "../../../../../components/GenericTable/GenericTable";

import { DocumentReference } from "fhir/r5";
import { ProcedureRequestActions } from "./ProcedureRequestActions";

export function ProcedureRequest({
  data,
  title,
  handleOpen,
  procedimentos,
  setBinaryUrl,
  resultsData,
}: any) {
  const results = resultsData?.[0]?.entry;

  const documentReference = data?.[0]?.entry?.filter((el: any) => {
    return (
      el?.resource?.resourceType === "DocumentReference" &&
      el?.resource?.category?.[0].coding?.[0].display === "Procedimento"
    );
  });

  const columns = [
    {
      column: "Descrição",
      rowName: "description",
    },
    { column: "Data", rowName: "date" },
    { column: "Profissional solicitante", rowName: "practitioner" },
    { column: "Status", rowName: "status" },
  ];

  const rowData = procedimentos?.[0]?.entry
    ?.filter(
      (valueFilter: any) =>
        valueFilter?.resource?.category?.[0]?.text === "procedure"
    )
    ?.map((el: any, index: number) => {
      const description =
        el?.resource?.code?.concept?.coding?.[0]?.display || "-";
      const date = dayjs(el?.resource?.authoredOn).format("DD/MM/YYYY") || "-";
      const category = el?.resource?.category?.[0]?.text || "-";
      const practitioner = el?.resource?.contained?.[0].name?.[0]?.text || "-";
      const status = el?.resource?.status;

      const docReference = documentReference?.find(
        (doc: fhir5.BundleEntry<DocumentReference | any>, index: number) => {
          const findRef = doc?.resource?.basedOn?.[0]?.reference?.includes(
            el?.resource?.id
          );

          return findRef;
        }
      );

      const auxResults = results?.find((result: any) => {
        const findRef =
          result?.resource?.basedOn
            ?.find((bas: any) => bas.reference.includes("ServiceRequest"))
            ?.reference?.split("/")?.[1] === el?.resource?.id;

        return findRef;
      });
      const resultUrl =
        auxResults?.resource?.content?.[0]?.attachment?.url || "";

      const binaryUrl =
        docReference?.resource?.content?.[0]?.attachment?.url || "";

      return {
        description,
        date,
        category,
        practitioner,
        resultUrl,
        binaryUrl,
        status,
      };
    })
    ?.sort((a: any, b: any) => {
      const dateA = dayjs(a.date, "DD/MM/YYYY");
      const dateB = dayjs(b.date, "DD/MM/YYYY");
      return dateB.diff(dateA);
    });

  return (
    <Accordion sx={{ width: "100%" }} elevation={5}>
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack direction="row" alignItems="center" gap={1}>
          {title}
          <Avatar
            sx={{
              width: 26,
              height: 26,
              fontSize: "14px",
              fontWeight: 600,
              backgroundColor: "primary100.main",
              color: "primary1000.main",
            }}
          >
            {rowData?.length || "0"}
          </Avatar>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <GenericTable
          tableColumns={columns}
          tableRows={rowData}
          actions={(rowData: any) => (
            <ProcedureRequestActions
              row={rowData}
              handleOpen={handleOpen}
              setBinaryUrl={setBinaryUrl}
            />
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
}
