import React from "react";
import {
  Avatar,
  Box,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  PowerSettingsNew,
  Quiz,
} from "@mui/icons-material";

import OptionsMenuItem from "./OptionsMenuItem";
import { useSessionContext } from "../../../../../contexts/SessionContext";
import { useLoggedUserContext } from "../../../../../contexts/LoggedUserContext";
import { IFeatures } from "../../../../../utils/interfaces";
import { useFormatter } from "../../../../../utils/useFormatter";
import { isMobile } from "react-device-detect";
import { MobileMenu } from "../MobileMenu";

export function OptionsMenu() {
  const { avatarMenuItens } = useLoggedUserContext();
  const { user, loading, inviteDialog, access } = useSessionContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { formatNameFirstInitials } = useFormatter;

  const suporteLink =
    access.type === "professional"
      ? "https://projectcore.atlassian.net/servicedesk/customer/portal/11"
      : "https://projectcore.atlassian.net/servicedesk/customer/portal/12";

  return (
    <>
      {loading || inviteDialog ? (
        <Box
          sx={{
            padding: "5px",
            ml: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Skeleton
            variant="circular"
            width={44}
            height={44}
            sx={{ bgcolor: "primary100.main" }}
          />
          <ExpandMore
            sx={{
              color: "neutral0.main",
              marginLeft: "4px",
              fontSize: "24px",
            }}
          />
        </Box>
      ) : (
        <IconButton
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          size="small"
          sx={{
            ml: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              width: 44,
              height: 44,
              backgroundColor: "neutral0.main",
              color: "primary900.main",
              fontSize: "25px",
            }}
          >
            {formatNameFirstInitials(user?.name, 1)}
          </Avatar>

          {!isMobile && (
            <>
              {open ? (
                <ExpandLess
                  sx={{
                    color: "neutral0.main",
                    marginLeft: "4px",
                    fontSize: "24px",
                  }}
                />
              ) : (
                <ExpandMore
                  sx={{
                    color: "neutral0.main",
                    marginLeft: "4px",
                    fontSize: "24px",
                  }}
                />
              )}
            </>
          )}
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        sx={{ marginTop: "8px" }}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {isMobile ? (
          <MobileMenu open={open} setModalOpen={handleClose} />
        ) : (
          <>
            {avatarMenuItens?.map((item: IFeatures, id: number) => (
              <OptionsMenuItem
                key={`layout-private-user-options-menu-${id}`}
                link={item.path}
                title={item.text}
                Icon={item.icon}
              />
            ))}

            <Stack
              style={{ textDecoration: "none", cursor: "pointer" }}
              onClick={() => window.open(suporteLink, "_blank")}
            >
              <MenuItem
                sx={{ "&:hover": { backgroundColor: "primary200.main" } }}
              >
                <ListItemIcon>
                  <Quiz sx={{ fontSize: "24px", color: "primary1000.main" }} />
                </ListItemIcon>
                <ListItemText sx={{ color: "neutral1000.main" }}>
                  Feedback e suporte
                </ListItemText>
              </MenuItem>
            </Stack>

            <OptionsMenuItem
              key={`layout-private-user-options-menu-logout`}
              link="/signin"
              title="Sair"
              action={() => localStorage.clear()}
              Icon={PowerSettingsNew}
            />
          </>
        )}
      </Menu>
    </>
  );
}
