import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { useSessionContext } from "../../../../contexts/SessionContext";
import { useOrganizationContext } from "../../../../contexts/OrganizationContext";
import { FormButtonGroup, FormTextInput } from "../../../../components/form";
import { Controller, useForm } from "react-hook-form";

import { toast } from "react-toastify";
import { PostPrescriptionProtocol } from "../../../../services/fhir";

export function CreateProtocolDialog({ handleClose, isOpen, itens }: any) {
  const { user } = useSessionContext();
  const { organization } = useOrganizationContext();
  const { control, handleSubmit } = useForm();

  const auxMedication = itens?.filter(
    (item: any) => item.type === "medication"
  );
  const auxSupplement = itens?.filter(
    (item: any) => item.type === "supplement"
  );
  const auxHerbalMedicine = itens?.filter(
    (item: any) => item.type === "herbalMedicine"
  );
  const auxProcedure = itens?.filter((item: any) => item.type === "procedure");
  const auxService = itens?.filter((item: any) => item.type === "service");
  const auxCertificate = itens?.filter(
    (item: any) => item.type === "certificate"
  );

  const references = {
    organizationId: organization?.id,
    practitionerId: user?.username,
  };

  const tags = [
    {
      name: "VERMELHA",
      value: "CE",
      color: "#db3f30",
      display: "Controle especial - Tarja vermelha",
    },
    {
      name: "PRETA",
      value: "CE",
      color: "white",
      background: "black",
      display: "Controle especial - Tarja preta",
    },
    {
      name: "AMARELA",
      value: "AM",
      color: "#FEE2A9",
      display: "Receita amarela",
    },
    {
      name: "AZUL",
      value: "AZ",
      color: "#A4CBE8",
      display: "Receita azul",
    },
    {
      name: "SEM TARJA",
      value: "BR",
      color: "white",
      display: "Receita branca",
    },
    {
      name: "EX",
      display: "Exame",
      value: "EX",
      color: "#FDCF72",
    },
    {
      name: "AT",
      value: "AT",
      color: "#009E8C",
      display: "Atestado",
    },
  ];

  const onSubmit = async (data: any) => {
    const selectedItens = itens?.filter(
      (item: any) => data.itens[item.inputValue?.value] !== false
    );

    const medicationArray = selectedItens?.filter(
      (el: any) => el.type !== "service" && el.type !== "certificate"
    );

    const serviceArray = selectedItens?.filter(
      (el: any) => el.type === "service"
    );
    const certificateArray = selectedItens?.filter(
      (el: any) => el.type === "certificate"
    );

    const auxObj = {
      nomeProtocolo: data.name,
      medicamentos: medicationArray?.length ? medicationArray : undefined,
      exames: serviceArray?.length ? serviceArray : undefined,
      atestados: certificateArray?.length ? certificateArray : undefined,
    };

    try {
      await PostPrescriptionProtocol(auxObj, references);
      toast.success("Protocolo criado com sucesso!");
    } catch (err) {
      toast.warn("Não foi possível finalizar esta operação, tente novamente");
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: { borderRadius: "5px", padding: 4 },
      }}
      maxWidth="lg"
      onClose={(event, reason) => handleClose()}
    >
      <Stack width="950px" spacing={4}>
        <Typography
          textAlign="center"
          variant="h6"
          color="neutral1000.main"
          fontWeight={500}
        >
          Criar protocolo
        </Typography>

        <FormTextInput
          name="name"
          label="Nome do protocolo"
          required
          control={control}
        />
        <Stack spacing={2}>
          {auxMedication.length ||
          auxSupplement.length ||
          auxHerbalMedicine.length ? (
            <Stack width="85%" direction="row" alignItems="center" gap={2}>
              <Typography
                variant="subtitle1"
                color="neutral1000.main"
                fontWeight={500}
              >
                Tratamento
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Stack>
          ) : (
            <></>
          )}
          {auxMedication.length ? (
            <>
              <Typography
                variant="body1"
                color="neutral1000.main"
                fontWeight={500}
              >
                Medicamentos:
              </Typography>
              {auxMedication?.map((el: any, index: number) => {
                const auxTag = tags.find((tag: any) =>
                  el.tag?.toUpperCase()?.includes(tag.name)
                );
                return (
                  <Stack direction="row" alignItems="center" width="100%">
                    <Controller
                      name={`itens.${el?.inputValue?.value}`}
                      control={control}
                      render={({ field: { onChange, ref, ...field } }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              inputRef={ref}
                              onChange={onChange}
                              defaultChecked
                              {...field}
                            />
                          }
                          label=""
                        />
                      )}
                    />
                    <Stack direction="row" width="100%" mb={1} key={`${index}`}>
                      <Stack
                        p={1}
                        gap={1}
                        sx={{
                          background: "#CCC",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      >
                        <Stack direction="row" gap={1}>
                          <Typography
                            variant="body1"
                            color="neutral1000.main"
                            fontWeight={500}
                          >
                            {index + 1}. {el.name}
                          </Typography>

                          {el.inputValue?.presentation && (
                            <Typography variant="body1" color="neutral700.main">
                              {el.inputValue?.presentation}
                            </Typography>
                          )}

                          {el.tag && (
                            <Tooltip title={auxTag?.display}>
                              <Button
                                sx={{
                                  background:
                                    auxTag?.background ||
                                    auxTag?.color ||
                                    "white",

                                  width: "21px",
                                  minWidth: "21px",
                                  height: "22px",
                                  "&:hover": {
                                    backgroundColor:
                                      auxTag?.background ||
                                      auxTag?.color ||
                                      "white",
                                  },
                                }}
                                variant="contained"
                              >
                                <Typography
                                  sx={{
                                    color: auxTag?.background
                                      ? auxTag?.color
                                      : "black",
                                  }}
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  {auxTag?.value || "-"}
                                </Typography>
                              </Button>
                            </Tooltip>
                          )}
                        </Stack>

                        {el.formValue?.label && (
                          <Typography variant="body1" color="neutral700.main">
                            {el.form}
                          </Typography>
                        )}

                        {el?.type === "service" && el?.inputValue?.code && (
                          <Typography variant="body1" color="neutral700.main">
                            Código TUSS: {el?.inputValue?.code}
                          </Typography>
                        )}

                        {el?.category && (
                          <Typography variant="body1" color="neutral700.main">
                            Categoria: {el?.category?.label}
                          </Typography>
                        )}

                        {el.condition && (
                          <Typography variant="body1" color="neutral700.main">
                            Condição: {el?.condition?.label}{" "}
                            {!el?.condition?.name &&
                              el.condition?.value &&
                              `- CID10 ${el.condition?.value}`}
                          </Typography>
                        )}

                        {el.note && (
                          <Typography variant="body1" color="neutral700.main">
                            {el.note}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                );
              })}
            </>
          ) : (
            <></>
          )}

          {auxSupplement.length ? (
            <>
              <Typography
                variant="body1"
                color="neutral1000.main"
                fontWeight={500}
              >
                Suplementos
              </Typography>
              {auxSupplement?.map((el: any, index: number) => {
                const auxTag = tags.find((tag: any) =>
                  el.tag?.toUpperCase()?.includes(tag.name)
                );
                return (
                  <Stack direction="row" alignItems="center" width="100%">
                    <Controller
                      name={`itens.${el?.inputValue?.value}`}
                      control={control}
                      render={({ field: { onChange, ref, ...field } }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              inputRef={ref}
                              onChange={onChange}
                              defaultChecked
                              {...field}
                            />
                          }
                          label=""
                        />
                      )}
                    />
                    <Stack direction="row" width="100%" mb={1} key={`${index}`}>
                      <Stack
                        p={1}
                        gap={1}
                        sx={{
                          background: "#CCC",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      >
                        <Stack direction="row" gap={1}>
                          <Typography
                            variant="body1"
                            color="neutral1000.main"
                            fontWeight={500}
                          >
                            {index + 1}. {el.name}
                          </Typography>

                          {el.inputValue?.presentation && (
                            <Typography variant="body1" color="neutral700.main">
                              {el.inputValue?.presentation}
                            </Typography>
                          )}

                          {el.tag && (
                            <Tooltip title={auxTag?.display}>
                              <Button
                                sx={{
                                  background:
                                    auxTag?.background ||
                                    auxTag?.color ||
                                    "white",

                                  width: "21px",
                                  minWidth: "21px",
                                  height: "22px",
                                  "&:hover": {
                                    backgroundColor:
                                      auxTag?.background ||
                                      auxTag?.color ||
                                      "white",
                                  },
                                }}
                                variant="contained"
                              >
                                <Typography
                                  sx={{
                                    color: auxTag?.background
                                      ? auxTag?.color
                                      : "black",
                                  }}
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  {auxTag?.value || "-"}
                                </Typography>
                              </Button>
                            </Tooltip>
                          )}
                        </Stack>

                        {el.formValue?.label && (
                          <Typography variant="body1" color="neutral700.main">
                            {el.form}
                          </Typography>
                        )}

                        {el?.type === "service" && el?.inputValue?.code && (
                          <Typography variant="body1" color="neutral700.main">
                            Código TUSS: {el?.inputValue?.code}
                          </Typography>
                        )}

                        {el?.category && (
                          <Typography variant="body1" color="neutral700.main">
                            Categoria: {el?.category?.label}
                          </Typography>
                        )}

                        {el.condition && (
                          <Typography variant="body1" color="neutral700.main">
                            Condição: {el?.condition?.label}{" "}
                            {!el?.condition?.name &&
                              el.condition?.value &&
                              `- CID10 ${el.condition?.value}`}
                          </Typography>
                        )}

                        {el.note && (
                          <Typography variant="body1" color="neutral700.main">
                            {el.note}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                );
              })}
            </>
          ) : (
            <></>
          )}

          {auxHerbalMedicine.length ? (
            <>
              <Typography
                variant="body1"
                color="neutral1000.main"
                fontWeight={500}
              >
                Fitoterápicos:
              </Typography>
              {auxHerbalMedicine?.map((el: any, index: number) => {
                const auxTag = tags.find((tag: any) =>
                  el.tag?.toUpperCase()?.includes(tag.name)
                );
                return (
                  <Stack direction="row" alignItems="center" width="100%">
                    <Controller
                      name={`itens.${el?.inputValue?.value}`}
                      control={control}
                      render={({ field: { onChange, ref, ...field } }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              inputRef={ref}
                              onChange={onChange}
                              defaultChecked
                              {...field}
                            />
                          }
                          label=""
                        />
                      )}
                    />
                    <Stack direction="row" width="100%" mb={1} key={`${index}`}>
                      <Stack
                        p={1}
                        gap={1}
                        sx={{
                          background: "#CCC",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      >
                        <Stack direction="row" gap={1}>
                          <Typography
                            variant="body1"
                            color="neutral1000.main"
                            fontWeight={500}
                          >
                            {index + 1}. {el.name}
                          </Typography>

                          {el.inputValue?.presentation && (
                            <Typography variant="body1" color="neutral700.main">
                              {el.inputValue?.presentation}
                            </Typography>
                          )}

                          {el.tag && (
                            <Tooltip title={auxTag?.display}>
                              <Button
                                sx={{
                                  background:
                                    auxTag?.background ||
                                    auxTag?.color ||
                                    "white",

                                  width: "21px",
                                  minWidth: "21px",
                                  height: "22px",
                                  "&:hover": {
                                    backgroundColor:
                                      auxTag?.background ||
                                      auxTag?.color ||
                                      "white",
                                  },
                                }}
                                variant="contained"
                              >
                                <Typography
                                  sx={{
                                    color: auxTag?.background
                                      ? auxTag?.color
                                      : "black",
                                  }}
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  {auxTag?.value || "-"}
                                </Typography>
                              </Button>
                            </Tooltip>
                          )}
                        </Stack>

                        {el.formValue?.label && (
                          <Typography variant="body1" color="neutral700.main">
                            {el.form}
                          </Typography>
                        )}

                        {el?.type === "service" && el?.inputValue?.code && (
                          <Typography variant="body1" color="neutral700.main">
                            Código TUSS: {el?.inputValue?.code}
                          </Typography>
                        )}

                        {el?.category && (
                          <Typography variant="body1" color="neutral700.main">
                            Categoria: {el?.category?.label}
                          </Typography>
                        )}

                        {el.condition && (
                          <Typography variant="body1" color="neutral700.main">
                            Condição: {el?.condition?.label}{" "}
                            {!el?.condition?.name &&
                              el.condition?.value &&
                              `- CID10 ${el.condition?.value}`}
                          </Typography>
                        )}

                        {el.note && (
                          <Typography variant="body1" color="neutral700.main">
                            {el.note}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                );
              })}
            </>
          ) : (
            <></>
          )}
          {auxProcedure.length || auxService.length || auxCertificate.length ? (
            <Stack width="82%" direction="row" alignItems="center" gap={2}>
              <Typography
                variant="subtitle1"
                color="neutral1000.main"
                fontWeight={500}
              >
                Procedimento
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Stack>
          ) : (
            <></>
          )}
          {auxProcedure.length ? (
            <>
              <Typography
                variant="body1"
                color="neutral1000.main"
                fontWeight={500}
              >
                Procedimentos:
              </Typography>
              {auxProcedure?.map((el: any, index: number) => {
                const auxTag = tags.find((tag: any) =>
                  el.tag?.toUpperCase()?.includes(tag.name)
                );
                return (
                  <Stack direction="row" alignItems="center" width="100%">
                    <Controller
                      name={`itens.${el?.inputValue?.value}`}
                      control={control}
                      render={({ field: { onChange, ref, ...field } }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              inputRef={ref}
                              onChange={onChange}
                              defaultChecked
                              {...field}
                            />
                          }
                          label=""
                        />
                      )}
                    />
                    <Stack direction="row" width="100%" mb={1} key={`${index}`}>
                      <Stack
                        p={1}
                        gap={1}
                        sx={{
                          background: "#CCC",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      >
                        <Stack direction="row" gap={1}>
                          <Typography
                            variant="body1"
                            color="neutral1000.main"
                            fontWeight={500}
                          >
                            {index + 1}. {el.name}
                          </Typography>

                          {el.inputValue?.presentation && (
                            <Typography variant="body1" color="neutral700.main">
                              {el.inputValue?.presentation}
                            </Typography>
                          )}

                          {el.tag && (
                            <Tooltip title={auxTag?.display}>
                              <Button
                                sx={{
                                  background:
                                    auxTag?.background ||
                                    auxTag?.color ||
                                    "white",

                                  width: "21px",
                                  minWidth: "21px",
                                  height: "22px",
                                  "&:hover": {
                                    backgroundColor:
                                      auxTag?.background ||
                                      auxTag?.color ||
                                      "white",
                                  },
                                }}
                                variant="contained"
                              >
                                <Typography
                                  sx={{
                                    color: auxTag?.background
                                      ? auxTag?.color
                                      : "black",
                                  }}
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  {auxTag?.value || "-"}
                                </Typography>
                              </Button>
                            </Tooltip>
                          )}
                        </Stack>

                        {el.formValue?.label && (
                          <Typography variant="body1" color="neutral700.main">
                            {el.form}
                          </Typography>
                        )}

                        {el?.type === "service" && el?.inputValue?.code && (
                          <Typography variant="body1" color="neutral700.main">
                            Código TUSS: {el?.inputValue?.code}
                          </Typography>
                        )}

                        {el?.category && (
                          <Typography variant="body1" color="neutral700.main">
                            Categoria: {el?.category?.label}
                          </Typography>
                        )}

                        {el.condition && (
                          <Typography variant="body1" color="neutral700.main">
                            Condição: {el?.condition?.label}{" "}
                            {!el?.condition?.name &&
                              el.condition?.value &&
                              `- CID10 ${el.condition?.value}`}
                          </Typography>
                        )}

                        {el.note && (
                          <Typography variant="body1" color="neutral700.main">
                            {el.note}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                );
              })}
            </>
          ) : (
            <></>
          )}

          {auxService.length ? (
            <>
              <Typography
                variant="body1"
                color="neutral1000.main"
                fontWeight={500}
              >
                Exames:
              </Typography>
              {auxService?.map((el: any, index: number) => {
                const auxTag = tags.find((tag: any) =>
                  el.tag?.toUpperCase()?.includes(tag.name)
                );
                return (
                  <Stack direction="row" alignItems="center" width="100%">
                    <Controller
                      name={`itens.${el?.inputValue?.value}`}
                      control={control}
                      render={({ field: { onChange, ref, ...field } }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              inputRef={ref}
                              onChange={onChange}
                              defaultChecked
                              {...field}
                            />
                          }
                          label=""
                        />
                      )}
                    />
                    <Stack direction="row" width="100%" mb={1} key={`${index}`}>
                      <Stack
                        p={1}
                        gap={1}
                        sx={{
                          background: "#CCC",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      >
                        <Stack direction="row" gap={1}>
                          <Typography
                            variant="body1"
                            color="neutral1000.main"
                            fontWeight={500}
                          >
                            {index + 1}. {el.name}
                          </Typography>

                          {el.inputValue?.presentation && (
                            <Typography variant="body1" color="neutral700.main">
                              {el.inputValue?.presentation}
                            </Typography>
                          )}

                          {el.tag && (
                            <Tooltip title={auxTag?.display}>
                              <Button
                                sx={{
                                  background:
                                    auxTag?.background ||
                                    auxTag?.color ||
                                    "white",

                                  width: "21px",
                                  minWidth: "21px",
                                  height: "22px",
                                  "&:hover": {
                                    backgroundColor:
                                      auxTag?.background ||
                                      auxTag?.color ||
                                      "white",
                                  },
                                }}
                                variant="contained"
                              >
                                <Typography
                                  sx={{
                                    color: auxTag?.background
                                      ? auxTag?.color
                                      : "black",
                                  }}
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  {auxTag?.value || "-"}
                                </Typography>
                              </Button>
                            </Tooltip>
                          )}
                        </Stack>

                        {el.formValue?.label && (
                          <Typography variant="body1" color="neutral700.main">
                            {el.form}
                          </Typography>
                        )}

                        {el?.type === "service" && el?.inputValue?.code && (
                          <Typography variant="body1" color="neutral700.main">
                            Código TUSS: {el?.inputValue?.code}
                          </Typography>
                        )}

                        {el?.category && (
                          <Typography variant="body1" color="neutral700.main">
                            Categoria: {el?.category?.label}
                          </Typography>
                        )}

                        {el.condition && (
                          <Typography variant="body1" color="neutral700.main">
                            Condição: {el?.condition?.label}{" "}
                            {!el?.condition?.name &&
                              el.condition?.value &&
                              `- CID10 ${el.condition?.value}`}
                          </Typography>
                        )}

                        {el.note && (
                          <Typography variant="body1" color="neutral700.main">
                            {el.note}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                );
              })}
            </>
          ) : (
            <></>
          )}

          {auxCertificate.length ? (
            <>
              <Typography
                variant="body1"
                color="neutral1000.main"
                fontWeight={500}
              >
                Relatórios:
              </Typography>
              {auxCertificate?.map((el: any, index: number) => {
                const auxTag = tags.find((tag: any) =>
                  el.tag?.toUpperCase()?.includes(tag.name)
                );
                return (
                  <Stack direction="row" alignItems="center" width="100%">
                    <Controller
                      name={`itens.${el?.inputValue?.value}`}
                      control={control}
                      render={({ field: { onChange, ref, ...field } }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              inputRef={ref}
                              onChange={onChange}
                              defaultChecked
                              {...field}
                            />
                          }
                          label=""
                        />
                      )}
                    />
                    <Stack direction="row" width="100%" mb={1} key={`${index}`}>
                      <Stack
                        p={1}
                        gap={1}
                        sx={{
                          background: "#CCC",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      >
                        <Stack direction="row" gap={1}>
                          <Typography
                            variant="body1"
                            color="neutral1000.main"
                            fontWeight={500}
                          >
                            {index + 1}. {el.name}
                          </Typography>

                          {el.inputValue?.presentation && (
                            <Typography variant="body1" color="neutral700.main">
                              {el.inputValue?.presentation}
                            </Typography>
                          )}

                          {el.tag && (
                            <Tooltip title={auxTag?.display}>
                              <Button
                                sx={{
                                  background:
                                    auxTag?.background ||
                                    auxTag?.color ||
                                    "white",

                                  width: "21px",
                                  minWidth: "21px",
                                  height: "22px",
                                  "&:hover": {
                                    backgroundColor:
                                      auxTag?.background ||
                                      auxTag?.color ||
                                      "white",
                                  },
                                }}
                                variant="contained"
                              >
                                <Typography
                                  sx={{
                                    color: auxTag?.background
                                      ? auxTag?.color
                                      : "black",
                                  }}
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  {auxTag?.value || "-"}
                                </Typography>
                              </Button>
                            </Tooltip>
                          )}
                        </Stack>

                        {el.formValue?.label && (
                          <Typography variant="body1" color="neutral700.main">
                            {el.form}
                          </Typography>
                        )}

                        {el?.type === "service" && el?.inputValue?.code && (
                          <Typography variant="body1" color="neutral700.main">
                            Código TUSS: {el?.inputValue?.code}
                          </Typography>
                        )}

                        {el?.category && (
                          <Typography variant="body1" color="neutral700.main">
                            Categoria: {el?.category?.label}
                          </Typography>
                        )}

                        {el.condition && (
                          <Typography variant="body1" color="neutral700.main">
                            Condição: {el?.condition?.label}{" "}
                            {!el?.condition?.name &&
                              el.condition?.value &&
                              `- CID10 ${el.condition?.value}`}
                          </Typography>
                        )}

                        {el.note && (
                          <Typography variant="body1" color="neutral700.main">
                            {el.note}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </Stack>
        <FormButtonGroup
          onGoBackButtonClick={handleClose}
          onNextButtonClick={handleSubmit(onSubmit)}
          goBackButtonText="Cancelar"
          nextButtonText="Confirmar"
          loading={false}
        />
      </Stack>
    </Dialog>
  );
}
