import { Grid } from "@mui/material";
import {
  InputRadio,
  InputText,
} from "../../../ClinicalRegister/components/QuestionnaireViewer/components/Form";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { useWatch } from "react-hook-form";
import { useEffect } from "react";

export function ExamsFields({ item, type }: any) {
  const { control, unregister } = useMedicalCareContext();

  const alteradoWatch = useWatch({
    name: `exams.${type}.${item?.text.replace(".", " ")}.value`,
    control,
  });

  useEffect(() => {
    if (alteradoWatch === "false") {
      unregister(`exams.${type}.${item?.text.replace(".", " ")}.alterado`);
    }
  }, [alteradoWatch]);

  return (
    <Grid
      item
      xs={6}
      paddingRight={2}
      sx={{
        borderRight: "1px solid rgba(0, 0, 0, 0.1)",

        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
      }}
    >
      <InputRadio
        key={`exams.${type}.${item?.text}`}
        label={item?.text}
        name={`exams.${type}.${item?.text.replace(".", " ")}.value`}
        control={control}
        answerOption={[
          { valueCoding: { id: false, display: "Normal" } },
          { valueCoding: { id: true, display: "Alterado" } },
        ]}
        defaultValue={false}
        // value={item?.answer?.[0]?.valueCoding?.id}
        sx={{ mb: 2 }}
      />

      {alteradoWatch && alteradoWatch === "true" && (
        <InputText
          key={item?.item?.[0]?.linkId}
          label={item?.item?.[0].text}
          name={`exams.${type}.${item?.text.replace(".", " ")}.alterado`}
          control={control}
        />
      )}
    </Grid>
  );
}
