import { toast } from "react-toastify";
import { endpoints, oids } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

interface formatFields {
  patient: number;
  category: "atestado" | "prescricao" | "service-request" | "resultado";
}

export async function GetDocumentReference({
  patient,
  category,
}: formatFields) {
  let url = `${endpoints.MHD}/DocumentReference?subject:Patient.identifier=urn:oid:${oids.cpf}%7C${patient}&status=current&category=${category}&_count=100&_sort=-_lastUpdated`;
  if (category !== "resultado")
    url = `${url}&_include=DocumentReference:based-on`;
  const subjectId = await useAuth.getSubjectId();
  const organizationId = await useAuth.getOrganizationId();

  const headers = {
    Accept: "application/fhir+json",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
    "x-user-token": await useAuth.getXUserToken(),
    ...(subjectId && { "subject-id": subjectId }),
    ...(organizationId && { "organization-id": organizationId }),
    purposeofuse: "Atendimento",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`Get DocumentReference: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetDocumentReference ", error);
      // toast.error(`GetDocumentReference Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
