import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useMedicalCareContext } from "./MedicalCareContext";
import { useLocation } from "react-router-dom";

interface InterventionsContextValue {
  control: any;
  setValue: any;
  handleSubmit: any;
  setClean: Function;

  createDialog: any;
  setCreateDialog: Function;
  selectProtocol: any;
  setSelectProtocol: Function;

  setMedication: any;
  medicationOptions: any;

  selectedInterventions: any;
  setInterventionsList: Function;

  base64: any;
  setBase64: any;

  selectedItem: any;
  setSelectedItem: Function;

  expandedList: any;
  setExpandedList: Function;

  subTabValue: any;
  setSubTabValue: Function;

  medicationTabValue: any;
  setMedicationTabValue: Function;

  functions: any;
  action: any;

  isEdit: any;
  clean: any;
}

export const InterventionsContext = createContext<InterventionsContextValue>(
  null!
);

export function InterventionsContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { setInterventionTab, initial } = useMedicalCareContext();

  const { state } = useLocation();

  const [expandedList, setExpandedList] = useState<boolean>(true);
  const [subTabValue, setSubTabValue] = useState(0);
  const [medicationTabValue, setMedicationTabValue] = useState(0);

  const methods = useForm();
  const { handleSubmit, control, reset, setValue } = methods;
  const [interventionsList, setInterventionsList] = useState<Array<any>>([]);
  const [selectedItem, setSelectedItem] = useState<number>();
  const [medication, setMedication] = useState([]);
  const [clean, setClean] = useState<boolean>(false);

  const [base64, setBase64] = useState<any>();

  const [createDialog, setCreateDialog] = useState<boolean>(false);
  const [selectProtocol, setSelectProtocol] = useState<boolean>(false);

  const selectedInterventions = {
    all: interventionsList,
    medicationSpecial: interventionsList?.filter(
      (el: any) =>
        el?.tag?.toUpperCase()?.includes("VERMELHA") ||
        el?.tag?.toUpperCase()?.includes("PRETA")
    ),
    medicationSimple: interventionsList?.filter(
      (el: any) =>
        (el?.type === "medication" ||
          el?.type === "supplement" ||
          el?.type === "herbalMedicine") &&
        !(
          el?.tag?.toUpperCase()?.includes("VERMELHA") ||
          el?.tag?.toUpperCase()?.includes("PRETA")
        )
    ),
    service: interventionsList?.filter((el: any) => el?.type === "service"),
    procedure: interventionsList?.filter((el: any) => el?.type === "procedure"),
    certificates: interventionsList?.filter(
      (el: any) => el?.type === "certificate"
    ),
  };
  const functions = {
    handleAdd: (item: any) => {
      setInterventionsList((prev: any) => [...prev, item]);
      setClean(true);
    },
    handleEdit: (item: any) => {
      setInterventionsList((prevState: any) => {
        const updatedItems = [...prevState];
        const index = updatedItems.findIndex((el, i) => i === selectedItem);
        if (index !== -1) {
          updatedItems[index] = item;
        }

        return updatedItems;
      });
      setSelectedItem(undefined);
      setClean(true);
    },
    handleSign: (index: any, signedBase64: any) => {
      setInterventionsList((prevState: any) => {
        const updatedItems = [...prevState];

        updatedItems[index] = {
          ...updatedItems[index],
          signedBase64,
          sign: true,
        };

        return updatedItems;
      });
      setSelectedItem(undefined);
      setClean(true);
    },
    handleRemove: (index: number) => {
      setSelectedItem(undefined);

      setInterventionsList((prev: any) =>
        prev.filter((item: any, i: number) => i !== index)
      );
    },
    handleSelect: (item: any, index: any) => {
      reset();

      setSelectedItem(index);

      if (item?.type === "medication") {
        setInterventionTab(0);
        setMedicationTabValue(Number(item.tabValue));
        setValue("medication", item?.inputValue);
        setValue("quantity", item?.quantity?.value || item?.quantity);
        setValue("frequency", item.frequency);
        setValue("note", item.note);
        setValue("time", item.time);
        setValue("form", item.formValue);
      }
      if (item?.type === "supplement") {
        setInterventionTab(0);
        setMedicationTabValue(1);
        setValue("supplement", item?.inputValue);
        setValue("quantity", item?.quantity?.value || item?.quantity);
        setValue("frequency", item?.frequency);
        setValue("note", item?.note);
        setValue("time", item?.time);
        setValue("form", item?.formValue);
      }
      if (item?.type === "herbalMedicine") {
        setInterventionTab(0);
        setMedicationTabValue(2);
        setValue("herbalMedicine", item.inputValue);
        setValue("quantity", item.quantity.value || item.quantity);
        setValue("frequency", item.frequency);
        setValue("note", item.note);
        setValue("time", item.time);
        setValue("form", item.formValue);
      }
      if (item.type === "procedure") {
        setInterventionTab(1);
        setSubTabValue(1);
        setValue("procedure", item.inputValue);
        setValue("note", item.note);
      }
      if (item.type === "service") {
        reset({
          inputValue: {},
          condition: [],
          category: {},
          note: "",
        });
        setInterventionTab(1);
        setSubTabValue(0);
        setValue("service", item.inputValue);
        setValue("condition", item.condition);
        item.category && setValue("category", item.category);
        item.note && setValue("note", item.note);
      }
      if (item.type === "certificate") {
        setInterventionTab(2);
        setValue("certificate", item.note);
      }
    },
  };

  const action =
    selectedItem === undefined ? functions.handleAdd : functions.handleEdit;
  const isEdit = selectedItem !== undefined;

  const medicationOptions =
    medication?.map((el: any, index: number) => {
      const item = {
        class:
          el?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDClasseTerapeutica"
          )?.valueString || "-",
        type:
          el?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDTipoProduto"
          )?.valueString || "-",
        description:
          el?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDSubstancia"
          )?.valueString || "-",
        presentation:
          el?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDApresentacao"
          )?.valueString || "-",
        tag:
          el?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDTarja"
          )?.valueString || "-",
        name:
          el?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDProduto"
          )?.valueString || "-",
        value: index,
        code: el?.code,
      };
      return { ...item, label: `${item?.name} - ${item.presentation}` };
    }) || [];

  useEffect(() => {
    if (initial) {
      setInterventionsList([]);
      setSubTabValue(0);
      setMedicationTabValue(0);
      setBase64(undefined);
    }
  }, [initial]);

  useEffect(() => {
    if (clean) {
      reset();
      // Reset específico da tab de exames
      reset({
        service: {},
        condition: [],
        category: {},
        note: "",
      });
      setClean(false);
    }
  }, [clean, reset, state?.patientId]);

  const contextValue = {
    setInterventionsList,
    expandedList,
    setExpandedList,
    control,
    setClean,
    base64,
    setBase64,
    handleSubmit,
    setValue,
    medicationOptions,
    functions,
    selectedInterventions,
    action,
    setMedication,
    selectedItem,
    createDialog,
    setCreateDialog,
    selectProtocol,
    setSelectProtocol,
    isEdit,
    clean,
    subTabValue,
    setSubTabValue,
    setSelectedItem,
    medicationTabValue,
    setMedicationTabValue,
  };

  return (
    <InterventionsContext.Provider value={contextValue}>
      {children}
    </InterventionsContext.Provider>
  );
}

export function useInterventionsContext() {
  return useContext(InterventionsContext);
}
