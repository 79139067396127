import { toast } from "react-toastify";
import { endpoints } from "../../configs/Settings";
import { HTTPResponseStatus } from "../../utils/enum";
import { useAuth } from "../../utils/useAuth";
import { useAxios } from "../../utils/useAxios";

/**
 * @param identifier cpf
 * @returns Array
 */

export async function GetAccessHistory(identifier: string) {
  const url = `${endpoints.CONSENT}/recurso/${identifier}/autorizacao?resultado=Autorizado`;
  const headers = {
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
    Accept: "*/*",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`GetAccessHistory: ${response.status}`);
      }
      return { status: HTTPResponseStatus.Success, data: response.data };
    })
    .catch((error) => {
      console.error("GetAccessHistory ", error);
      // toast.error(`GetAccessHistory Erro: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
