
import { IFeatures } from "../../utils/interfaces";
import { useSessionContext } from "../../contexts/SessionContext";
import { Procedures } from "./Procedures";
import { ContentPaste } from "@mui/icons-material";

export function useProcedures() {
  const { access } = useSessionContext();
  const configs: IFeatures = {
    initial: false,
    active: access.type === "patient",
    path: "/procedures",
    text: "Procedimentos",
    icon: ContentPaste,

    sideMenu: true,
    sideMenuIndex: 4,

    component: () => <Procedures />,
  };

  return configs;
}
