import { PrivateMainLayout } from "../../components/layout";
import { LargeContentBox, PageTitle } from "../../components/common";

import { isMobile } from "react-device-detect";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Business, LocalHospital, Person, Place } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export function Settings() {
  const navigate = useNavigate();

  const cards = [
    {
      name: "Clínica",
      icon: <Business fontSize="large" />,
      description:
        "Informações básicas e opções de gerenciamento da sua clínica",
      path: "/clinic",
    },
    {
      name: "Profissionais",
      icon: <Person fontSize="large" />,
      description: "Gerencie os profissionais de sua clínica",
      path: "/practitioners",
    },
    {
      name: "Localizações",
      icon: <Place fontSize="large" />,
      description: "Gerencie as localicades de sua clínica",
      path: "/locations",
    },
  ];

  return (
    <PrivateMainLayout noSidebar={isMobile} mobileView={isMobile}>
      <LargeContentBox>
        <Stack sx={{ width: "100%" }}>
          <PageTitle>Configurações</PageTitle>
          <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid container spacing={3}>
              {cards.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{ height: "100%" }}
                    onClick={() => navigate(card?.path)}
                  >
                    {" "}
                    {/* Garante que o card ocupe toda a altura */}
                    <CardActionArea
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 1,
                        }}
                      >
                        <Box sx={{ marginBottom: "-8px" }}>
                          {" "}
                          {/* Reduz espaço entre ícone e nome */}
                          {card.icon}
                        </Box>
                      </Box>
                      <CardContent sx={{ flexGrow: 1 }}>
                        {" "}
                        {/* FlexGrow garante que este conteúdo ocupe o espaço */}
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          align="center"
                        >
                          {card.name}
                        </Typography>
                        <Typography
                          textAlign="center"
                          variant="body1"
                          color="neutral700.main"
                          fontWeight={500}
                          mb={1}
                        >
                          {card.description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
