import { IconButton, Stack, styled, TableCell, Tooltip } from "@mui/material";
import {
  Description,
  FileDownload,
  FileUpload,
  UploadFile,
  Visibility,
} from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { ConfirmDocumentDialog } from "../../../../examsolicitation/components/ConfirmDocumentDialog";
import dayjs from "dayjs";
import { useSessionContext } from "../../../../../contexts/SessionContext";
import { GetBinary } from "../../../../../services/fhir/patientsummary/Binary/GetBinary";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export function ServiceRequestActions({
  row,
  handleOpen,
  setBinaryUrl,
  setRefetch,
}: any) {
  const { user } = useSessionContext();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleClickUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const convertToBase64 = (file: File) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    }
  };

  const [fileBase64, setFileBase64] = useState<any>();

  const uploadImage = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setFileBase64(base64);
  };

  const docReferenceData = {
    servicePratice: {
      code: row?.fullResource?.practiceSetting?.coding?.[0]?.code,
      system: row?.fullResource?.practiceSetting?.coding?.[0]?.system,
      display: row?.fullResource?.practiceSetting?.coding?.[0]?.display,
    },
    time: {
      start: dayjs().toISOString(),
      end: dayjs().toISOString(),
    },
  };

  const refs = {
    id: row?.fullResource?.context
      ?.find((el: any) => el?.reference?.includes("Encounter"))
      ?.reference?.split("/")?.[1],
    subject: user?.username,
    appointmentId: row?.fullResource?.context
      ?.find((el: any) => el?.reference?.includes("Appointment"))
      ?.reference?.split("/")?.[1],
  };

  const [base64, setBase64] = useState<any>();

  function downloadBase64File() {
    const linkSource = `data:${base64?.contentType};base64,${base64?.data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = "Solicitação de exame";
    downloadLink.click();
  }

  useEffect(() => {
    async function getDocumentPdf() {
      const response = await GetBinary({ urlBinary: row?.binaryUrl });
      setBase64(response);
    }
    if (row?.binaryUrl) {
      getDocumentPdf();
    }
  }, [row]);

  return (
    <TableCell align="center">
      <Stack direction="row" justifyContent="center">
        {row.resultUrl ? (
          <Tooltip title="Visualizar resultado">
            <IconButton
              onClick={() => {
                setBinaryUrl(row.resultUrl);
                handleOpen();
              }}
            >
              <Visibility />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton
            onClick={handleClickUpload}
            onChange={(e: any) => uploadImage(e)}
          >
            <Tooltip title="Enviar resultado">
              <FileUpload sx={{ color: "primary700.main" }} />
            </Tooltip>
            <VisuallyHiddenInput ref={fileInputRef} type="file" accept=".pdf" />
          </IconButton>
        )}

        {row?.binaryUrl && (
          <>
            <Tooltip title="Visualizar solicitação">
              <IconButton
                onClick={() => {
                  setBinaryUrl(row?.binaryUrl);
                  handleOpen();
                }}
              >
                <Description />
              </IconButton>
            </Tooltip>

            <Tooltip title="Baixar solicitação">
              <IconButton
                onClick={() => {
                  downloadBase64File();
                }}
              >
                <FileDownload />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Stack>

      {fileBase64 && (
        <ConfirmDocumentDialog
          setRenderButton={() => {}}
          setRefetch={setRefetch}
          fileBase64={fileBase64}
          isOpenDialog={fileBase64}
          onCloseDialog={() => {
            setFileBase64(null);
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          }}
          basedOn={row?.fullResource?.basedOn?.filter((el: any) =>
            el.reference?.includes("ServiceRequest")
          )}
          encounterReference={refs}
          data={{
            ...docReferenceData,
            base64: fileBase64?.split(",")?.[1],
            codeValue: "RESULT",
            codeDisplay: "Resultado de exame",
            categoryValue: "resultado",
            categoryDisplay: "Resultado de exame",
          }}
        />
      )}
    </TableCell>
  );
}
