import { Close, Description, PlayArrow } from "@mui/icons-material";
import { Button, CircularProgress, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useState } from "react";
import { useSessionContext } from "../../contexts/SessionContext";
import { useFhirData } from "../../utils/useFhirData";
import { useClinicalRegisterContext } from "../../contexts/ClinicalRegisterContext";
import { GetRelatedPerson } from "../../services/fhir/relatedperson/GetRelatedPerson";
import { oids } from "../../configs/Settings";
import { VerifyResponsibleDialog } from "./VerifyResponsibleDialog";

interface IProfessionalActions {
  status: string;
  dataAppointment: fhir5.Appointment;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const primaryButton: any = {
  icon: <Description />,
  text: {
    booked: "Registros clínicos",
    arrived: "Registros clínicos",
    proposed: "Registros clínicos",
    pending: "Registros clínicos",
  },
  backgroundColor: {
    booked: "primary700.main",
    arrived: "primary700.main",
    proposed: "neutral700.main",
    pending: "neutral700.main",
  },

  hover: {
    booked: "primary800.main",
    arrived: "primary800.main",
    proposed: "neutral600.main",
    pending: "neutral600.main",
  },
};

const secondaryButtonStyle: any = {
  icon: {
    booked: <PlayArrow />,
    arrived: <PlayArrow />,
    proposed: <Close />,
    pending: <Close />,
  },
  text: {
    booked: "Iniciar atendimento",
    arrived: "Iniciar atendimento",
    proposed: "Cancelar consulta",
    pending: "Cancelar consulta",
  },
  borderColor: {
    booked: "primary700.main",
    arrived: "primary700.main",
    proposed: "error600.main",
    pending: "error600.main",
  },
  color: {
    booked: "primary700.main",
    arrived: "primary700.main",
    proposed: "error600.main",
    pending: "error600.main",
  },
};

export function ProfessionalActions({
  status,
  setIsOpen,
  dataAppointment,
}: IProfessionalActions) {
  const navigate = useNavigate();
  const { access } = useSessionContext();

  const { verifyEncounter, inProgress } = useClinicalRegisterContext();

  const [loading, setLoading] = useState(false);

  const patientId = useFhirData
    .findParticipant(dataAppointment?.participant, "Patient")
    ?.reference?.split("-")?.[1];

  const styleSXSecondary = {
    fontSize: 16,
    border: "1px solid",
    borderColor: secondaryButtonStyle.borderColor[status || "pending"],
    gap: 1,
    borderRadius: 3,
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    color: secondaryButtonStyle.color[status || "pending"],
    backgroundColor: "transparent",
    ":hover": {
      color: "primary800.main",
    },
  };

  const [verifyDialog, setVerifyDialog] = useState<boolean>(false);
  const [related, setRelated] = useState<any>([]);

  async function fetchRelated() {
    const relatedResponse = await GetRelatedPerson({
      patient: `${oids.cpf}-${patientId}`,
    }).then((response) => response?.data?.entry);
    const verifyRelated = relatedResponse?.filter(
      (el: any) =>
        el?.resource?.identifier &&
        !el?.resource?.active &&
        el?.resource?.period?.start
    );
    if (verifyRelated?.length) setRelated(verifyRelated);
    else setRelated([]);
    return verifyRelated;
  }
  return (
    <>
      <Stack width="100%" gap={{ xs: 1, md: 2 }}>
        {access?.roles?.includes("practitioner") && (
          <Button
            fullWidth
            sx={{
              fontSize: 16,
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 3,
              textTransform: "none",
              color: "neutral0.main",
              backgroundColor: `${
                primaryButton.backgroundColor[status || "pending"]
              }`,
              ":hover": {
                backgroundColor: `${primaryButton.hover[status || "pending"]}`,
              },
              ":disabled": {
                border: `1px solid`,
                borderColor: "neutral300.main",
                color: "neutral600.main",
                backgroundColor: "neutral300.main",
              },
            }}
            disabled={loading}
            onClick={async () => {
              const verifyRelated = await fetchRelated();

              if (verifyRelated?.length) {
                setVerifyDialog(true);
              } else {
                setLoading(true);
                await verifyEncounter(dataAppointment);
              }
            }}
          >
            {loading ? (
              <CircularProgress size={25} />
            ) : (
              secondaryButtonStyle.icon[status]
            )}
            {inProgress === dataAppointment?.id
              ? "Continuar atendimento"
              : secondaryButtonStyle.text[status]}
          </Button>
        )}

        <Stack direction="row" spacing={2}>
          <Button
            fullWidth
            sx={styleSXSecondary}
            onClick={() => {
              if (patientId)
                navigate("/summary", {
                  state: {
                    patientId,
                    appointment: dataAppointment,
                  },
                });
            }}
          >
            {primaryButton.icon} {primaryButton.text[status]}
          </Button>

          <Button
            fullWidth
            sx={{
              gap: 1,
              border: "1px solid",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 3,
              fontSize: 16,
              textTransform: "none",
              backgroundColor: "transparent",
              borderColor: "#F8727D",
              color: "#F8727D",
              ":hover": {
                backgroundColor: "neutral0.main",
              },
            }}
            onClick={() => setIsOpen(true)}
          >
            <Close /> Cancelar consulta
          </Button>
        </Stack>
      </Stack>

      {verifyDialog && (
        <VerifyResponsibleDialog
          handleClose={() => setVerifyDialog(false)}
          isOpen={true}
          loading={loading}
          setLoading={setLoading}
          dataAppointment={dataAppointment}
          related={related}
          fetchRelated={fetchRelated}
          patientId={patientId}
        />
      )}
    </>
  );
}
