import { IconButton, TableCell, Tooltip } from "@mui/material";
import { Assignment, MoreHoriz } from "@mui/icons-material";

export function AllergiesActions({ row }: any) {
  return (
    <TableCell align="center">
      <Tooltip title="Abrir opções">
        <IconButton>
          <MoreHoriz />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
}
