import { Warning } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useFhirData } from "../../../../utils/useFhirData";
import { ManoleVerification } from "../../../../services/manole/auth/ManoleVerification";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { toast } from "react-toastify";
import { useState } from "react";
import { InterdrugsDialog } from "./InterdrugsDialog";
import { LoadingButton } from "@mui/lab";

export function InterdrugsVerification({ summary, itens }: any) {
  const { manoleToken } = useSessionContext();
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [verificationData, setVerificationData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const medicationList =
    useFhirData
      .extractPatientSummaryResource(
        summary,
        "DocumentReference",
        "prescricao"
      )?.[0]
      ?.entry?.filter(
        (el: any) => el?.resource?.resourceType === "MedicationRequest"
      ) || [];

  const declaratedData =
    useFhirData.extractPatientSummaryResource(summary, "MedicationRequest")?.[0]
      ?.entry || [];

  const itensSubstance =
    itens?.map((el: any) => {
      return {
        substance: el.inputValue?.description,
        name: el.name,
        type: "prescription",
      };
    }) || [];

  const medications = [...medicationList, ...declaratedData];

  const medicationSubstance =
    medications
      ?.filter((medication: any) => medication?.resource?.status === "active")
      ?.map((el: any) => {
        return {
          substance: el.resource?.medication?.concept?.coding?.[0]?.display,
          name: el.resource?.medication?.concept?.text,
          type: el?.resource?.requester
            ? "Prescrição"
            : "Declarado pelo paciente",
        };
      }) || [];

  const verificationSubstances = [
    ...itensSubstance,
    ...medicationSubstance,
  ]?.map((el: any) => el.substance?.toLowerCase() || el.name?.toLowerCase());

  const handleClose = () => {
    setIsOpenDialog(false);
  };

  const handleVerification = async () => {
    try {
      setLoading(true);
      const verificationResponse = await ManoleVerification(
        verificationSubstances,
        manoleToken
      );
      if (!verificationResponse?.items?.length) {
        toast.success("Nenhuma interação encontrada!");
      } else {
        toast.warn(`Interações encontradas!`);
      }
      setVerificationData(verificationResponse);
      setIsOpenDialog(true);
    } catch (err) {
      toast.error("Falha na verificação!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Stack>
        <LoadingButton
          loading={loading}
          sx={{
            width: "fit-content",
            textTransform: "none",
            color: "orange",
            border: "1px solid orange",
            borderRadius: "5px",
            padding: "5px 10px",
          }}
          startIcon={<Warning />}
          onClick={handleVerification}
        >
          Verificar interações
        </LoadingButton>
      </Stack>

      {isOpenDialog && (
        <InterdrugsDialog
          isOpen={isOpenDialog}
          handleClose={handleClose}
          response={verificationData}
          itens={[medicationSubstance, itensSubstance]}
        />
      )}
    </>
  );
}
