import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useViewContext } from "../../../contexts/ViewContext";

import { IconButton, TableCell, Tooltip } from "@mui/material";
import { Check, Close, Description, PlayArrow } from "@mui/icons-material";
import { NextAppointmentsDialog } from "./NextAppointmentsDialog";
import { useClinicalRegisterContext } from "../../../contexts/ClinicalRegisterContext";
import { VerifyResponsibleDialog } from "../../../components/schedule/VerifyResponsibleDialog";
import { GetRelatedPerson } from "../../../services/fhir/relatedperson/GetRelatedPerson";
import { oids } from "../../../configs/Settings";

export function NextAppointmentsActions({ row }: any) {
  const navigate = useNavigate();
  const { verifyEncounter } = useClinicalRegisterContext();
  const { availableRoles } = useViewContext();
  const [loading, setLoading] = useState(false);
  const patientId = row?.reference?.participant?.split("-")?.[1];

  const [verifyDialog, setVerifyDialog] = useState<boolean>(false);
  const [related, setRelated] = useState<any>([]);

  async function fetchRelated() {
    const relatedResponse = await GetRelatedPerson({
      patient: `${oids.cpf}-${patientId}`,
    }).then((response) => response?.data?.entry);
    const verifyRelated = relatedResponse?.filter(
      (el: any) =>
        el?.resource?.identifier &&
        !el?.resource?.active &&
        el?.resource?.period?.start
    );
    if (verifyRelated?.length) setRelated(verifyRelated);
    else setRelated([]);
    return verifyRelated;
  }

  const [dialogCancelAppointment, setDialogCancelAppointment] =
    useState<boolean>(false);
  return (
    <>
      {dialogCancelAppointment && (
        <NextAppointmentsDialog
          setModalOpen={setDialogCancelAppointment}
          open={dialogCancelAppointment}
          row={row}
        />
      )}
      <TableCell align="center">
        {row?.status !== "cancelled" && (
          <Tooltip title="Cancelar consulta">
            <IconButton
              sx={{
                color: "error600.main",
                "&:hover": {
                  background: "error700.main",
                },
              }}
              onClick={() => setDialogCancelAppointment(true)}
            >
              <Close />
            </IconButton>
          </Tooltip>
        )}

        {(row?.status === "pending" || row?.status === "proposed") && (
          <IconButton>
            <Check />
          </IconButton>
        )}

        {(row?.status === "booked" || row?.status === "arrived") &&
          availableRoles?.includes("practitioner") && (
            <Tooltip title="Iniciar atendimento">
              <IconButton
                disabled={loading}
                onClick={async () => {
                  const verifyRelated = await fetchRelated();

                  if (verifyRelated?.length) {
                    setVerifyDialog(true);
                  } else {
                    setLoading(true);
                    await verifyEncounter(row?.appointment);
                  }
                }}
              >
                <PlayArrow />
              </IconButton>
            </Tooltip>
          )}

        {(row?.status === "pending" || row?.status === "proposed") && (
          <IconButton>
            <Check />
          </IconButton>
        )}

        <Tooltip title="Acessar registros">
          <IconButton
            onClick={() => {
              if (patientId)
                navigate("/summary", {
                  state: {
                    patientId,
                    appointment: row?.appointment,
                  },
                });
            }}
          >
            <Description />
          </IconButton>
        </Tooltip>
      </TableCell>

      {verifyDialog && (
        <VerifyResponsibleDialog
          handleClose={() => setVerifyDialog(false)}
          isOpen={true}
          loading={loading}
          setLoading={setLoading}
          dataAppointment={row?.appointment}
          related={related}
          fetchRelated={fetchRelated}
          patientId={patientId}
        />
      )}
    </>
  );
}
