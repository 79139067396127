import { Controller } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";

import { FormInputProps } from "../types";

export function FormDateInput({
  name,
  control,
  label,
  required,
  pattern,
}: FormInputProps) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
        pattern,
      }}
      render={({
        field: { onChange, value, ...field },
        fieldState: { error },
      }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateField
            sx={{
              "& .MuiFilledInput-root": { backgroundColor: "neutral100.main" },
            }}
            slotProps={{
              textField: {
                error: !!error,
                variant: "filled",
              },
            }}
            helperText={error ? error.message : null}
            label={label}
            value={value}
            // error={error !== undefined}
            onChange={onChange}
            format="DD/MM/YYYY"
            fullWidth
            {...field}
          />
        </LocalizationProvider>
      )}
    />
  );
}
