import { Box, Button, Skeleton, Stack, Typography } from "@mui/material";
import { HealthMedicineDetails } from "./HealthMedicineDetails";
import { AuxiliarButtonHealthMedicine } from "./AuxiliarButtonHealthMedicine";
import { isMobile } from "react-device-detect";
import {
  CircularLoading,
  NoDataWarning,
  PrimaryLink,
} from "../../../components/common";
import { ArrowForward } from "@mui/icons-material";
import { useState } from "react";
import { ModalNewMedication } from "./ModalNewMedication";

interface IProps {
  image: any;
  text: string;
  CardType?: string;
  data: any;
  setState?: any;
  setRefetch?: any;
  loading?: boolean;
  control?: any;
}

export function HealthMedicineInformation({
  image,
  text,
  CardType,
  control,
  data,
  setState,
  setRefetch,
  loading,
}: IProps) {
  const [openNewMedicament, setOpenNewMedicament] = useState(false);

  const onClickLink =
    data?.length === 0
      ? "/"
      : data?.find((el: any) => el.type === "examSolicitation")
      ? "/examsolicitation"
      : "/medicalprescription";

  return (
    <Stack
      sx={{
        width: "50%",
        minWidth: "100px",
        height: { xs: "fit-content", md: "100%" },
        minHeight: { xs: "100%", md: "fit-content" },
        backgroundColor: "neutral0.main",
        borderRadius: "16px",
      }}
      alignItems="flex-start"
    >
      <Box
        component="img"
        src={image}
        width="100%"
        height="auto"
        alt="profissionais de saúde"
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        paddingRight="16px"
        alignItems="center"
      >
        {isMobile ? (
          <>
            <Typography
              minHeight={{ xs: "60px", sm: "0px" }}
              variant="body1"
              fontWeight={600}
              color="neutral700.main"
              padding="4px 8px"
            >
              {text}
            </Typography>
          </>
        ) : (
          <>
            <Typography
              minHeight={{ xs: "60px", sm: "0px" }}
              variant="subtitle2"
              fontWeight={600}
              color="neutral700.main"
              padding="16px 16px"
            >
              {text}
            </Typography>
            <PrimaryLink variant="body1" link={onClickLink} text="Mais..." />
          </>
        )}
      </Stack>
      {!isMobile ? (
        <>
          <Stack
            alignItems="flex-start"
            width="100%"
            height="100%"
            padding="0 16px"
            mb={2}
            sx={{
              display: { xs: "none", sm: "initial" },
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "0.5em",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "primary700.main",
              },
            }}
          >
            {loading ? (
              <Stack width="100%" height="100%" mt={2}>
                <Skeleton variant="rectangular" width="100%" height="90%" />
              </Stack>
            ) : data?.length ? (
              data?.map((el: any) => (
                <HealthMedicineDetails
                  fullData={el.fullResource}
                  type={el.type}
                  name={el.name}
                  result={el.result}
                  date={el.date}
                  setRefetch={setRefetch}
                />
              ))
            ) : (
              <Stack width="100%" mt={2}>
                <NoDataWarning />
              </Stack>
            )}
            {/* {CardType === "medication" ? (
              <Button
                component="label"
                variant="text"
                endIcon={<ArrowForward />}
                sx={{
                  width: "fit-content",
                  color: "primary700.main",
                  margin: "8px 0",
                  padding: "8px",
                }}
                onClick={() => setOpenNewMedicament(true)}
              >
                <Typography
                  variant="subtitle2"
                  textTransform="lowercase"
                  sx={{
                    "&::first-letter": {
                      textTransform: "uppercase",
                    },
                  }}
                >
                  Registrar novo medicamento
                </Typography>
              </Button>
            ) : (
              ""
            )} */}
            {openNewMedicament && (
              <ModalNewMedication
                isOpen={openNewMedicament}
                setRefetch={setRefetch}
                setModalOpen={setOpenNewMedicament}
              />
            )}
          </Stack>
          {CardType === "medication" ? (
            <Button
              component="label"
              variant="text"
              endIcon={<ArrowForward />}
              sx={{
                width: "fit-content",
                color: "primary700.main",
                margin: "0 8px 8px",
                //padding: "8px",
                borderRadius: "4px",
              }}
              onClick={() => setOpenNewMedicament(true)}
            >
              <Typography
                variant="subtitle2"
                textTransform="lowercase"
                sx={{
                  "&::first-letter": {
                    textTransform: "uppercase",
                  },
                }}
              >
                Registrar novo medicamento
              </Typography>
            </Button>
          ) : (
            ""
          )}
        </>
      ) : (
        <Stack
          width="100%"
          height="100%"
          justifyContent="flex-end"
          alignItems="flex-start"
          sx={{ position: "relative", bottom: 5 }}
        >
          <AuxiliarButtonHealthMedicine onClick={() => setState(true)} />
        </Stack>
      )}
    </Stack>
  );
}
