import { Settings } from "@mui/icons-material";
import { IFeatures } from "../../utils/interfaces";
import { ScheduleManage } from "./ScheduleManage";

import { useSessionContext } from "../../contexts/SessionContext";

import { usePractitionerRoleContext } from "../../contexts/PractitionerRoleContext";

export function useScheduleManager() {
  const { access } = useSessionContext();

  const { userSpecialties } = usePractitionerRoleContext();

  const configs: IFeatures = {
    active: access.type === "professional" && !!userSpecialties,
    path: "/schedule",
    icon: Settings,
    text: "Gerenciar agenda",

    subitem: true,
    parent: "Agenda",

    sideMenu: true,
    sideMenuIndex: 0,

    component: () => <ScheduleManage />,
  };

  return configs;
}
