import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

import { FormInputProps } from "../types";

interface ITextProps extends FormInputProps {
  variant?: "filled" | "outlined";
  multiline?: boolean;
  rows?: number;
  hiddenLabel?: boolean;
  type?: string;
}

export function FormTextInput({
  name,
  control,
  label,
  required,
  pattern,
  placeholder,
  mask,
  multiline,
  maxLength,
  shrink,
  autoFocus,
  rows,
  variant,
  hiddenLabel,
  defaultValue,
  type,
}: ITextProps) {
  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      control={control}
      rules={{
        required,
        pattern,
      }}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => (
        <TextField
          type={type}
          multiline={multiline}
          sx={{
            "& .MuiFilledInput-root": { backgroundColor: "neutral100.main" },
          }}
          autoFocus={autoFocus}
          placeholder={placeholder || ""}
          InputLabelProps={{
            shrink,
          }}
          rows={rows}
          helperText={error ? error.message : null}
          size="medium"
          error={error !== undefined}
          onChange={(e: any) => {
            const formattedValue = mask ? mask(e.target.value) : e.target.value;
            onChange(formattedValue);
          }}
          onBlur={onBlur}
          inputRef={ref}
          value={mask ? mask(value) : value}
          label={label}
          inputProps={{ maxLength }}
          fullWidth
          variant={variant === "outlined" ? "outlined" : "filled"}
          hiddenLabel={hiddenLabel || false}
        />
      )}
    />
  );
}
