import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import { auxiliarData } from "../AuxiliarData";

import { IContact } from "../interfaces";

export async function ProfileContactPatch(newContact: IContact) {
  const url = `${endpoints.Scim2}/Users/${newContact.id}`;
  const data = auxiliarData.ProfileContactPatch(newContact);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: await useAuth.getUserToken(),
  };

  const response = await useAxios
    .patch(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`ProfileContactPatch error: ${response.status}`);
      }
      return { status: HTTPResponseStatus.Success, data: response.data };
    })
    .catch((error) => {
      console.error("ProfileContactPatch ", error);
      // toast.error(`ProfileContactPatch Erro: ${error?.response?.status}`);
      throw new Error();
    });

  if (response) return response;
}
