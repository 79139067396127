import { ContentBoxTitle } from "../../../components/common";
import { Stack, Typography } from "@mui/material";
import {
  FormAutoComplete,
  FormButtonGroup,
  FormMultipleAutoComplete,
  FormTextInput,
} from "../../../components/form";
import { useNavigate } from "react-router-dom";
import { UFValueSet } from "./form/ValueSet";
import { IFormInput } from "../Types";
import { useState } from "react";
import {
  GetPractitionerRole,
  UpdatePractitionerRole,
} from "../../../services/fhir";
import { useSessionContext } from "../../../contexts/SessionContext";
import { oids } from "../../../configs/Settings";
import { toast } from "react-toastify";

interface IProps {
  control: any;
  onNextButtonClick: Function;
  specialties: {
    label: string;
    value: string;
  }[];
  loading: boolean;
  edit?: boolean;
}

export default function ProfessionalForm({
  control,
  onNextButtonClick,
  specialties,
  loading,
  edit,
}: IProps) {
  const navigate = useNavigate();
  const [Newloading, setNewloading] = useState(false);
  const { user, access } = useSessionContext();

  const onSubmit = async (data: IFormInput) => {
    setNewloading(true);

    try {
      const practitionerRole = await GetPractitionerRole({
        id: `${access?.organizationId}-${oids.cpf}-${user.username}`,
      });

      await UpdatePractitionerRole(practitionerRole, {
        crm: data?.crm,
        uf: data?.uf,
        speciality: data?.speciality,
      });

      toast.success("Registro profissional realizado com sucesso!");
      navigate("/");
      window.location.reload();
    } catch (err) {
      console.log("err", err);
    } finally {
      setNewloading(false);
    }
  };

  return (
    <Stack
      width="100%"
      height={{ xs: "fit-content", md: "100%" }}
      flex={"1"}
      justifyContent={{ xs: "flex-start", md: "center" }}
      alignItems="center"
      spacing={4}
    >
      {!edit && (
        <>
          <ContentBoxTitle>Informações básicas</ContentBoxTitle>
          <Typography
            variant="body1"
            color="neutral700.main"
            textAlign="center"
            m={{ xs: 2, md: 5 }}
          >
            Insira seu CRM e sua especialidade clínica para criar sua conta como
            profissional de saúde
          </Typography>
        </>
      )}

      <Stack direction="row" width="100%" spacing={2}>
        <FormTextInput
          name="crm"
          maxLength={6}
          control={control}
          label="CRM"
          required={true}
          autoFocus
        />

        <Stack width="40%">
          <FormAutoComplete
            name="uf"
            control={control}
            label="UF"
            selectOptions={UFValueSet}
            required
          />
        </Stack>
      </Stack>

      <FormMultipleAutoComplete
        name="speciality"
        control={control}
        label="Especialidade"
        selectOptions={specialties}
        loading={loading}
        required
      />

      {!edit && (
        <Stack
          width="100%"
          justifyContent="flex-end"
          direction="row"
          spacing={3}
          mt={2}
        >
          <FormButtonGroup
            onGoBackButtonClick={() => navigate("/")}
            onNextButtonClick={onNextButtonClick(onSubmit)}
            goBackButtonText="Voltar"
            nextButtonText="Finalizar"
            loading={Newloading}
          />
        </Stack>
      )}
    </Stack>
  );
}
