import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import { auxiliarData } from "../AuxiliarData";

import { IDeviceID } from "../interfaces";

export async function DeviceIDPatch({
  idUser,
  operation,
  pathName,
  valueField,
}: IDeviceID) {
  const url = `${endpoints.Scim2}/Users/${idUser}`;
  const data = auxiliarData.DeviceIDPatch({
    idUser: idUser,
    operation: operation,
    pathName: pathName,
    valueField: valueField,
  });
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: await useAuth.getUserToken(),
  };

  const response = await useAxios
    .patch(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`DeviceIDPatch error: ${response.status}`);
      }
      return { status: HTTPResponseStatus.Success, data: response.data };
    })
    .catch((error) => {
      console.error("DeviceIDPatch ", error);
      // toast.error(`DeviceIDPatch Erro: ${error?.response?.status}`);
      throw new Error();
    });

  if (response) return response;
}
