import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { GetQuestionnaire } from "../services/fhir/questionnaire/GetQuestionnaire";
import { endpoints } from "../configs/Settings";
import { GetQuestionnaireResponse } from "../services/fhir/patientsummary/questionnaireResponse/GetQuestionnaireResponse";
import { useLocation } from "react-router-dom";

interface QuestionnaireContextValue {
  questionnaire: any;
  loadingQuestionnaire: boolean;
  loadingResponse: boolean;

  response: any;
}

export const QuestionnaireContext = createContext<QuestionnaireContextValue>(
  null!
);

export function QuestionnaireContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [questionnaire, setQuestionnaire] = useState<any>();
  const [response, setResponse] = useState<any>();

  const [loadingQuestionnaire, setLoadingQuestionnaire] =
    useState<boolean>(true);
  const [loadingResponse, setLoadingResponse] = useState<boolean>(true);

  const { state } = useLocation();

  useEffect(() => {
    async function getQuestionnaire() {
      setLoadingQuestionnaire(true);
      try {
        const fetchQuestionnaire = await GetQuestionnaire({
          questionnaire: endpoints.QUESTIONNARIE_ID,
        });

        setQuestionnaire(fetchQuestionnaire);
      } catch (error) {
        setQuestionnaire(undefined);
        console.log(error);
      } finally {
        setLoadingQuestionnaire(false);
      }
    }

    if (!questionnaire) getQuestionnaire();
  }, []);

  useEffect(() => {
    async function fetchQuestionnairesResponse() {
      setLoadingResponse(true);
      try {
        const fetchQuestionnaireResponse = await GetQuestionnaireResponse({
          patient: state?.encounterReference?.subject,
          questionnaire: endpoints.QUESTIONNARIE_ID,
        });

        setResponse(fetchQuestionnaireResponse);
      } catch (error) {
        setResponse(undefined);
        console.log(error);
      } finally {
        setLoadingResponse(false);
      }
    }
    if (state?.encounterReference?.subject) fetchQuestionnairesResponse();
  }, [state?.encounterReference?.subject]);

  const contextValue = {
    response,
    questionnaire,
    loadingQuestionnaire,
    loadingResponse,
  };

  return (
    <QuestionnaireContext.Provider value={contextValue}>
      {children}
    </QuestionnaireContext.Provider>
  );
}

export function useQuestionnaireContext() {
  return useContext(QuestionnaireContext);
}
