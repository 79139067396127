import { Grid } from "@mui/material";
import { FormAutoComplete } from "../../../../../components/form";
import { InputText } from "../../../../ClinicalRegister/components/QuestionnaireViewer/components/Form";

const clinicalStatusOptions = [
  {
    value: "active",
    label: "Ativo",
  },
  {
    value: "inactive",
    label: "Inativo",
  },
  {
    value: "resolved",
    label: "Resolvido",
  },
];

const categoryOptions = [
  {
    value: "food",
    label: "Alimento",
  },
  {
    value: "medication",
    label: "Medicamento",
  },
  {
    value: "environment",
    label: "Ambiente",
  },
  {
    value: "biologic",
    label: "Biológico",
  },
];

const criticalityOptions = [
  {
    value: "low",
    label: "Baixo",
  },
  {
    value: "high",
    label: "Alto",
  },
  {
    value: "unable-to-assess",
    label: "Incapaz de avaliar",
  },
];

const verificationStatusOptions = [
  {
    value: "unconfirmed",
    label: "Não confirmado",
  },
  {
    value: "confirmed",
    label: "Confirmado",
  },
  {
    value: "presumed",
    label: "Presumido",
  },
  {
    value: "refuted",
    label: "Refutado",
  },
];

export function AllergyForm({ control }: any) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={12}>
        <InputText
          name="allergy.substance"
          label="Substancia"
          required
          control={control}
          autoFocus
        />
      </Grid>
      <Grid item xs={6}>
        <FormAutoComplete
          loading={false}
          name="allergy.category"
          label="Categoria"
          required
          control={control}
          selectOptions={categoryOptions}
        />
      </Grid>

      <Grid item xs={6}>
        <FormAutoComplete
          loading={false}
          name="allergy.criticality"
          label="Criticidade"
          required
          control={control}
          selectOptions={criticalityOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <FormAutoComplete
          loading={false}
          name="allergy.clinicalStatus"
          label="Status clínico"
          required
          control={control}
          selectOptions={clinicalStatusOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <FormAutoComplete
          loading={false}
          name="allergy.verificationStatus"
          label="Status de verificação"
          required
          control={control}
          selectOptions={verificationStatusOptions}
        />
      </Grid>
    </Grid>
  );
}
