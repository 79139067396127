import { Box, Skeleton, Stack } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";

import { useSessionContext } from "../../../contexts/SessionContext";
import {
  BundlePatientSummary,
  GetAppointment,
  GetDocumentReference,
} from "../../../services/fhir";
import { DateFilterType } from "../../../utils/interfaces";
import { Schedule } from "../../../components/schedule";
import { useDatetime } from "../../../utils/useDatetime";

import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { isMobile } from "react-device-detect";
import { HealthMedicineInformation } from "./HealthMedicineInformation";
import JourneyCards from "./JourneyCards";
import imgMedication from "../assets/medicationAux.svg";
import imgExam from "../assets/examAuxiliar.svg";

import { AuxiliarControlDosage } from "../../auxiliarcontroldosage/AuxiliarControlDosage";
import { usePatientHealthCareInformationContext } from "../../../contexts/PatientHealthCareInformationContext";
import { DeviceIDPatch } from "../../../services/scim/profile/DeviceIDPatch";
import { Carrousel } from "./Caroussel/Carrousel";
import { useFhirData } from "../../../utils/useFhirData";

interface ISelectOption {
  label: string;
  value: string;
}

export interface IFormInput {
  healthcareService: ISelectOption | undefined;
  organization: ISelectOption | undefined;
  practitioner: ISelectOption | undefined;
}

const defaultValues = {
  healthcareService: undefined,
  organization: undefined,
  practitioner: undefined,
};

export function PatientDashboard() {
  const navigate = useNavigate();
  const { setRefetchNote, setRefetchPrescription, setRefetchServiceRequest } =
    usePatientHealthCareInformationContext();
  const { inviteDialog, user, fetchUser } = useSessionContext();
  const { formatDateToISO, isDateFilterTypeDay, isSameYearAndMonthAsToday } =
    useDatetime;
  const methods = useForm<IFormInput>({ defaultValues });
  const { control } = methods;

  const [appointments, setAppointments] =
    useState<fhir5.Bundle<fhir5.Appointment>>();
  const [dateFilter, setDateFilter] = useState<Dayjs>(dayjs());
  const [filterType, setFilterType] = useState<DateFilterType>("month");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPrescription, setLoadingPrescription] =
    useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [refetchMedication, setRefetchMedication] = useState<boolean>(false);
  const [results, setResults] = useState<any>();
  const [medicationScreen, setMedicationScreen] = useState<boolean>(false);
  const [solicitationScreen, setSolicitationScreen] = useState<boolean>(false);

  const [medicationRequest, setMedicationRequest] = useState<any>();
  const [serviceRequest, setServiceRequest] = useState<any>();
  const [documentReferenceExam, setDocumentReferenceExam] = useState<any>();

  const tokenMobile = localStorage.getItem("device_id") || undefined;

  const updateDeviceIdMobile = async () => {
    if (user?.tokenFirebaseMobile === undefined && isMobile) {
      try {
        await DeviceIDPatch({
          idUser: user?.id,
          operation: "add",
          pathName:
            "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User:deviceIDMobile",
          valueField: tokenMobile,
        });
      } catch (err) {
        console.log("err", err);
      }

      fetchUser();
      return;
    }
    if (tokenMobile !== user?.tokenFirebaseMobile) {
      try {
        await DeviceIDPatch({
          idUser: user?.id,
          operation: "replace",
          pathName:
            "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User:deviceIDMobile",
          valueField: tokenMobile,
        });
      } catch (err) {
        console.log("err", err);
      }
      fetchUser();
      return;
    }
  };

  useEffect(() => {
    if (user?.tokenFirebaseMobile === tokenMobile || tokenMobile === undefined)
      return;
    else {
      updateDeviceIdMobile();
    }
  }, [tokenMobile && user?.tokenFirebaseMobile]);

  const medicationData = medicationRequest
    // ?.filter((medication: any) =>
    //   dayjs(medication?.resource?.effectiveDosePeriod?.end).isAfter(dayjs())
    // )
    ?.map((el: any) => ({
      type: "medication",
      name: `${el?.resource?.medication?.concept?.text} ` || "",

      date: dayjs(el?.resource?.effectiveDosePeriod?.start).format(
        "DD/MM/YYYY"
      ),
      fullResource: el?.resource,
    }));

  const examData = documentReferenceExam
    ?.filter(
      (filterEl: any) =>
        filterEl?.resource?.category?.[0]?.coding?.[0]?.display !==
        "Procedimento"
    )
    ?.map((el: any) => {
      const auxDescription = serviceRequest?.filter((request: any) => {
        const findRef = el?.resource?.basedOn?.find((basedOn: any) =>
          basedOn?.reference.includes(request.resource.id)
        );
        return findRef;
      });

      const auxResults = results?.find((result: any) => {
        const findRef = auxDescription?.find(
          (service: any) =>
            service?.resource?.id ===
            result?.resource?.basedOn
              ?.find((bas: any) => bas.reference.includes("ServiceRequest"))
              ?.reference?.split("/")?.[1]
        );
        return findRef;
      });

      const name =
        auxDescription
          ?.map(
            (serviceRequest: any) =>
              serviceRequest?.resource?.code?.concept?.coding?.[0]?.display
          )
          ?.join(" , ") || "";

      return {
        name: name,
        result: auxResults,
        type: "examSolicitation",
        date: dayjs(el?.resource?.date).format("DD/MM/YYYY"),
        fullResource: el?.resource,
      };
    });
  // ?.filter((e: any) => !e.result);

  useEffect(() => {
    async function fetchAppointments() {
      setLoading(true);

      const startDate =
        isDateFilterTypeDay(filterType) || isSameYearAndMonthAsToday(dateFilter)
          ? formatDateToISO(dateFilter)
          : formatDateToISO(dateFilter.startOf("month"));

      const endDate = isDateFilterTypeDay(filterType)
        ? formatDateToISO(dateFilter)
        : formatDateToISO(dateFilter.endOf("month"));

      const response = await GetAppointment({
        ehrrunner: `${user.username}`,
        startDate,
        endDate,
        status: ["booked"],
        sort: "date",
        includeLocation: true,
      });

      setAppointments(response);
      setLoading(false);
    }
    fetchAppointments();
  }, [
    dateFilter,
    filterType,
    user.username,
    formatDateToISO,
    isDateFilterTypeDay,
    isSameYearAndMonthAsToday,
    refetch,
  ]);

  useEffect(() => {
    setLoadingPrescription(true);
    async function fetchPrescriptions() {
      const response = await GetDocumentReference({
        patient: user.username,
        category: "prescricao",
      });

      const bundleResponse = await BundlePatientSummary({
        identifier: user?.username,
        entries: ["MedicationRequest"],
      });

      const declaratedMedications =
        useFhirData
          .extractPatientSummaryResource(
            bundleResponse,
            "MedicationRequest"
          )?.[0]
          ?.entry?.filter((el: any) => el?.resource?.informationSource) || [];

      const responseService = await GetDocumentReference({
        patient: user?.username,
        category: "service-request",
      });

      const resultsResponse = await GetDocumentReference({
        patient: user?.username,
        category: "resultado",
      });

      const medicationRequest =
        response?.entry?.filter(
          (el: any) => el?.resource?.resourceType === "MedicationRequest"
        ) || [];

      const documentReferenceExamFilter =
        responseService?.entry?.filter(
          (el: any) => el?.resource?.resourceType === "DocumentReference"
        ) || [];

      const serviceRequest =
        responseService?.entry?.filter(
          (el: any) => el?.resource?.resourceType === "ServiceRequest"
        ) || [];

      setResults(resultsResponse?.entry);
      setMedicationRequest([...declaratedMedications, ...medicationRequest]);
      setServiceRequest(serviceRequest);
      setDocumentReferenceExam(documentReferenceExamFilter);
      setLoadingPrescription(false);
    }
    fetchPrescriptions();
  }, [user.username, refetchMedication]);

  useEffect(() => {
    setRefetchNote(true);
    setRefetchPrescription(true);
    setRefetchServiceRequest(true);
  }, []);

  if (inviteDialog) {
    return <Skeleton variant="rectangular" width="100%" height="15vh" />;
  }

  return (
    <Stack width="100%" height="fit-content" gap={1}>
      <Carrousel />

      {isMobile && (
        <>
          <Stack direction="column">
            <JourneyCards />

            <Stack
              direction="row"
              justifyContent="flex-start"
              width="100%"
              minWidth="100px"
              overflow={{ xs: "auto", md: "visible" }}
              spacing={2}
              mb={2}
            >
              <HealthMedicineInformation
                image={imgMedication}
                text="Meus Medicamentos"
                data={medicationData}
                setState={setMedicationScreen}
                setRefetch={setRefetchMedication}
              />
              <HealthMedicineInformation
                image={imgExam}
                text="Solicitações de exames"
                data={examData}
                setState={setSolicitationScreen}
                setRefetch={setRefetchMedication}
              />
            </Stack>
            <Box
              sx={{
                width: { xs: "100%", lg: "70%" },
                //height: "100%",
                backgroundColor: "neutral0.main",
                borderRadius: { xs: "8px", md: "16px" },
                mb: 2,
                padding: { xs: "16px", md: "24px 40px" },
              }}
            >
              <Schedule
                bundle={appointments}
                dateFilter={dateFilter}
                filterType={filterType}
                setDateFilter={setDateFilter}
                setFilterType={setFilterType}
                loading={loading}
                setRefetch={setRefetch}
                refetch={refetch}
              />
            </Box>
          </Stack>
        </>
      )}
      {!isMobile && (
        <>
          <Stack
            direction={{ xs: "column", lg: "row" }}
            gap="24px"
            height={{ xs: "200px", sm: "400px", lg: "70vh" }}
            // minHeight="70vh"
          >
            <Box
              sx={{
                width: { xs: "100%", lg: "70%" },
                height: "100%",
                backgroundColor: "neutral0.main",
                borderRadius: { xs: "8px", md: "16px" },
                mb: 2,
                padding: { xs: "16px", md: "24px 40px" },
              }}
            >
              <Schedule
                bundle={appointments}
                dateFilter={dateFilter}
                filterType={filterType}
                setDateFilter={setDateFilter}
                setFilterType={setFilterType}
                loading={loading}
                setRefetch={setRefetch}
                refetch={refetch}
              />
            </Box>
            <Stack width="100%" direction="column" spacing={1}>
              {/* <JourneyCards /> */}
              <Stack
                direction="row"
                justifyContent="flex-start"
                // width="100%"
                //  minWidth="100px"
                overflow={{ xs: "auto", md: "visible" }}
                height={{ xs: "200px", sm: "400px", lg: "100%" }}
                spacing={3}
              >
                <HealthMedicineInformation
                  image={imgMedication}
                  text="Meus Medicamentos"
                  CardType="medication"
                  control={control}
                  data={medicationData}
                  setRefetch={setRefetchMedication}
                  loading={loadingPrescription}
                />
                <HealthMedicineInformation
                  image={imgExam}
                  text="Solicitações de exames"
                  CardType="exam"
                  data={examData}
                  setRefetch={setRefetchMedication}
                  loading={loadingPrescription}
                />
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
      {medicationScreen && (
        <AuxiliarControlDosage
          title="Meus medicamentos"
          auxText="Gerencie e selecione os medicamentos que você esta usando."
          open={medicationScreen}
          data={medicationData}
          onClose={() => setMedicationScreen(false)}
          setRefetch={setRefetchMedication}
          loading={loadingPrescription}
        />
      )}
      {solicitationScreen && (
        <AuxiliarControlDosage
          title="Solicitações de exames"
          auxText="Gerencie e realize aqui o upload dos resultados dos exames solicitados. "
          open={solicitationScreen}
          data={examData}
          onClose={() => setSolicitationScreen(false)}
          setRefetch={setRefetchMedication}
        />
      )}
    </Stack>
  );
}
