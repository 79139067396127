import { Controller } from "react-hook-form";
import { FormInputProps } from "../types";
import { Autocomplete, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

interface IProps {
  selectOptions: { label: string; value: any }[];
  defaultValue?: any;
  loading?: boolean;
  variant?: "filled" | "standard";
}

type TypeMergedInterfaces = FormInputProps & IProps;

export function FormMultipleAutoComplete({
  name,
  control,
  label,
  required,
  selectOptions,
  defaultValue,
  loading,
  variant,
}: TypeMergedInterfaces) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: required,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Autocomplete
            sx={{
              "& .MuiFilledInput-root": { backgroundColor: "neutral100.main" },
            }}
            loading={loading}
            multiple
            disabled={loading}
            options={selectOptions}
            value={value || []}
            onChange={(_, newValue) => {
              onChange([...newValue]);
            }}
            getOptionLabel={(option) => option.label || undefined}
            disableCloseOnSelect
            fullWidth
            isOptionEqualToValue={(option, value) =>
              option?.value === value.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant={variant || "outlined"}
                label={label}
                error={error !== undefined}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        );
      }}
    />
  );
}
