import { Button, Grid, Stack } from "@mui/material";
import { ReadOnlyField } from "../../../medicaldocumentation/components/ReadOnlyField";

import { useFormatter } from "../../../../utils/useFormatter";
import { useDatetime } from "../../../../utils/useDatetime";
import { History } from "@mui/icons-material";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";

export function Header() {
  const { formatNameDisplay, formatGender, formatAge } = useFormatter;
  const { formatDate } = useDatetime;

  const { setOpenSummary, patient } = useMedicalCareContext();
  const handleClickSummary = () => {
    setOpenSummary(true);
  };

  const auxAddress = patient?.address?.[0];

  const city = auxAddress?.city || "";
  const addressState = auxAddress?.state || "";
  const postalCode = auxAddress?.postalCode || "";
  const line = auxAddress?.line?.join(", ") || "";

  const address =
    [line, city, addressState, postalCode]
      ?.filter((el: any) => el)
      ?.join(" , ") || "-";

  const data = [
    {
      label: "Nome do paciente",
      description: formatNameDisplay(patient?.name?.[0]?.text || "-"),
    },
    {
      label: "Sexo de nascimento",
      description: formatGender(patient?.gender) || "-",
    },
    {
      label: "Idade",
      description: patient?.birthDate
        ? `${formatAge(`${patient?.birthDate}`)} anos`
        : "-",
    },
    {
      label: "Data de nascimento",
      description: patient?.birthDate
        ? formatDate(`${patient?.birthDate}`)
        : "-",
    },
    {
      label: "Endereço",
      description: address || "-",
    },
    {
      label: "Raça/Etnia",
      description:
        patient?.extension?.find((ext: any) =>
          ext.url.includes("BRRacaCorEtnia")
        )?.valueCodeableConcept?.coding?.[0]?.display || "-",
    },
  ];

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        width: "100%",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Grid
        alignSelf="center"
        container
        direction="row"
        alignItems="center"
        width="fit-content"
        gap={3}
      >
        {data?.map((el) => (
          <Grid key={`patient-information-${el.label}`}>
            <ReadOnlyField
              title={el.label}
              description={el.description}
              icon={false}
            />
          </Grid>
        ))}
      </Grid>

      <Button
        sx={{
          width: "fit-content",
          textTransform: "none",
          position: "absolute",
          right: 0,
        }}
        startIcon={<History />}
        onClick={handleClickSummary}
      >
        Sumário de saúde
      </Button>
    </Stack>
  );
}
