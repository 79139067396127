import React from "react";

import logo from "../../assets/fluxmed-logo-horizontal-white.png";
import { Stack } from "@mui/material";

export function HeaderLogo() {
  return (
    <Stack width="100%" maxWidth="151px" position="relative" height="36px">
      <img src={logo} alt="Logo fluxmed" />
    </Stack>
  );
}
