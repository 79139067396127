import dayjs from "dayjs";
import { DocumentReference } from "fhir/r5";
import { GenericTable } from "../../../../../../components/GenericTable/GenericTable";
import { ServiceRequestActions } from "../../ServiceRequest/ServiceRequestAction";

export function Solicitations({
  data,
  resultsData,
  handleOpen,
  setBinaryUrl,
  sign,
}: any) {
  const documentReference = data
    ?.filter((el: any) => el?.resource?.resourceType === "DocumentReference")
    ?.filter((e: any) => {
      return sign
        ? e?.resource?.securityLabel?.[0]?.coding?.[0]?.code === "ProofOfOrigin"
        : e?.resource?.securityLabel?.[0]?.coding?.[0]?.code !==
            "ProofOfOrigin";
    });

  const serviceRequest = data?.filter(
    (el: any) => el?.resource?.resourceType === "ServiceRequest"
  );

  const columns = [
    {
      column: "Descrição",
      rowName: "description",
    },
    { column: "Data", rowName: "date" },
    { column: "Categoria", rowName: "category" },
    { column: "Profissional solicitante", rowName: "practitioner" },
  ];

  const rows =
    documentReference
      ?.filter(
        (filterEl: any) =>
          filterEl?.resource?.category?.[0]?.coding?.[0]?.display ===
          "Solicitação"
      )
      ?.map((el: fhir5.BundleEntry<DocumentReference | any>, index: number) => {
        const auxPractitioner = el?.resource?.contained.find(
          (contained: any) => contained?.resourceType === "Practitioner"
        );
        const auxSpecialty =
          el?.resource?.practiceSetting?.coding?.[0]?.display;

        const auxDescription = serviceRequest?.filter((request: any) => {
          const findRef = el?.resource?.basedOn?.find((basedOn: any) =>
            basedOn?.reference.includes(request.resource.id)
          );
          return findRef;
        });

        const auxResults = resultsData?.find((result: any) => {
          const findRef = auxDescription?.find(
            (service: any) =>
              service?.resource?.id ===
              result?.resource?.basedOn
                ?.find((bas: any) => bas.reference.includes("ServiceRequest"))
                ?.reference?.split("/")?.[1]
          );
          return findRef;
        });

        const resultUrl =
          auxResults?.resource?.content?.[0]?.attachment?.url || "";

        const auxType = el?.resource?.type?.coding?.[0]?.display || "-";

        return {
          id: index,
          type: auxType,
          description:
            auxDescription?.map((serviceRequest: any) => (
              <p>
                {serviceRequest?.resource?.code?.concept?.coding?.[0]?.display}
              </p>
            )) || "",
          date: dayjs(el?.resource?.date).format("DD/MM/YYYY") || "-",
          specialty: auxSpecialty || "-",

          practitioner:
            auxPractitioner?.name?.[0]?.given?.[0] ||
            auxPractitioner?.identifier?.[0]?.value,
          binaryUrl: el?.resource?.content?.[0]?.attachment?.url || "",
          auxResults,
          resultUrl,
          fullResource: el?.resource,
        };
      }) || [];
  return (
    <GenericTable
      tableColumns={columns}
      tableRows={rows}
      actions={(rowData: any) => (
        <ServiceRequestActions
          row={rowData}
          handleOpen={handleOpen}
          setBinaryUrl={setBinaryUrl}
        />
      )}
    />
  );
}
