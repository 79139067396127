import { Stack } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../common";

interface IProps {
  onGoBackButtonClick: Function;
  onNextButtonClick: Function;
  loading?: boolean;
  goBackButtonText?: string;
  nextButtonText?: string;
  disabled?: boolean;
  maxWidth?: boolean;
  direction?: "row" | "row-reverse" | "column" | "column-reverse";
}

/**
 * Pair of buttons named "Avançar" and "Voltar".
 *
 * On medium size screen, they are organized as row. On smallers sizes, they are organized as column.
 */

export function FormButtonGroup({
  onGoBackButtonClick,
  onNextButtonClick,
  loading = false,
  goBackButtonText,
  nextButtonText,
  maxWidth,
  disabled = false,
  direction,
}: IProps) {
  return (
    <Stack
      width="100%"
      direction={direction ? direction : { xs: "column", md: "row-reverse" }}
      spacing={{ xs: 2, lg: 4 }}
      justifyContent="space-between"
    >
      <PrimaryButton
        width={maxWidth ? "220px" : "100% "}
        height="50px"
        loading={loading}
        onClick={() => onNextButtonClick()}
        disabled={disabled}
      >
        {nextButtonText || "Avançar"}
      </PrimaryButton>

      {onGoBackButtonClick && (
        <SecondaryButton
          width={maxWidth ? "220px" : "100% "}
          height="50px"
          onClick={() => onGoBackButtonClick()}
          id="goBackButton"
        >
          {goBackButtonText || "Voltar"}
        </SecondaryButton>
      )}
    </Stack>
  );
}
