import { Today } from "@mui/icons-material";
import { IFeatures } from "../../utils/interfaces";
import { ScheduleViewer } from "./ScheduleViewer";

import { useSessionContext } from "../../contexts/SessionContext";

export function useScheduleViewer() {
  const { access } = useSessionContext();

  const configs: IFeatures = {
    active: access.type === "patient",
    path: "/schedule",
    icon: Today,
    text: "Agenda",

    sideMenu: true,
    sideMenuIndex: 2,

    component: () => <ScheduleViewer />,
  };

  return configs;
}
