import { endpoints } from "../../configs/Settings";
import { HTTPResponseStatus } from "../../utils/enum";
import { useAuth } from "../../utils/useAuth";
import { useAxios } from "../../utils/useAxios";

interface IProps {
  codeSystem: string;

  filter?: string;
}
export async function GetCodeSystemLookup({ codeSystem, filter }: IProps) {
  let url = ` ${endpoints.TERMINOLOGY}/CodeSystem/$lookup?system=${codeSystem}`;
  if (filter) url = `${url}&code=${filter}`;

  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`GetCodeSystem ${codeSystem}: ${response.status}`);
      }

      return { status: HTTPResponseStatus.Success, data: response.data };
    })
    .catch((error) => {
      console.error("GetCodeSystem ", error);
      // toast.error(`GetCodeSystem Erro: ${error?.response?.status}`);
      // throw new Error();
    });
  if (response?.data) return response?.data;
}
