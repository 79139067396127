import { SubscriptionCard } from "./SubscriptionCard";

interface IPaymentOption {
  reason: string;
  price: number;
  period: string;
  freeTrial: {
    frequency: string;
  };
}

interface IProps {
  PaymentOptionsArray: IPaymentOption[];
  setPaymentLink: (link: string) => void;
  handleNext: () => void;
}

export function SubscriptionContent({
  PaymentOptionsArray,
  setPaymentLink,
  handleNext,
}: IProps) {
  return (
    <>
      {PaymentOptionsArray && PaymentOptionsArray.length > 0 ? (
        PaymentOptionsArray.map((el, index) => (
          <SubscriptionCard
            title={el.reason}
            textContent="Assine agora por apenas"
            price={el.price}
            period={el.period}
            freeTrial={el.freeTrial.frequency}
            key={index}
            setPaymentLink={setPaymentLink}
            handleNext={handleNext}
          />
        ))
      ) : (
        <p>Nenhuma opção de pagamento disponível.</p>
      )}
    </>
  );
}
