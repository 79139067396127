import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

interface IProps {
  id?: string;
  practitioner?: string;
  organization?: string;
  includeOrganization?: boolean;
  includePractitioner?: boolean;
  specialty?: string;
  count?: string;
}

export async function GetPractitionerRole(
  {
    id,
    practitioner,
    organization,
    specialty,
    count,
    includeOrganization,
    includePractitioner,
  }: IProps,
  p0?: any
) {
  let url = `${endpoints.ADMFHIR}/PractitionerRole`;
  if (id) url = `${url}/${id}`;
  if (practitioner) url = `${url}?practitioner=${practitioner}`;
  if (organization)
    url = `${url}?organization=${organization}&_include=PractitionerRole:practitioner`;
  if (includeOrganization)
    url = `${url}&_include=PractitionerRole:organization`;
  if (includePractitioner)
    url = `${url}?_include=PractitionerRole:practitioner`;
  if (specialty) url = `${url}?specialty:code=${specialty}`;
  if (count) url = `${url}?_count=${count}`;

  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`GetPractitionerRole: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetPractitionerRole ", error);
      // toast.error(`GetPractitionerRole Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
