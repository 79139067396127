import { GenericTimeline } from "../GenericTimeline";
import { Stack } from "@mui/material";
import { useState } from "react";

import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import dayjs from "dayjs";
import { ProblemasAccordion } from "./ProblemasAccordion";

// import { AnamneseHistoryAccordion } from "./AnamneseHistoryAccordion";

const style: any = {
  Concluído: "#007C6D",
  Parcialmente: "#FBBB3C",
  Falhou: "#EF4352",
};

export function ProblemasHistory() {
  const { response } = useQuestionnaireContext();

  const responseEntries = response?.entry?.map((el: any) => el?.resource);

  const problemasReponses = responseEntries?.map((el: any) => {
    const awnserDate = el?.authored;
    const filterItens = el?.item
      ?.filter((e: any) => e?.text === "Lista de Problemas")?.[0]
      ?.item?.filter((subItem: any) => {
        const verifyDuplicates = el?.item
          ?.filter((e: any) => e?.text === "Condutas")?.[0]
          ?.item?.some((e: any) => {
            return (
              e?.item[0].answer[0].valueString === subItem?.item?.[0]?.answer &&
              e.item?.[1]?.answer?.[0]?.valueCoding
            );
          });
        return (
          subItem?.item?.[1]?.answer?.[0]?.valueCoding &&
          subItem?.item?.[0]?.answer &&
          !verifyDuplicates
        );
      });
    const displayReferences = el?.item?.filter(
      (e: any) => e?.linkId === "displayReferences"
    )?.[0]?.item;
    const displayInfo = {
      healthcare: displayReferences?.[0]?.answer?.[0]?.valueString || "",
      practitioner: displayReferences?.[1]?.answer?.[0].valueString || "",
    };
    return { awnserDate, filterItens, displayInfo };
  });

  const verifyResponse = problemasReponses?.filter(
    (el: any) => el.filterItens?.length
  );

  return (
    <Stack spacing={1}>
      <GenericTimeline
        data={verifyResponse}
        dotColor={style}
        component={(data: any, index: number) => (
          <ProblemasAccordion style={style} data={data} index={index} />
        )}
      />
    </Stack>
  );
}
