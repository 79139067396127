import { Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { ReadOnlyField } from "../../medicaldocumentation/components/ReadOnlyField";
import { useEffect, useState } from "react";

import { GetPatient } from "../../../services/fhir";
import { useDatetime } from "../../../utils/useDatetime";
import { useFormatter } from "../../../utils/useFormatter";
import { WhatsApp } from "@mui/icons-material";
import { useSessionContext } from "../../../contexts/SessionContext";

export function PatientData({ patientId }: any) {
  const { access } = useSessionContext();
  const [patientData, setPatientData] = useState<fhir5.Patient>();

  const { formatDate } = useDatetime;
  const { formatNameDisplay, formatGender, formatAge } = useFormatter;

  const auxAddress = patientData?.address?.[0];

  const contact = patientData?.telecom;
  const phone = contact?.find((e: any) => e.system === "phone")?.value;

  const city = auxAddress?.city || "";
  const addressState = auxAddress?.state || "";
  const postalCode = auxAddress?.postalCode || "";
  const line = auxAddress?.line?.join(", ") || "";

  const address =
    [line, city, addressState, postalCode]
      ?.filter((el: any) => el)
      ?.join(" , ") || "-";

  const auxData = [
    {
      label: "Nome do paciente",
      description: formatNameDisplay(patientData?.name?.[0]?.text || ""),
    },
    {
      label: "Sexo de nascimento",
      description: formatGender(patientData?.gender) || "-",
    },
    {
      label: "Idade",
      description: patientData?.birthDate
        ? `${formatAge(`${patientData?.birthDate}`)} anos`
        : "-",
    },
    {
      label: "Telefone",
      description: phone ? (
        <>
          <Stack
            height="22px"
            direction="row"
            alignItems="center"
            justifyContent="left"
            alignContent="center"
          >
            <Typography>
              {useFormatter.formatPhoneNumber(phone || "")}
            </Typography>

            {access?.type === "professional" ? (
              <Tooltip title={useFormatter.formatPhoneNumber(phone || "")}>
                <IconButton
                  color="primary"
                  onClick={() =>
                    window.open(
                      `https://web.whatsapp.com/send?phone=+55${phone}`,
                      "_blank"
                    )
                  }
                >
                  <WhatsApp />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </Stack>
        </>
      ) : (
        "-"
      ),
    },
    {
      label: "Data de nascimento",
      description: patientData?.birthDate
        ? formatDate(`${patientData?.birthDate}`)
        : "-",
    },

    {
      label: "Endereço",
      description: address || "-",
    },
    {
      label: "Raça/Etnia",
      description:
        patientData?.extension?.find((ext: any) =>
          ext.url.includes("BRRacaCorEtnia")
        )?.valueCodeableConcept?.coding?.[0]?.display || "-",
    },
  ];

  useEffect(() => {
    async function fetchPatient() {
      const mpiResponse = await GetPatient({ identifier: patientId });
      setPatientData(mpiResponse?.data);
    }

    if (patientId) fetchPatient();
  }, [patientId]);
  return (
    <>
      {auxData?.map((el: any) => (
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          xs={6}
          key={`patient-information-${el.label}`}
        >
          <ReadOnlyField
            title={el.label}
            description={el.description}
            icon={false}
          />
        </Grid>
      ))}
    </>
  );
}
