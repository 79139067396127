import { endpoints } from "../../../configs/Settings";
import { useAxios } from "../../../utils/useAxios";
import { HTTPResponseStatus } from "../../../utils/enum";

import { useAuth } from "../../../utils/useAuth";
import { toast } from "react-toastify";

export async function DeletePracititionerRole(id: string) {
  const url = `${endpoints.ADMFHIR}/PractitionerRole/${id}`;

  const bearerToken = await useAuth.getUserToken();

  const headers = {
    Accept: "*/*",
    Authorization: bearerToken,
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .delete(url, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`DeletePractitionerRole: ${response.status}`);
      }
      return { status: HTTPResponseStatus.Success, data: response.data };
    })
    .catch((error) => {
      console.error("DeletePracititionerRole ", error);
      // toast.error(`DeletePracititionerRole Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
