import { useState, SetStateAction, Dispatch, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { DialogContent, DialogTitle, Stack } from "@mui/material";

import { CommonDialog } from "../../../components/common/CommonDialog";
import { FormButtonGroup } from "../../../components/form";

import { useSessionContext } from "../../../contexts/SessionContext";
import { HTTPResponseStatus } from "../../../utils/enum";
import { GetValueSet } from "../../../services/terminology";
import { ISpecialty } from "../../professionalregister/Types";
import ProfessionalForm from "../../professionalregister/components/ProfessionalForm";
import {
  GetPractitionerRole,
  UpdatePractitionerRole,
} from "../../../services/fhir";
import { oids } from "../../../configs/Settings";
import { isMobile } from "react-device-detect";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";

interface IFormInput {
  crm: string;
  uf: any;
  speciality: any;
}

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  seletedRole: any;
  setSelectedRole: any;
}

export default function EditPractitionerRoleDialog({
  isOpen,
  setIsOpen,
  seletedRole,
  setSelectedRole,
}: IProps) {
  const { user, fetchUserRoles } = useSessionContext();
  const { refetchPractitionerRole } = usePractitionerRoleContext();
  const [loading, setLoading] = useState(false);

  const [specialties, setSpecialties] = useState([
    { value: "", label: "Carregando" },
  ]);

  const crm = {
    value: seletedRole?.crm?.split("/")[1].slice(2),
    uf: seletedRole?.crm?.split("/")[1].slice(0, 2),
  };

  const defaultValues = {
    crm: crm.value,
    uf: { label: crm.uf, value: crm.uf },
    speciality: seletedRole?.specialty?.map((el: any) => {
      return {
        value: el?.coding[0]?.code,
        label: el?.coding[0]?.display,
      };
    }),
  };

  const methods = useForm<IFormInput>({ defaultValues });

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
    reset,
  } = methods;

  function handleClose() {
    setIsOpen(false);
    setSelectedRole();
    reset(defaultValues);
  }

  const onSubmit = async (data: IFormInput) => {
    setLoading(true);

    try {
      const practitionerRole = await GetPractitionerRole({
        id: `${seletedRole?.id.split("-")[0]}-${oids.cpf}-${user.username}`,
      });

      await UpdatePractitionerRole(practitionerRole, {
        crm: data?.crm,
        uf: data?.uf,
        speciality: data?.speciality,
      });
      await fetchUserRoles();
      handleClose();
      toast.success("Registro profissional editado com sucesso!");
      refetchPractitionerRole();
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function asyncCall() {
      setLoading(true);
      const EspecialidadeMedicaValueSet = await GetValueSet({
        valueset: "EspecialidadeMedica",
      }).then((response) => {
        if (response?.status === HTTPResponseStatus.Success) {
          return response.data.expansion.contains;
        }
        return [];
      });

      setSpecialties(
        EspecialidadeMedicaValueSet.map((item: ISpecialty) => ({
          value: item?.code,
          label: item?.display,
        }))
      );
      setLoading(false);
    }
    asyncCall();
  }, []);

  return (
    <CommonDialog
      handleClose={handleClose}
      isOpen={isOpen}
      mobileView={isMobile}
    >
      <DialogTitle textAlign="center">
        Editar informações profissionais
      </DialogTitle>
      <DialogContent>
        <ProfessionalForm
          edit
          onNextButtonClick={handleSubmit(() => {})}
          control={control}
          specialties={specialties}
          loading={loading}
        />

        <Stack
          minWidth="100%"
          mt={3}
          direction="row"
          justifyContent={"space-between"}
          spacing={2}
        >
          <FormButtonGroup
            onGoBackButtonClick={handleClose}
            onNextButtonClick={handleSubmit(onSubmit)}
            goBackButtonText="Cancelar"
            nextButtonText="Salvar"
            loading={loading}
            disabled={!isDirty || !isValid}
          />
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
