import { IconButton, TableCell, Tooltip } from "@mui/material";
import { Description, FileDownload } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { GetBinary } from "../../../../../services/fhir/patientsummary/Binary/GetBinary";

export function PrescriptionsActions({ row, handleOpen, setBinaryUrl }: any) {
  const [base64, setBase64] = useState<any>();

  function downloadBase64File() {
    const linkSource = `data:${base64?.contentType};base64,${base64?.data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = row?.description;
    downloadLink.click();
  }

  useEffect(() => {
    async function getDocumentPdf() {
      const response = await GetBinary({ urlBinary: row?.binaryUrl });
      setBase64(response);
    }
    if (row?.binaryUrl) {
      getDocumentPdf();
    }
  }, [row]);
  return (
    <TableCell align="center">
      {row.binaryUrl && (
        <>
          <Tooltip title="Visualizar receita">
            <IconButton
              onClick={() => {
                setBinaryUrl(row.binaryUrl);
                handleOpen();
              }}
            >
              <Description />
            </IconButton>
          </Tooltip>
          <Tooltip title="Baixar">
            <IconButton
              onClick={() => {
                downloadBase64File();
              }}
            >
              <FileDownload />
            </IconButton>
          </Tooltip>
        </>
      )}
    </TableCell>
  );
}
