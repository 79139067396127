import { Avatar, IconButton, Stack } from "@mui/material";
import { useSessionContext } from "../../../contexts/SessionContext";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";
import { useFormatter } from "../../../utils/useFormatter";

export function PractitionerAvatar({ filter }: any) {
  const { access } = useSessionContext();
  const { practitionerRole } = usePractitionerRoleContext();

  const displayAvatar = practitionerRole?.filter((el: any) => {
    const reference = filter?.find(
      (element: any) =>
        element.reference === el.resource?.practitioner?.reference &&
        el.resource?.practitioner?.display &&
        el.resource.id.includes(access?.organizationId)
    );

    return reference;
  });

  const { formatNameFirstInitials } = useFormatter;
  return (
    <Stack direction="row">
      {displayAvatar?.map((el: any, index: number) => {
        return (
          <IconButton
            key={`avatar-${index}`}
            sx={{
              padding: 0,
              height: "fit-content",
              marginLeft: "4px",
            }}
          >
            <Avatar
              sx={{
                width: 32,
                height: 32,
                fontSize: "14px",
                fontWeight: 600,
                backgroundColor: "primary100.main",
                color: "primary1000.main",
              }}
            >
              {formatNameFirstInitials(el?.resource?.practitioner?.display, 2)}
            </Avatar>
          </IconButton>
        );
      })}
    </Stack>
  );
}
