import axios, { AxiosError } from "axios";

interface SubscriptionData {
  email: string;
  reason: string;
  price: number;
  period: string;
  organizationId: string | undefined;
}

interface SubscriptionPlan {
  reason: string;
  auto_recurring: {
    transaction_amount: number;
    free_trial?: {
      frequency: number;
      frequency_type: string;
    };
  };
  external_reference: string;
}

interface ApiResponse {
  results: SubscriptionPlan[];
}

export class SubscriptionService {
  private readonly apiUrl = `${process.env.REACT_APP_ENDPOINT_BILLING}/subscribe`;

  async createSubscription(subscriptionData: SubscriptionData): Promise<string | undefined> {
    try {
      const response = await axios.post<{ paymentLink: string }>(this.apiUrl, subscriptionData);
      return response.data.paymentLink;
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error("Erro ao enviar a solicitação de assinatura:", error.message);
        if (error.response) {
          console.error("Detalhes do erro:", error.response.data);
        }
      } else {
        console.error("Ocorreu um erro desconhecido:", error);
      }
      throw error;
    }
  }

  async getSubscriptionPlans(): Promise<{ reason: string; price: number; period: string; freeTrial?: { frequency: number; frequencyType: string } }[]> {
    try {
      const response = await axios.get<ApiResponse>(`${process.env.REACT_APP_ENDPOINT_BILLING}/subscriptionPlans`);
      const subscriptionPlans = response.data.results;

      const formattedPlans = subscriptionPlans.map((plan) => ({
        reason: plan.reason,
        price: plan.auto_recurring.transaction_amount,
        period: plan.external_reference,
        freeTrial: plan.auto_recurring.free_trial ? {
          frequency: plan.auto_recurring.free_trial.frequency,
          frequencyType: plan.auto_recurring.free_trial.frequency_type
        } : undefined 
      }));

      return formattedPlans;
    } catch (error) {
      console.error('Erro ao obter os planos de assinatura:', error);
      throw error;
    }
  }
}