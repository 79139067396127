import { Stack, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
  FormTextInput,
  FormStack,
  FormButtonGroup,
  FormDateInput,
  FormSelectInput,
} from "../../../components/form";
import { ContentBoxTitle } from "../../../components/common";
import { handleKeyPress } from "../../../utils/useKeyPress";
import { IFormInput } from "../types";
import { ProfileSearch, SignUpValidate } from "../../../services/scim";
import { GetPractitioner } from "../../../services/fhir";
import dayjs from "dayjs";
import { HTTPResponseStatus } from "../../../utils/enum";
import { toast } from "react-toastify";
import { useState } from "react";
import { useRegex } from "../../../utils/useRegex";
import { useFormatter } from "../../../utils/useFormatter";
import { HelpOutline } from "@mui/icons-material";

interface IProps {
  control: any;
  handleSubmit: Function;
  onNextButtonClick: Function;
}

const genderOptions = [
  { display: "Feminino", value: "female" },
  { display: "Masculino", value: "male" },
];

export default function PersonalData({
  control,
  handleSubmit,
  onNextButtonClick,
}: IProps) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data: IFormInput) => {
    setLoading(true);
    try {
      const Scim2UserResponse = await ProfileSearch(
        "application",
        data.identifier?.replace(/[.-]/g, "")
      ).then((response) => response?.Resources);

      if (Scim2UserResponse) {
        toast.error("Usuário já cadastrado na plataforma");
        return;
      }

      const searchPractitioner = await GetPractitioner(
        `${data?.identifier.replace(/[.-]/g, "")}`,
        true
      );

      if (searchPractitioner) {
        const verifyName =
          searchPractitioner?.name[0]?.text
            .toUpperCase()
            .includes(data.firstName.toUpperCase()) ||
          searchPractitioner?.name[0]?.text
            .toUpperCase()
            .includes(data.firstName.toUpperCase());
        const verifyBirthDate = dayjs(searchPractitioner.birthDate).isSame(
          data?.birthdate.toISOString().split("T")[0]
        );
        if (verifyName && verifyBirthDate)
          toast.success("Dados validados na base da receita federal");
      } else {
        const validationSerpro = await SignUpValidate(data);
        if (validationSerpro?.status === HTTPResponseStatus.Conflict) {
          toast.warn(validationSerpro.data);
          navigate("/signin");
          return;
        }
        if (validationSerpro?.status !== HTTPResponseStatus.Created) {
          toast.error(
            validationSerpro?.data ||
              "Dados não encontrados na base da receita federal"
          );
          return;
        }
        toast.success("Dados validados na base da receita federal");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
    onNextButtonClick();
  };

  return (
    <form
      onKeyDown={(event) => handleKeyPress(event, false, onNextButtonClick)}
      style={{ width: "100%" }}
    >
      <FormStack>
        <ContentBoxTitle>Criar uma conta Fluxmed</ContentBoxTitle>
        <Typography
          variant="body1"
          sx={{ color: "neutral600.main", mt: "-10px" }}
        >
          Para começar a sua jornada na plataforma, por favor, preencha os dados
          abaixo.
        </Typography>
        <FormTextInput
          name="firstName"
          control={control}
          label="Nome"
          required={true}
          autoFocus
        />
        <FormTextInput
          name="surname"
          control={control}
          label="Último nome"
          required={true}
        />

        <Stack
          sx={{
            display: "grid",
            gridTemplateColumns: "100% 20px",
          }}
          width="100%"
          gap={2}
        >
          <FormTextInput
            label="CPF"
            name="identifier"
            control={control}
            required={true}
            pattern={useRegex.cpf}
            mask={useFormatter.formatCPF}
            maxLength={14}
          />
          <Tooltip
            title="CPF será validado na base da Receita Federal"
            placement="right"
          >
            <HelpOutline
              fontSize="small"
              id="cpfSupport"
              sx={{ marginTop: 2, color: "neutral600.main" }}
            />
          </Tooltip>
        </Stack>
        <Stack width="100%" direction={{ xs: "column", lg: "row" }} spacing={2}>
          <FormDateInput
            name="birthdate"
            control={control}
            label="Data de nascimento"
            required={true}
          />
          <FormSelectInput
            control={control}
            name="gender"
            label="Sexo de nascimento"
            selectOptions={genderOptions}
            required
          />
        </Stack>

        <FormButtonGroup
          loading={loading}
          onNextButtonClick={handleSubmit(onSubmit)}
          onGoBackButtonClick={() => navigate("/signin")}
        />
      </FormStack>
    </form>
  );
}
