import { IconButton, TableCell, Tooltip } from "@mui/material";
import { Description, PersonAdd } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { useState } from "react";
import { CreateAppointmentDialog } from "../../../../pages/schedulemanager/components/creatappointmentdialog";
import { useFormatter } from "../../../../utils/useFormatter";

interface IProps {
  row: any;
}

export function PatientSearchActions({ row }: IProps) {
  const navigate = useNavigate();
  const [createAppointmentDialogOpen, setCreateAppointmentDialogOpen] =
    useState<boolean>(false);

  return (
    <TableCell align="center">
      <Tooltip title="Realizar encaixe">
        <IconButton onClick={() => setCreateAppointmentDialogOpen(true)}>
          <PersonAdd />
        </IconButton>
      </Tooltip>
      {createAppointmentDialogOpen && (
        <CreateAppointmentDialog
          isOpen={createAppointmentDialogOpen}
          setModalOpen={setCreateAppointmentDialogOpen}
          cpfT={useFormatter.formatCPF(row?.identifier)}
          walkin
        />
      )}

      <Tooltip title="Acessar Registros ">
        <IconButton
          onClick={() => {
            if (row.identifier)
              navigate("/summary", {
                state: {
                  patientId: `${row?.identifier}`,
                },
              });
          }}
        >
          <Description />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
}
