import { endpoints } from "../../configs/Settings";
import { useAuth } from "../../utils/useAuth";
import { useAxios } from "../../utils/useAxios";

export async function OAuthApplication() {
  const url = endpoints.token_api;
  const data = "grant_type=client_credentials";
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getBasicToken(),
    "Content-Type": "application/x-www-form-urlencoded",
  };
  const response = await useAxios
    .post(url, data, {
      headers,
      timeout: 8000,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
  if (response) return `Bearer ${response.access_token}`;
}
