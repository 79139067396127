import { Search } from "@mui/icons-material";
import { IFeatures } from "../../utils/interfaces";
import { SchedulingSearch } from "./SchedulingSearch";

import { useSessionContext } from "../../contexts/SessionContext";
import { currentFeatures } from "../../configs/Settings";

export function useSchedulingSearch() {
  const { access } = useSessionContext();

  const configs: IFeatures = {
    active: access.type === "patient" && !currentFeatures.register,
    path: "/schedulingsearch",
    text: "Agendamento",
    icon: Search,

    avatarMenu: true,
    avatarIndex: 1,

    component: () => <SchedulingSearch />,
  };

  return configs;
}
