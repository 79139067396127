import { Button, Grid, Stack, Typography } from "@mui/material";
import {
  CircularLoading,
  LargeContentBox,
  PrimaryButton,
} from "../../components/common";
import { PrivateMainLayout } from "../../components/layout";

import { PatientData } from "./components/PatientData";

import { ArrowBack, PlayArrow } from "@mui/icons-material";
import { PatientSummary } from "./components/PatientSummary";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { BundlePatientSummary } from "../../services/fhir";
import { useClinicalRegisterContext } from "../../contexts/ClinicalRegisterContext";
import { HTTPResponseStatus } from "../../utils/enum";
import { toast } from "react-toastify";
import { useSessionContext } from "../../contexts/SessionContext";

export function EHRRecords() {
  const { id } = useParams();
  const { state } = useLocation();
  const { user } = useSessionContext();
  const { verifyEncounter } = useClinicalRegisterContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [summaryData, setSummaryData] = useState();
  const [refetch, setRefetch] = useState<boolean>(false);

  const verifyRoute = id === "myrecords" ? user?.username : undefined;

  useEffect(() => {
    async function GetBundle() {
      setLoading(true);
      try {
        const bundleResponse = await BundlePatientSummary({
          identifier: state?.patientId || verifyRoute,
          purposeofuse: state?.purpose || "Atendimento",
        });

        if (bundleResponse.status === HTTPResponseStatus.Forbidden) {
          navigate("/");
          toast.warn("Verifique as opções de consentimento");
        }
        setSummaryData(bundleResponse);
      } catch (err) {
        console.log("PatientSummary error:", err);
      } finally {
        setLoading(false);
      }
    }
    if (state?.patientId || verifyRoute) GetBundle();
  }, [refetch, state?.patientId, navigate, user]);

  return (
    <PrivateMainLayout>
      <LargeContentBox>
        <Grid container rowSpacing={3} columnSpacing={6}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h6" fontWeight={600} paddingBottom={3}>
              Sumário de saúde
            </Typography>

            <Grid
              container
              direction="row"
              maxWidth={{
                xs: `${window.innerWidth - 50}px`,
                sm: "fit-content",
              }}
              rowSpacing={3}
              columnSpacing={3}
            >
              <PatientData patientId={state?.patientId || verifyRoute} />

              <Grid item xs={12}>
                {loading ? (
                  <Stack
                    mt={4}
                    sx={{ minHeight: "200px", justifyContent: "center" }}
                  >
                    <CircularLoading />
                  </Stack>
                ) : (
                  <PatientSummary
                    summary={summaryData}
                    setRefetch={setRefetch}
                  />
                )}
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              rowSpacing={3}
              columnSpacing={3}
              paddingTop={4}
              justifyContent="space-between"
            >
              <Grid item>
                <Button
                  onClick={() => navigate(-1)}
                  style={{ whiteSpace: "nowrap" }}
                  sx={{
                    ":active": { whiteSpace: "nowrap" },
                    textTransform: "none",
                  }}
                  startIcon={<ArrowBack />}
                >
                  Voltar
                </Button>
              </Grid>
              {state?.appointment && (
                <Grid item>
                  <PrimaryButton
                    height="42px"
                    width="220px"
                    onClick={async () =>
                      await verifyEncounter(state?.appointment)
                    }
                    startIcon={<PlayArrow />}
                  >
                    Iniciar atendimento
                  </PrimaryButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
