import { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Close } from "@mui/icons-material";

export function QuestionnaireVisualizer({ handleClose, isOpen, data }: any) {
  const [selectedTab, setSelectedTab] = useState(
    data?.resource?.item?.[0]?.text
  );
  const handleChangeTab = (e: React.SyntheticEvent, value: any) =>
    setSelectedTab(value);

  const FirstLevelQuestion = ({ item }: any) => {
    return (
      <Stack spacing={1}>
        <Typography variant="body1" fontWeight={600}>
          {item.text}
        </Typography>
        <Typography variant="body1" fontWeight={400}>
          {item.answer?.[0]?.valueString ||
            item.answer?.[0]?.valueCoding?.display ||
            item.answer?.[0]?.valueDate ||
            "-"}
        </Typography>
      </Stack>
    );
  };

  const QuestionnaireItem = ({ item }: any) => {
    if (item.item) {
      return (
        <Grid container>
          {item?.text !== selectedTab &&
            item?.item?.find((el: any) => el?.answer || el?.item) && (
              <Grid item xs={12} mb={2}>
                <Typography variant="subtitle1" fontWeight={600}>
                  {item.text}
                </Typography>
              </Grid>
            )}

          {/* Chamada recursiva para renderizar os sub-itens */}
          {item?.item?.map((subItem: any, index: number) => (
            <QuestionnaireItem key={index} item={subItem} />
          ))}
        </Grid>
      );
    } else {
      return (
        <Grid item xs={4} sx={{ mb: 2 }}>
          <FirstLevelQuestion item={item} />{" "}
        </Grid>
      );
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "10px",
          padding: 2,
          maxWidth: "1200px",
        },
      }}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      open={isOpen}
    >
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", right: 5, top: 5 }}
      >
        <Close />
      </IconButton>
      <DialogTitle textAlign="left">Atendimento</DialogTitle>
      <DialogContent>
        <TabContext value={selectedTab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TabList onChange={handleChangeTab}>
              {data?.resource?.item?.map((item: any, index: number) => (
                <Tab
                  key={index}
                  value={item.text}
                  label={item.text}
                  sx={{ textTransform: "none" }}
                />
              ))}
            </TabList>
          </Box>
          {data?.resource?.item?.map((item: any, index: number) => (
            <TabPanel key={index} value={item.text} sx={{ paddingX: 0 }}>
              {/* Renderiza os itens de acordo com a estrutura */}

              <QuestionnaireItem item={item} />
            </TabPanel>
          ))}
        </TabContext>
      </DialogContent>
    </Dialog>
  );
}
