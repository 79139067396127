import { Stack } from "@mui/material";
import {
  CircularLoading,
  ContentBoxTitle,
  PrimaryButton,
} from "../../../components/common";
import { useState, useEffect } from "react";
import { SubscriptionContent } from "./SubscriptionContent";
import { SubscriptionService } from "../../../services/subscription/SubscriptionService";
import { endpoints } from "../../../configs/Settings";

interface IProps {
  setPaymentLink: (link: string) => void;
  handleNext: () => void;
}
const voltarPortal = () => {
  window.location.href = `${endpoints.XDSB_domain}/portal/`;
};

export function SubscriptionPlan({ setPaymentLink, handleNext }: IProps) {
  const [paymentOptions, setPaymentOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const subscriptionService = new SubscriptionService();

  const fetchAllSubscriptionPlans = async () => {
    const allPlans = await subscriptionService.getSubscriptionPlans();
    return allPlans ? allPlans : [];
  };

  useEffect(() => {
    setLoading(true);
    try {
      const fetchPaymentOptions = async () => {
        const options = await fetchAllSubscriptionPlans();
        setLoading(false);
        setPaymentOptions(options);
      };

      fetchPaymentOptions();
    } catch (err) {
      console.log("err", err);
    }
  }, []);

  return (
    <Stack alignItems="center" spacing={10}>
      <Stack alignItems="center" spacing={2}>
        <img
          src="./assets/images/fluxmed-logo-horizontal.png"
          alt="Logo fluxmed"
          width={185}
          height={44}
        />
        <ContentBoxTitle>Faça a sua assinatura</ContentBoxTitle>
      </Stack>
      <Stack spacing={6} justifyContent="center" alignItems="center">
        {loading ? (
          <CircularLoading />
        ) : (
          <Stack direction="row" spacing={3}>
            <SubscriptionContent
              PaymentOptionsArray={paymentOptions}
              setPaymentLink={setPaymentLink}
              handleNext={handleNext}
            />
          </Stack>
        )}
      </Stack>
      <Stack>
        <PrimaryButton width="200px" height="40px" onClick={voltarPortal}>
          Acessar o Fluxmed
        </PrimaryButton>
      </Stack>
    </Stack>
  );
}
