import { Grid, Stack, Typography } from "@mui/material";

import { ContentBoxTitle, DisplayTextField } from "../../../components/common";
import { oids } from "../../../configs/Settings";
import { useSessionContext } from "../../../contexts/SessionContext";
import { useFhirData } from "../../../utils/useFhirData";
import { useFormatter } from "../../../utils/useFormatter";
import { FormButtonGroup, FormStack } from "../../../components/form";
import { handleKeyPress } from "../../../utils/useKeyPress";

interface IProps {
  organization: any;
  onBackButtonClick: any;
  onNextButtonClick: Function;
}

export function ReviewSubscription({
  organization,
  onBackButtonClick,
  onNextButtonClick,
}: IProps) {
  const { user } = useSessionContext();
  const { findIdentifier, findType } = useFhirData;

  return (
    <form
      onKeyDown={(event) => handleKeyPress(event, false, onNextButtonClick)}
      style={{ height: "50%", width: "50%" }}
    >
      <FormStack>
        <img
          src="./assets/images/fluxmed-logo-horizontal.png"
          alt="Logo fluxmed"
          width={185}
          height={44}
        />
        <ContentBoxTitle>Registrar clínica</ContentBoxTitle>
        <Typography variant="body1" textAlign="center">
          Revise as informações da sua organização de saúde
        </Typography>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={6}>
            <DisplayTextField
              label="CNPJ"
              mask={useFormatter.formatCNPJ}
              value={findIdentifier(
                organization?.identifier,
                `urn:oid:${oids.cnpj}`
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DisplayTextField
              label="CPF"
              mask={useFormatter.formatCPF}
              value={user.username}
            />
          </Grid>
          <Grid item xs={12}>
            <DisplayTextField label="Razão Social" value={organization?.name} />
          </Grid>
          <Grid item xs={12}>
            <DisplayTextField
              label="Natureza Jurídica"
              value={findType(organization?.type, "naturezaJuridica")?.display}
            />
          </Grid>
          <Grid item xs={12}>
            <DisplayTextField
              label="CNAE Principal"
              value={`${
                findType(organization?.type, "cnaePrincipal")?.value
              } - ${findType(organization?.type, "cnaePrincipal")?.display}`}
            />
          </Grid>
        </Grid>
        <Stack direction="row" width="100%" mt={3} spacing={3}>
          <FormButtonGroup
            onGoBackButtonClick={() => onBackButtonClick()}
            onNextButtonClick={() => onNextButtonClick()}
            goBackButtonText="Voltar"
            nextButtonText="Próximo"
          />
        </Stack>
      </FormStack>
    </form>
  );
}
