import { Stack } from "@mui/material";
import { Header } from "../Header/Header";
import { RegisterArea } from "../RegisterArea/RegisterArea";
import { Footer } from "../Footer/Footer";
import { KeyPressShortCutAreas } from "../KeyPressShortcuts/KeyPressShortcut";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import { CircularLoading } from "../../../../components/common";
import { StepArea } from "../StepArea/StepArea";

export function ClinicalVisualization() {
  const { loadingAppointment } = useMedicalCareContext();
  const { loadingQuestionnaire, loadingResponse } = useQuestionnaireContext();

  return !loadingAppointment &&

    !loadingQuestionnaire &&
    !loadingResponse ? (
    <>
      <Stack width="100%" spacing={3}>
        <>
          <StepArea />
          <Header />

          <RegisterArea />
          <Footer />
        </>
      </Stack>
      <KeyPressShortCutAreas />
    </>
  ) : (
    <CircularLoading />
  );
}
