import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import { auxiliarData } from "../AuxiliarData";

export async function PostPrescriptionProtocol(objData: any, references: any) {
  let url = `${endpoints.ADMFHIR}/QuestionnaireResponse`;

  const data = auxiliarData.ProtocolResponse(objData, references);
  const headers = {
    Accept: "application/fhir+json",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };

  const response = await useAxios
    .post(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (
        response.status !== HTTPResponseStatus.Created &&
        response.status !== HTTPResponseStatus.Success
      ) {
        throw new Error(`PostQuestionnaireResponse: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("PostPrescriptionProtocol ", error);
      // toast.error(`PostPrescriptionProtocol Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
