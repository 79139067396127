import { toast } from "react-toastify";
import { endpoints, oids } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

export async function PutOrganization(fhirOrganization: any) {
  const cnpj = fhirOrganization.identifier[0]?.value;

  const url = `${endpoints.ADMFHIR}/Organization/${oids.cnpj}-${cnpj}`;
  const data = fhirOrganization;
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .put(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (
        response.status !== HTTPResponseStatus.Success &&
        response.status !== HTTPResponseStatus.Created
      ) {
        throw new Error(`PutOrganization: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("PutOrganization ", error);
      // toast.error(`PutOrganization Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
