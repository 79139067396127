import { toast } from "react-toastify";
import { endpoints } from "../../../../configs/Settings";
import { HTTPResponseStatus } from "../../../../utils/enum";

import { useAuth } from "../../../../utils/useAuth";
import { useAxios } from "../../../../utils/useAxios";
import { auxiliarData } from "../../AuxiliarData";
import { PostDocumentReference } from "../documentreference/PostDocumentReference";

export async function PostMedicationRequest(
  objData: any,
  encounter?: any,
  source?: any
) {
  let url = `${endpoints.EHRPOSTER}/`;

  const data = auxiliarData.MedicationRequest(objData, encounter, source);
  const headers = {
    Accept: "application/fhir+json",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };

  const response = await useAxios
    .post(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (
        response.status !== HTTPResponseStatus.Created &&
        response.status !== HTTPResponseStatus.Success
      ) {
        throw new Error(`PostMedicationRequest: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("PostMedicationRequest ", error);
      // toast.error(`PostMedicationRequest Error: ${error?.response?.status}`);
      throw new Error();
    });

  if (response) return response;
}
