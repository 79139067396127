import dayjs from "dayjs";
import { DocumentReference } from "fhir/r5";
import { GenericTable } from "../../../../../../components/GenericTable/GenericTable";
import { PrescriptionsActions } from "../../Prescriptions/PrescriptionsActions";

export function MedicalNotes({ data, handleOpen, setBinaryUrl, sign }: any) {
  const documentReference = data
    ?.filter((el: any) => el?.resource?.resourceType === "DocumentReference")
    ?.filter((e: any) => {
      return sign
        ? e?.resource?.securityLabel?.[0]?.coding?.[0]?.code === "ProofOfOrigin"
        : e?.resource?.securityLabel?.[0]?.coding?.[0]?.code !==
            "ProofOfOrigin";
    });
  const medicationRequest = data?.filter(
    (el: any) => el?.resource?.resourceType === "MedicationRequest"
  );

  const coloumns = [
    {
      column: "Medicamentos",
      rowName: "description",
    },
    {
      column: "Profissional",
      rowName: "practitioner",
    },
    {
      column: "Especialidade",
      rowName: "specialty",
    },
    {
      column: "Data",
      rowName: "date",
    },
  ];

  const rowData =
    documentReference?.map(
      (el: fhir5.BundleEntry<DocumentReference | any>, index: number) => {
        const auxPractitioner = el?.resource?.contained?.find(
          (contained: any) => contained?.resourceType === "Practitioner"
        );
        const auxSpecialty =
          el?.resource?.practiceSetting?.coding?.[0]?.display;

        const auxDescription = medicationRequest?.filter((request: any) => {
          const findRef = el?.resource?.basedOn?.find((basedOn: any) =>
            basedOn?.reference.includes(request.resource.id)
          );
          return findRef;
        });

        const auxType = el?.resource?.type?.coding?.[0]?.display || "-";

        return {
          id: index,
          type: auxType,
          description:
            auxDescription
              ?.map(
                (medicationRequestPrescription: any) =>
                  medicationRequestPrescription?.resource?.medication?.concept
                    ?.text
              )
              ?.join(" , ") || "",
          date: dayjs(el?.resource?.date).format("DD/MM/YYYY") || "-",
          specialty: auxSpecialty || "-",
          practitioner:
            auxPractitioner?.name?.[0]?.given?.[0] ||
            auxPractitioner?.identifier?.[0]?.value,
          binaryUrl: el?.resource?.content?.[0]?.attachment?.url || "",
          medication: auxDescription,
        };
      }
    ) || [];
  return (
    <GenericTable
      tableColumns={coloumns}
      tableRows={rowData}
      actions={(rowData: any) => (
        <PrescriptionsActions
          row={rowData}
          handleOpen={handleOpen}
          setBinaryUrl={setBinaryUrl}
        />
      )}
    />
  );
}
