import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

export function ExamesHistoryAccordion({ data, index }: any) {
  return (
    <Accordion
      defaultExpanded={index === 0}
      sx={{
        width: "500px",
        boxShadow: "1px 1px 16px 1px rgba(0, 0, 0, 0.1)",
        position: "initial",
        border: "1px solid",
        borderColor: "neutral200.main",
        borderRadius: 1,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Stack direction="row" width="100%" spacing={2}>
          <Chip
            label={data?.displayInfo?.healthcare || "Sem especialidade"}
            size="small"
            sx={{
              color: data?.displayInfo?.healthcare
                ? "neutral700.main"
                : "neutral500.main",

              fontWeight: 500,
              backgroundColor: data?.displayInfo?.healthcare ? "" : "#f1f1f1",
            }}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {data?.filterItens?.item?.map((el: any) => {
          const auxAlterado =
            el?.answer?.[0]?.item?.[0]?.answer?.[0]?.valueString !== "undefined"
              ? true
              : false;

          return (
            <Stack
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              height="50px"
              direction="row"
              sx={{ mt: 1 }}
            >
              <Stack p={1}>
                <Typography fontWeight={500}>{el?.text}</Typography>
                {auxAlterado && (
                  <Typography>
                    {el?.answer?.[0]?.item?.[0]?.answer?.[0]?.valueString}
                  </Typography>
                )}
              </Stack>
              <Stack
                width="100px"
                borderRadius="5px"
                justifyContent="center"
                alignItems="center"
                padding={1}
                sx={{ background: auxAlterado ? "#FEE2A9" : "#E6F7F5" }}
              >
                <Typography fontWeight={500} sx={{ color: "neutral600.main" }}>
                  {auxAlterado ? "Alterado" : "Normal"}{" "}
                </Typography>
              </Stack>
            </Stack>
          );
        })}
        <Divider sx={{ my: 2 }}></Divider>
        <Typography
          color="neutral700.main"
          sx={{ fontWeight: 500, fontStyle: "italic", fontSize: 12 }}
        >
          Dr. {data?.displayInfo?.practitioner}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
