import {
  CircularProgress,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { CommonDialog } from "../../../../components/common/CommonDialog";
import {
  FormAutoComplete,
  FormButtonGroup,
  FormPasswordInput,
} from "../../../../components/form";
import { useForm } from "react-hook-form";
import { useState } from "react";

interface IProps {
  setModalOpen: Function;
  isOpen: boolean;
  handleSignature: Function;
  row?: any;
  loading: boolean;
}

const ProviderOptions: Array<any> = [
  { label: "VidaaS", value: "vidaas" },
  { label: "SafeID", value: "safeid" },
];

export function SignatureDialog({
  setModalOpen,
  isOpen,
  handleSignature,
  row,
  loading,
}: IProps) {
  const { control, handleSubmit } = useForm({});
  const [selectedPassProvider, setSelectedPassProvider] = useState(false);

  const handleProviderChange = (selectedOption: any) => {
    setSelectedPassProvider(selectedOption?.value === "safeid");
  };

  const onSubmit = async (data: any) => {
    if (row?.sign === "Pendente") {
      await handleSignature(data?.provider?.value, data?.password);
      setModalOpen(false);
    }
  };

  return (
    <CommonDialog handleClose={() => setModalOpen(false)} isOpen={isOpen}>
      <DialogTitle textAlign="center">
        <Typography variant="h5" fontWeight={600}>
          Assinatura eletrônica
        </Typography>
        <Typography variant="body1" color="neutral700.main" textAlign="center">
          Selecione o provedor para realizar a assinatura eletrônica.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack margin="16px 0 32px">
          <FormAutoComplete
            label="Provedores disponíveis"
            name="provider"
            control={control}
            selectOptions={ProviderOptions}
            auxOnChange={handleProviderChange}
            defaultValue={ProviderOptions[0]}
          />
          {selectedPassProvider ? (
            <Stack mt={2}>
              <FormPasswordInput
                name="password"
                control={control}
                label="Senha"
                required
              />
            </Stack>
          ) : (
            ""
          )}
        </Stack>
        <FormButtonGroup
          nextButtonText={"Assinar documento"}
          onGoBackButtonClick={() => setModalOpen(false)}
          onNextButtonClick={handleSubmit(onSubmit)}
          loading={loading}
        />
      </DialogContent>
    </CommonDialog>
  );
}
