import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { InputText, InputTextArea } from "../Form";
import { useState } from "react";
import { Controller } from "react-hook-form";

export function ConductsFields({
  name = "",
  index,
  control,
  linkIdConduta,
  linkIdStatus,
  linkIdNote,
}: {
  name: string;
  index: number;
  control: any;
  linkIdConduta: string;
  linkIdStatus: any;
  linkIdNote: any;
}) {
  const [showNote, setShowNote] = useState<boolean>(false);

  const handleRadioChange = () => {
    setShowNote(true);
  };

  return (
    <>
      <Stack key={`_guided_conduct_${index + 1}`} spacing={2}>
        <InputText
          key={`_guided_conduct_${index + 1}`}
          defaultValue={name}
          name={`${linkIdConduta}_guided_conduct_${index + 1}`}
          control={control}
          disabled
          sx={{ mb: 4 }}
        />
        <Controller
          name={`${linkIdStatus?.linkId}_guided_conduct_status_${index + 1}`}
          control={control}
          defaultValue={linkIdStatus?.answer?.[0]?.valueCoding?.id}
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              onChange={(event) => {
                onChange(event.target.value);
                handleRadioChange();
              }}
              value={value}
            >
              <Stack justifyContent="center" direction="row">
                <FormControlLabel
                  value="71e7e351-3af3-44c1-f749-c26ffd4d2cc0"
                  control={<Radio />}
                  label="Concluído"
                />
                <FormControlLabel
                  value="69c43257-9e56-4a65-9aa2-e207fcd52885"
                  control={<Radio />}
                  label="Parcialmente"
                />
                <FormControlLabel
                  value="908d3dd9-8690-4da8-a1ea-e2d7892618a1"
                  control={<Radio />}
                  label="Falhou"
                />
              </Stack>
            </RadioGroup>
          )}
        />

        <div
          style={{
            display:
              linkIdNote?.answer?.[0]?.valueString || showNote
                ? "block"
                : "none",
          }}
        >
          <InputTextArea
            key={`nota${index + 1}`}
            label={"Nota"}
            defaultValue={linkIdNote?.answer?.[0]?.valueString}
            name={`${linkIdNote?.linkId}_guided_conduct_note_${index + 1}`}
            control={control}
            sx={{ mb: 4 }}
          />
        </div>
      </Stack>
    </>
  );
}

export function RenderCondutasPassadas({
  control,
  itemsResponse,
}: {
  control?: any;
  itemsResponse?: any;
}) {
  return (
    <Stack sx={{ marginBottom: 2 }}>
      <Accordion
        sx={{
          border: "1px solid #ccc",
          boxShadow: "2px 2px 16px 2px rgba(0, 0, 0, 0.2)",
          position: "initial",
        }}
      >
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant={"h6"}>Condutas Orientadas</Typography>
        </AccordionSummary>

        <AccordionDetails>
          {itemsResponse?.[0]?.item
            ?.filter(
              (cond: any) =>
                cond?.item?.find((conduta: any) => conduta?.text === "Conduta")
                  ?.answer?.[0]?.valueString &&
                !cond?.item?.find((conduta: any) => conduta?.text === "Status")
                  ?.answer?.[0]?.valueCoding?.id
            )
            ?.map((condutas: any, index: any) => {
              const name =
                condutas?.item?.find(
                  (conduta: any) => conduta.text === "Conduta"
                )?.answer?.[0]?.valueString || "";
              const linkIdConduta =
                condutas?.item?.find(
                  (conduta: any) => conduta.text === "Conduta"
                )?.linkId || "null";
              const linkIdStatus =
                condutas?.item?.find(
                  (conduta: any) => conduta.text === "Status"
                ) || "null";
              const linkIdNote =
                condutas?.item?.find(
                  (conduta: any) => conduta.text === "Nota"
                ) || "null";

              return (
                <>
                  <ConductsFields
                    name={name}
                    index={index}
                    control={control}
                    linkIdConduta={linkIdConduta}
                    linkIdStatus={linkIdStatus}
                    linkIdNote={linkIdNote}
                  />
                  <Divider sx={{ my: 4 }} />
                </>
              );
            })}
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
