import {
  Button,
  Dialog,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { FormSelectInput } from "../../../../components/form";
import { useForm, useWatch } from "react-hook-form";
import { PrimaryButton } from "../../../../components/common";
import { Add, Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useOrganizationContext } from "../../../../contexts/OrganizationContext";
import { GetPrescriptionProtocol } from "../../../../services/fhir";
import { endpoints } from "../../../../configs/Settings";

export function SelectProtocolDialog({ handleClose, isOpen, addItem }: any) {
  const { control, handleSubmit } = useForm();
  const { organization } = useOrganizationContext();

  const [protocol, setProtocol] = useState<any>();

  const tags = [
    {
      name: "VERMELHA",
      value: "CE",
      color: "#db3f30",
      display: "Controle especial - Tarja vermelha",
    },
    {
      name: "PRETA",
      value: "CE",
      color: "white",
      background: "black",
      display: "Controle especial - Tarja preta",
    },
    {
      name: "AMARELA",
      value: "AM",
      color: "#FEE2A9",
      display: "Receita amarela",
    },
    {
      name: "AZUL",
      value: "AZ",
      color: "#A4CBE8",
      display: "Receita azul",
    },
    {
      name: "SEM TARJA",
      value: "BR",
      color: "white",
      display: "Receita branca",
    },
    {
      name: "EX",
      display: "Exame",
      value: "EX",
      color: "#FDCF72",
    },
    {
      name: "AT",
      value: "AT",
      color: "#009E8C",
      display: "Atestado",
    },
  ];

  let selectedProtocol = useWatch({ name: "protocol", control });

  function findAnswer({ questionnaireResponseItens, targetId }: any) {
    return (
      questionnaireResponseItens?.find((item: any) => item.linkId === targetId)
        ?.answer?.[0] ||
      questionnaireResponseItens?.find((item: any) => item.linkId === targetId)
        ?.item
    );
  }

  function generateMedication(item: any) {
    //console.log("Select:", auxType);
    const medicationName = findAnswer({
      questionnaireResponseItens: item,
      targetId: "8543714373691",
    })?.valueString;

    const presentation = findAnswer({
      questionnaireResponseItens: item,
      targetId: "6273182343815",
    })?.valueString;

    const medicationValue = findAnswer({
      questionnaireResponseItens: item,
      targetId: "5252423656882",
    })?.valueString;

    const substance = findAnswer({
      questionnaireResponseItens: item,
      targetId: "2043756634085",
    })?.valueString;

    const auxTag = findAnswer({
      questionnaireResponseItens: item,
      targetId: "7570117070815",
    })?.valueString;

    const auxType = findAnswer({
      questionnaireResponseItens: item,
      targetId: "2794386593077",
    })?.valueString;

    const auxFrequency = findAnswer({
      questionnaireResponseItens: item,
      targetId: "1265328124778",
      targetText: "Frequency",
    })?.valueString?.split(" ")?.[0];

    const auxPeriod = findAnswer({
      questionnaireResponseItens: item,
      targetId: "6391319168670",
      targetText: "Period",
    })?.valueString?.split(" ")?.[0];

    const auxQuantity = findAnswer({
      questionnaireResponseItens: item,
      targetId: "4145930684259",
    })?.valueString;

    const medicationForm = findAnswer({
      questionnaireResponseItens: item,
      targetId: "4027816497232",
    })?.valueString;

    const medicationNote = findAnswer({
      questionnaireResponseItens: item,
      targetId: "5201559249537",
    })?.valueString;

    const auxForm = [
      auxQuantity && `${auxQuantity}`,
      medicationForm && `${medicationForm}`,
      auxFrequency && `a cada ${auxFrequency} h`,
      auxPeriod && `por ${auxPeriod} dias`,
    ]
      ?.filter((e: string) => e)
      ?.join(" ");

    return {
      name: medicationName,
      label: `${medicationName}${
        auxType === "medication"
          ? `${presentation ? ` - ${presentation}` : ""}`
          : ``
      }`,

      inputValue: {
        ...(substance && {
          code: {
            coding: [
              {
                code: medicationValue,
                display: substance,
                system: "https://fluxmed.com.br/fhir/CodeSystem/CMED",
              },
            ],
          },
          tag: auxTag,
        }),
        presentation: presentation,
        name: medicationName,

        label: `${medicationName}${
          auxType === "medication"
            ? `${presentation ? ` - ${presentation}` : ""}`
            : ``
        }`,
      },
      tag: auxTag,
      ...(auxQuantity && {
        quantity: { label: auxQuantity, value: auxQuantity },
      }),
      ...(auxFrequency && {
        frequency: { label: `${auxFrequency} h` },
      }),
      formValue: { label: medicationForm },
      ...(auxPeriod && {
        time: { label: `${auxPeriod} dias` },
      }),
      note: medicationNote,
      form: auxForm,
      type: auxType,
      tabValue:
        auxType === "medication"
          ? "1"
          : auxType === "supplement"
          ? "2"
          : auxType === "procedure"
          ? "1"
          : "3",
    };
  }

  function generateService(item: any) {
    const serviceName = findAnswer({
      questionnaireResponseItens: item,
      targetId: "8772001052232",
    })?.valueString;

    const auxCondition = findAnswer({
      questionnaireResponseItens: item,
      targetId: "9571768447124",
    });

    const conditionValue = findAnswer({
      questionnaireResponseItens: auxCondition,
      targetId: "4923176744451",
    })?.valueString;

    const conditionName = findAnswer({
      questionnaireResponseItens: auxCondition,
      targetId: "8170939802572",
    })?.valueString;

    const serviceValue = findAnswer({
      questionnaireResponseItens: item,
      targetId: "379415603318",
    })?.valueString;

    const auxCategory = findAnswer({
      questionnaireResponseItens: item,
      targetId: "1383313118296",
    })?.valueString;

    const serviceNote = findAnswer({
      questionnaireResponseItens: item,
      targetId: "3523489955010",
    })?.valueString;

    return {
      type: "service",
      tabValue: "2",
      inputValue: {
        ...(serviceValue && {
          code: serviceValue,
          system:
            "https://fluxmed.com.br/fhir/r5/CodeSystem/TUSSProcedimentosEventos",
        }),
        display: serviceName,
        name: serviceName,
        value: serviceName,
        label: serviceName,
      },
      name: serviceName,
      tag: "EX",
      form: "",
      note: serviceNote,
      condition: {
        code: conditionValue,
        display: conditionName,
        label: conditionName,
        system: "https://fluxmed.com.br/fhir/r4/CodeSystem/CID10",
        value: conditionValue,
      },
      category: { label: auxCategory },
    };
  }

  function generateCertificate(item: any) {
    const certificate = findAnswer({
      questionnaireResponseItens: item,
      targetId: "8744868479001",
    })?.valueString;

    return {
      tabValue: "3",
      type: "certificate",
      name: "Atestado",
      tag: "AT",
      form: "",
      note: certificate,
    };
  }

  const options = protocol?.map((el: any, index: number) => {
    const auxDisplay = findAnswer({
      questionnaireResponseItens: el?.resource?.item,
      targetId: "438586562091",
    })?.valueString;

    const itens = el?.resource?.item?.filter(
      (item: any) => item.linkId !== "438586562091"
    );

    const auxItens = itens?.map((prescriptionItem: any) => {
      if (prescriptionItem?.linkId === "2631266001552") {
        return generateMedication(prescriptionItem.item);
      }
      if (prescriptionItem?.linkId === "5455138012969") {
        return generateService(prescriptionItem.item);
      }
      if (prescriptionItem?.linkId === "7695792866978") {
        return generateCertificate(prescriptionItem.item);
      }
      return {};
    });

    return {
      display: auxDisplay || "-",
      value: index + 1,
      itens: auxItens,
    };
  });

  const onSubmit = async (data: any) => {
    const selectedProtocol = options?.find(
      (opt: any) => opt.value === data?.protocol
    );
    selectedProtocol?.itens?.map((el: any) => addItem(el));
    handleClose();
  };

  const auxMedication = options?.[selectedProtocol - 1]?.itens?.filter(
    (item: any) => item.type === "medication"
  );
  const auxSupplement = options?.[selectedProtocol - 1]?.itens?.filter(
    (item: any) => item.type === "supplement"
  );
  const auxHerbalMedicine = options?.[selectedProtocol - 1]?.itens?.filter(
    (item: any) => item.type === "herbalMedicine"
  );
  const auxProcedure = options?.[selectedProtocol - 1]?.itens?.filter(
    (item: any) => item.type === "procedure"
  );
  const auxService = options?.[selectedProtocol - 1]?.itens?.filter(
    (item: any) => item.type === "service"
  );
  const auxCertificate = options?.[selectedProtocol - 1]?.itens?.filter(
    (item: any) => item.type === "certificate"
  );

  useEffect(() => {
    async function fetchProtocol() {
      const response = await GetPrescriptionProtocol({
        questionnaire: endpoints.PROTOCOL_ID,
        author: organization?.id,
      });

      setProtocol(response?.entry);
    }
    fetchProtocol();
  }, []);
  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: { borderRadius: "5px", padding: 4 },
      }}
      maxWidth="lg"
    >
        <IconButton
          onClick={() => handleClose()}
          sx={{ position: "absolute", right: 5, top: 5 }}
          >
          <Close />
        </IconButton>
      <Typography variant="subtitle2">Protocolos salvos</Typography>
      <Stack direction="row" width="700px" alignItems="center" spacing={4}>
        <Stack
          direction="row"
          spacing={2}
          width="100%"
          justifyContent="space-between"
        >
          <FormSelectInput
            name="protocol"
            label="Selecione um protocolo"
            selectOptions={options || []}
            control={control}
            required
          />
        </Stack>

        <PrimaryButton
          width="200px"
          height="42px"
          onClick={handleSubmit(onSubmit)}
        >
          <Add /> Adicionar
        </PrimaryButton>
      </Stack>
      {selectedProtocol && (
        <Stack mt={4} spacing={1}>
          {auxMedication.length ||
          auxSupplement.length ||
          auxHerbalMedicine.length ? (
            <Stack width="87%" direction="row" alignItems="center" gap={2}>
              <Typography
                variant="subtitle1"
                color="neutral1000.main"
                fontWeight={500}
              >
                Tratamento
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Stack>
          ) : (
            <></>
          )}
          {auxMedication?.length ? (
            <>
              <Typography
                variant="body1"
                color="neutral1000.main"
                fontWeight={500}
              >
                Medicamentos:
              </Typography>
              {auxMedication?.map((el: any, index: number) => {
                const auxTag = tags.find((tag: any) =>
                  el.tag?.toUpperCase()?.includes(tag.name)
                );
                return (
                  <Stack direction="row" width="100%" mb={1} key={`${index}`}>
                    <Stack
                      p={1}
                      gap={1}
                      sx={{
                        background: "#CCC",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      <Stack direction="row" gap={1}>
                        <Typography
                          variant="body1"
                          color="neutral1000.main"
                          fontWeight={500}
                        >
                          {index + 1}. {el.name}
                        </Typography>

                        {el.inputValue?.presentation && (
                          <Typography variant="body1" color="neutral700.main">
                            {el.inputValue?.presentation}
                          </Typography>
                        )}

                        {el.tag && (
                          <Tooltip title={auxTag?.display}>
                            <Button
                              sx={{
                                background:
                                  auxTag?.background ||
                                  auxTag?.color ||
                                  "white",

                                width: "21px",
                                minWidth: "21px",
                                height: "22px",
                                "&:hover": {
                                  backgroundColor:
                                    auxTag?.background ||
                                    auxTag?.color ||
                                    "white",
                                },
                              }}
                              variant="contained"
                            >
                              <Typography
                                sx={{
                                  color: auxTag?.background
                                    ? auxTag?.color
                                    : "black",
                                }}
                                variant="body1"
                                color="neutral700.main"
                              >
                                {auxTag?.value || "-"}
                              </Typography>
                            </Button>
                          </Tooltip>
                        )}
                      </Stack>

                      {el.formValue?.label && (
                        <Typography variant="body1" color="neutral700.main">
                          {el.form}
                        </Typography>
                      )}

                      {el?.type === "service" && el?.inputValue?.code && (
                        <Typography variant="body1" color="neutral700.main">
                          Código TUSS: {el?.inputValue?.code}
                        </Typography>
                      )}

                      {el?.category && (
                        <Typography variant="body1" color="neutral700.main">
                          Categoria: {el?.category?.label}
                        </Typography>
                      )}

                      {el.condition && (
                        <Typography variant="body1" color="neutral700.main">
                          Condição: {el?.condition?.label}{" "}
                          {!el?.condition?.name &&
                            el.condition?.value &&
                            `- CID10 ${el.condition?.value}`}
                        </Typography>
                      )}

                      {el.note && (
                        <Typography variant="body1" color="neutral700.main">
                          {el.note}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                );
              })}
            </>
          ) : (
            <></>
          )}

          {auxSupplement?.length ? (
            <>
              <Typography
                variant="body1"
                color="neutral1000.main"
                fontWeight={500}
              >
                Suplementos:
              </Typography>
              {auxSupplement?.map((el: any, index: number) => {
                const auxTag = tags.find((tag: any) =>
                  el.tag?.toUpperCase()?.includes(tag.name)
                );
                return (
                  <Stack direction="row" width="100%" mb={1} key={`${index}`}>
                    <Stack
                      p={1}
                      gap={1}
                      sx={{
                        background: "#CCC",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      <Stack direction="row" gap={1}>
                        <Typography
                          variant="body1"
                          color="neutral1000.main"
                          fontWeight={500}
                        >
                          {index + 1}. {el.name}
                        </Typography>

                        {el.inputValue?.presentation && (
                          <Typography variant="body1" color="neutral700.main">
                            {el.inputValue?.presentation}
                          </Typography>
                        )}

                        {el.tag && (
                          <Tooltip title={auxTag?.display}>
                            <Button
                              sx={{
                                background:
                                  auxTag?.background ||
                                  auxTag?.color ||
                                  "white",

                                width: "21px",
                                minWidth: "21px",
                                height: "22px",
                                "&:hover": {
                                  backgroundColor:
                                    auxTag?.background ||
                                    auxTag?.color ||
                                    "white",
                                },
                              }}
                              variant="contained"
                            >
                              <Typography
                                sx={{
                                  color: auxTag?.background
                                    ? auxTag?.color
                                    : "black",
                                }}
                                variant="body1"
                                color="neutral700.main"
                              >
                                {auxTag?.value || "-"}
                              </Typography>
                            </Button>
                          </Tooltip>
                        )}
                      </Stack>

                      {el.formValue?.label && (
                        <Typography variant="body1" color="neutral700.main">
                          {el.form}
                        </Typography>
                      )}

                      {el?.type === "service" && el?.inputValue?.code && (
                        <Typography variant="body1" color="neutral700.main">
                          Código TUSS: {el?.inputValue?.code}
                        </Typography>
                      )}

                      {el?.category && (
                        <Typography variant="body1" color="neutral700.main">
                          Categoria: {el?.category?.label}
                        </Typography>
                      )}

                      {el.condition && (
                        <Typography variant="body1" color="neutral700.main">
                          Condição: {el?.condition?.label}{" "}
                          {!el?.condition?.name &&
                            el.condition?.value &&
                            `- CID10 ${el.condition?.value}`}
                        </Typography>
                      )}

                      {el.note && (
                        <Typography variant="body1" color="neutral700.main">
                          {el.note}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                );
              })}
            </>
          ) : (
            <></>
          )}

          {auxHerbalMedicine?.length ? (
            <>
              <Typography
                variant="body1"
                color="neutral1000.main"
                fontWeight={500}
              >
                Fitoterápicos:
              </Typography>
              {auxHerbalMedicine?.map((el: any, index: number) => {
                const auxTag = tags.find((tag: any) =>
                  el.tag?.toUpperCase()?.includes(tag.name)
                );
                return (
                  <Stack direction="row" width="100%" mb={1} key={`${index}`}>
                    <Stack
                      p={1}
                      gap={1}
                      sx={{
                        background: "#CCC",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      <Stack direction="row" gap={1}>
                        <Typography
                          variant="body1"
                          color="neutral1000.main"
                          fontWeight={500}
                        >
                          {index + 1}. {el.name}
                        </Typography>

                        {el.inputValue?.presentation && (
                          <Typography variant="body1" color="neutral700.main">
                            {el.inputValue?.presentation}
                          </Typography>
                        )}

                        {el.tag && (
                          <Tooltip title={auxTag?.display}>
                            <Button
                              sx={{
                                background:
                                  auxTag?.background ||
                                  auxTag?.color ||
                                  "white",

                                width: "21px",
                                minWidth: "21px",
                                height: "22px",
                                "&:hover": {
                                  backgroundColor:
                                    auxTag?.background ||
                                    auxTag?.color ||
                                    "white",
                                },
                              }}
                              variant="contained"
                            >
                              <Typography
                                sx={{
                                  color: auxTag?.background
                                    ? auxTag?.color
                                    : "black",
                                }}
                                variant="body1"
                                color="neutral700.main"
                              >
                                {auxTag?.value || "-"}
                              </Typography>
                            </Button>
                          </Tooltip>
                        )}
                      </Stack>

                      {el.formValue?.label && (
                        <Typography variant="body1" color="neutral700.main">
                          {el.form}
                        </Typography>
                      )}

                      {el?.type === "service" && el?.inputValue?.code && (
                        <Typography variant="body1" color="neutral700.main">
                          Código TUSS: {el?.inputValue?.code}
                        </Typography>
                      )}

                      {el?.category && (
                        <Typography variant="body1" color="neutral700.main">
                          Categoria: {el?.category?.label}
                        </Typography>
                      )}

                      {el.condition && (
                        <Typography variant="body1" color="neutral700.main">
                          Condição: {el?.condition?.label}{" "}
                          {!el?.condition?.name &&
                            el.condition?.value &&
                            `- CID10 ${el.condition?.value}`}
                        </Typography>
                      )}

                      {el.note && (
                        <Typography variant="body1" color="neutral700.main">
                          {el.note}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                );
              })}
            </>
          ) : (
            <></>
          )}
          {auxProcedure.length || auxService.length || auxCertificate.length ? (
            <Stack width="85%" direction="row" alignItems="center" gap={2}>
              <Typography
                variant="subtitle1"
                color="neutral1000.main"
                fontWeight={500}
              >
                Procedimentos
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Stack>
          ) : (
            <></>
          )}

          {auxProcedure?.length ? (
            <>
              <Typography
                variant="body1"
                color="neutral1000.main"
                fontWeight={500}
              >
                Procedimentos:
              </Typography>
              {auxProcedure?.map((el: any, index: number) => {
                const auxTag = tags.find((tag: any) =>
                  el.tag?.toUpperCase()?.includes(tag.name)
                );
                return (
                  <Stack direction="row" width="100%" mb={1} key={`${index}`}>
                    <Stack
                      p={1}
                      gap={1}
                      sx={{
                        background: "#CCC",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      <Stack direction="row" gap={1}>
                        <Typography
                          variant="body1"
                          color="neutral1000.main"
                          fontWeight={500}
                        >
                          {index + 1}. {el.name}
                        </Typography>

                        {el.inputValue?.presentation && (
                          <Typography variant="body1" color="neutral700.main">
                            {el.inputValue?.presentation}
                          </Typography>
                        )}

                        {el.tag && (
                          <Tooltip title={auxTag?.display}>
                            <Button
                              sx={{
                                background:
                                  auxTag?.background ||
                                  auxTag?.color ||
                                  "white",

                                width: "21px",
                                minWidth: "21px",
                                height: "22px",
                                "&:hover": {
                                  backgroundColor:
                                    auxTag?.background ||
                                    auxTag?.color ||
                                    "white",
                                },
                              }}
                              variant="contained"
                            >
                              <Typography
                                sx={{
                                  color: auxTag?.background
                                    ? auxTag?.color
                                    : "black",
                                }}
                                variant="body1"
                                color="neutral700.main"
                              >
                                {auxTag?.value || "-"}
                              </Typography>
                            </Button>
                          </Tooltip>
                        )}
                      </Stack>

                      {el.formValue?.label && (
                        <Typography variant="body1" color="neutral700.main">
                          {el.form}
                        </Typography>
                      )}

                      {el?.type === "service" && el?.inputValue?.code && (
                        <Typography variant="body1" color="neutral700.main">
                          Código TUSS: {el?.inputValue?.code}
                        </Typography>
                      )}

                      {el?.category && (
                        <Typography variant="body1" color="neutral700.main">
                          Categoria: {el?.category?.label}
                        </Typography>
                      )}

                      {el.condition && (
                        <Typography variant="body1" color="neutral700.main">
                          Condição: {el?.condition?.label}{" "}
                          {!el?.condition?.name &&
                            el.condition?.value &&
                            `- CID10 ${el.condition?.value}`}
                        </Typography>
                      )}

                      {el.note && (
                        <Typography variant="body1" color="neutral700.main">
                          {el.note}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                );
              })}
            </>
          ) : (
            <></>
          )}

          {auxService?.length ? (
            <>
              <Typography
                variant="body1"
                color="neutral1000.main"
                fontWeight={500}
              >
                Exames:
              </Typography>
              {auxService?.map((el: any, index: number) => {
                const auxTag = tags.find((tag: any) =>
                  el.tag?.toUpperCase()?.includes(tag.name)
                );
                return (
                  <Stack direction="row" width="100%" mb={1} key={`${index}`}>
                    <Stack
                      p={1}
                      gap={1}
                      sx={{
                        background: "#CCC",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      <Stack direction="row" gap={1}>
                        <Typography
                          variant="body1"
                          color="neutral1000.main"
                          fontWeight={500}
                        >
                          {index + 1}. {el.name}
                        </Typography>

                        {el.inputValue?.presentation && (
                          <Typography variant="body1" color="neutral700.main">
                            {el.inputValue?.presentation}
                          </Typography>
                        )}

                        {el.tag && (
                          <Tooltip title={auxTag?.display}>
                            <Button
                              sx={{
                                background:
                                  auxTag?.background ||
                                  auxTag?.color ||
                                  "white",

                                width: "21px",
                                minWidth: "21px",
                                height: "22px",
                                "&:hover": {
                                  backgroundColor:
                                    auxTag?.background ||
                                    auxTag?.color ||
                                    "white",
                                },
                              }}
                              variant="contained"
                            >
                              <Typography
                                sx={{
                                  color: auxTag?.background
                                    ? auxTag?.color
                                    : "black",
                                }}
                                variant="body1"
                                color="neutral700.main"
                              >
                                {auxTag?.value || "-"}
                              </Typography>
                            </Button>
                          </Tooltip>
                        )}
                      </Stack>

                      {el.formValue?.label && (
                        <Typography variant="body1" color="neutral700.main">
                          {el.form}
                        </Typography>
                      )}

                      {el?.type === "service" && el?.inputValue?.code && (
                        <Typography variant="body1" color="neutral700.main">
                          Código TUSS: {el?.inputValue?.code}
                        </Typography>
                      )}

                      {el?.category && (
                        <Typography variant="body1" color="neutral700.main">
                          Categoria: {el?.category?.label}
                        </Typography>
                      )}

                      {el.condition && (
                        <Typography variant="body1" color="neutral700.main">
                          Condição: {el?.condition?.label}{" "}
                          {!el?.condition?.name &&
                            el.condition?.value &&
                            `- CID10 ${el.condition?.value}`}
                        </Typography>
                      )}

                      {el.note && (
                        <Typography variant="body1" color="neutral700.main">
                          {el.note}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                );
              })}
            </>
          ) : (
            <></>
          )}

          {auxCertificate?.length ? (
            <>
              <Typography
                variant="body1"
                color="neutral1000.main"
                fontWeight={500}
              >
                Relatórios:
              </Typography>
              {auxCertificate?.map((el: any, index: number) => {
                const auxTag = tags.find((tag: any) =>
                  el.tag?.toUpperCase()?.includes(tag.name)
                );
                return (
                  <Stack direction="row" width="100%" mb={1} key={`${index}`}>
                    <Stack
                      p={1}
                      gap={1}
                      sx={{
                        background: "#CCC",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      <Stack direction="row" gap={1}>
                        <Typography
                          variant="body1"
                          color="neutral1000.main"
                          fontWeight={500}
                        >
                          {index + 1}. {el.name}
                        </Typography>

                        {el.inputValue?.presentation && (
                          <Typography variant="body1" color="neutral700.main">
                            {el.inputValue?.presentation}
                          </Typography>
                        )}

                        {el.tag && (
                          <Tooltip title={auxTag?.display}>
                            <Button
                              sx={{
                                background:
                                  auxTag?.background ||
                                  auxTag?.color ||
                                  "white",

                                width: "21px",
                                minWidth: "21px",
                                height: "22px",
                                "&:hover": {
                                  backgroundColor:
                                    auxTag?.background ||
                                    auxTag?.color ||
                                    "white",
                                },
                              }}
                              variant="contained"
                            >
                              <Typography
                                sx={{
                                  color: auxTag?.background
                                    ? auxTag?.color
                                    : "black",
                                }}
                                variant="body1"
                                color="neutral700.main"
                              >
                                {auxTag?.value || "-"}
                              </Typography>
                            </Button>
                          </Tooltip>
                        )}
                      </Stack>

                      {el.formValue?.label && (
                        <Typography variant="body1" color="neutral700.main">
                          {el.form}
                        </Typography>
                      )}

                      {el?.type === "service" && el?.inputValue?.code && (
                        <Typography variant="body1" color="neutral700.main">
                          Código TUSS: {el?.inputValue?.code}
                        </Typography>
                      )}

                      {el?.category && (
                        <Typography variant="body1" color="neutral700.main">
                          Categoria: {el?.category?.label}
                        </Typography>
                      )}

                      {el.condition && (
                        <Typography variant="body1" color="neutral700.main">
                          Condição: {el?.condition?.label}{" "}
                          {!el?.condition?.name &&
                            el.condition?.value &&
                            `- CID10 ${el.condition?.value}`}
                        </Typography>
                      )}

                      {el.note && (
                        <Typography variant="body1" color="neutral700.main">
                          {el.note}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </Stack>
      )}
    </Dialog>
  );
}
