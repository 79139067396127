import { Typography } from "@mui/material";

import {
  FormTextInput,
  FormStack,
  FormButtonGroup,
} from "../../../components/form";
import { ContentBoxTitle } from "../../../components/common";
import { useRegex } from "../../../utils/useRegex";
import { useFormatter } from "../../../utils/useFormatter";
import { IFormInput } from "../types";
import { useFirebaseService } from "../../../utils/useFirebase";
import { handleKeyPress } from "../../../utils/useKeyPress";

interface IProps {
  control: any;
  handleSubmit: Function;
  onBackButtonClick: Function;
  navigateToNextPage: Function;
}

export default function ConfirmAccount({
  control,
  handleSubmit,
  onBackButtonClick,
  navigateToNextPage,
}: IProps) {
  const { sendVerificationCode } = useFirebaseService;

  const onSubmit = async (data: IFormInput) => {
    sendVerificationCode(data.phoneNumber);
    navigateToNextPage();
  };

  return (
    <form
      onKeyDown={(event) =>
        handleKeyPress(event, false, handleSubmit(onSubmit))
      }
      style={{ height: "100%", width: "100%" }}
    >
      <FormStack>
        <ContentBoxTitle>Informações de contato</ContentBoxTitle>

        <Typography
          textAlign="center"
          variant="body1"
          sx={{ color: "neutral600.main", mt: "-10px" }}
        >
          Enviaremos um SMS com um código para validarmos o seu número de
          celular.
        </Typography>

        <FormTextInput
          label="Número de celular"
          name="phoneNumber"
          control={control}
          required={true}
          pattern={useRegex.phoneNumber}
          mask={useFormatter.formatPhoneNumber}
          maxLength={15}
          autoFocus
        />

        <FormTextInput
          name="email"
          control={control}
          label="E-mail"
          required={true}
          pattern={useRegex.email}
        />
        <FormButtonGroup
          onGoBackButtonClick={() => onBackButtonClick()}
          onNextButtonClick={handleSubmit(onSubmit)}
        />
      </FormStack>
    </form>
  );
}
