import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { useFhirData } from "../../../utils/useFhirData";
import dayjs from "dayjs";

const styles = StyleSheet.create({
  section: {
    padding: 40,
    flexDirection: "column",
    flexGrow: 1,
  },
  headerMedication: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    height: 80,
    textTransform: "uppercase",
    marginBottom: 20,
  },
  contentMedication: {
    flexGrow: 1,
    marginBottom: 0,
  },
  footerMedication: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: 6,
  },
  titleMedication: {
    fontSize: 22,
    width: 320,
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
    textTransform: "uppercase",
  },
  text: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
    padding: "5px 0px",
  },
  textResponse: {
    fontSize: 12,
    fontFamily: "Helvetica",
    textTransform: "uppercase",
  },
  box: {
    marginBottom: 10,
  },
  footerContainer: {
    marginTop: 6,
  },
  marginTopSecondPage: {
    marginTop: 40,
  },
  marginTopSubsequentPages: {
    marginTop: 30,
  },
  pageContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
});

export function MedicationSimpleRecipe({ filterMedication, dataPdf }: any) {
  const crm = dataPdf?.[0]?.professionalData?.identifier?.[0]?.value
    .split("/")[1]
    .slice(2);

  const ufCrm = dataPdf?.[0]?.professionalData?.identifier?.[0]?.value
    .split("/")[1]
    .slice(0, 2);

  const splitMedicationsIntoPages = (medications: any[]) => {
    const pages = [];
    for (let i = 0; i < medications.length; i += 8) {
      if (i === 0 && (medications.length === 7 || medications.length === 8)) {
        pages.push(medications.slice(i, i + 7));
        pages.push(medications.slice(i + 7));
      } else {
        pages.push(medications.slice(i, i + 8));
      }
    }
    return pages;
  };

  const medicationPages = splitMedicationsIntoPages(filterMedication);

  const renderMedications = (medications: any[]) => (
    <View style={{ height: "auto", width: "100%", backgroundColor: "#e6e6e6" }}>
      {medications.map((el: any, index: number) => (
        <View key={index} style={styles.box} wrap={false}>
          <Text style={styles.text}>
            Nome do Produto:
            <Text style={styles.textResponse}> {el.name} </Text>
          </Text>
          <Text style={styles.text}>
            Modo de Usar:
            <Text style={styles.textResponse}> {el.form} </Text>
          </Text>
          {el.note && (
            <Text style={styles.text}>
              Obs:
              <Text style={styles.textResponse}> {el.note} </Text>
            </Text>
          )}
        </View>
      ))}
    </View>
  );

  const renderFooter = () => (
    <View style={styles.footerContainer}>
      <View style={styles.footerMedication}>
        <Text style={styles.text}>
          Nome do(a) Médico(a):
          <Text style={styles.textResponse}>
            {dataPdf?.[0]?.professionalData?.practitioner.display || ""}
          </Text>
        </Text>
        <View style={{ display: "flex", flexDirection: "row", gap: 3 }}>
          <Text style={styles.text}>
            CRM: <Text style={styles.textResponse}>{crm || ""}</Text>
          </Text>
          <Text style={styles.text}>
            UF: <Text style={styles.textResponse}>{ufCrm || ""}</Text>
          </Text>
        </View>
      </View>

      <View style={styles.footerMedication}>
        <Text style={styles.text}>
          Local de Atendimento:
          <Text style={styles.textResponse}>
            {dataPdf?.[0]?.location?.alias?.[0] || "-"}
          </Text>
        </Text>
        <Text style={styles.text}>
          Endereço:
          <Text style={styles.textResponse}>
            {useFhirData.formatAddress(dataPdf?.[0].location)}
          </Text>
        </Text>
        <Text style={styles.text}>
          Data de Emissão:
          <Text style={styles.textResponse}>
            {dayjs().format("DD/MM/YYYY")}
          </Text>
        </Text>
      </View>
    </View>
  );

  return (
    <View style={styles.section}>
      <View style={styles.headerMedication}>
        <Text style={styles.titleMedication}>Receituário Simples</Text>
      </View>

      {medicationPages.map((pageMedications, index) => (
        <View
          key={index}
          break={index > 0}
          style={[
            styles.pageContainer,
            index > 0 ? styles.marginTopSubsequentPages : {},
          ]}
        >
          <View style={styles.contentMedication}>
            {index === 0 && (
              <>
                <Text style={styles.text}>
                  Paciente:
                  <Text style={styles.textResponse}>
                    {dataPdf?.[0]?.patientData || ""}
                  </Text>
                </Text>
                <Text style={styles.text}>Prescrição:</Text>
              </>
            )}
            {renderMedications(pageMedications)}
          </View>

          {index === medicationPages.length - 1 && renderFooter()}
        </View>
      ))}
    </View>
  );
}
