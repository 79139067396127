import { ArrowForwardRounded } from "@mui/icons-material";
import { Checkbox, Typography, Button } from "@mui/material";
import { Stack } from "@mui/system";

export function SearchControls({ handleCheck }: any) {
  return (
    <>
      <Stack
        mt={1}
        alignItems="center"
        direction="column"
        width="100%"
        paddingTop="4px"
      >
        <Stack
          mt={1}
          alignItems="center"
          direction="row"
          width="100%"
          paddingTop="4px"
        >
          <Stack direction="row" alignItems="center" width="100%">
            <Checkbox
              defaultChecked
              onChange={handleCheck}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography
              variant="body1"
              color="neutral700.main"
              fontWeight={500}
            >
              Busca avançada
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
