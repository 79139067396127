import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { OAuthUser } from "../../services/auth";
import { toast } from "react-toastify";
import base64 from "base-64";

import { CircularLoading } from "../../components/common";
import { Form } from "./components/Form";
import { useValidate } from "../../utils/useValidate";

import SignInLogo from "../../assets/fluxmed-signin.svg";

export function SignIn() {
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { signinCredentialType } = useValidate;

  function verificarBase64(baseCode: string) {
    try {
      window.atob(baseCode);
      return true;
    } catch (error) {
      return false;
    }
  }

  const url = window.location.href.split("params=")[1];
  const urlInformation = isMobile ? url?.split("&")[0] : url;
  const decodedParams = verificarBase64(urlInformation)
    ? base64.decode(urlInformation)
    : undefined;
  const identifier = decodedParams?.split(":")[0] || "";
  const password = decodedParams?.split(":")[1] || "";

  const tokenMobile = url?.split("&tokenMobile=")[1];

  const loginApp = async () => {
    const auxValue = signinCredentialType(identifier);
    const auxType =
      auxValue.includes("userName") || auxValue.includes("phoneNumbers.mobile");

    let credential;

    if (auxType) {
      credential = identifier.replace(/[^a-zA-Z0-9]/g, "");
    } else {
      credential = identifier.replace("@", "[AT]");
    }

    setLoading(true);
    try {
      const response = await OAuthUser(credential, password);
      if (response) {
        localStorage.setItem("token", response.access_token);
        localStorage.setItem("logged", "true");
        localStorage.setItem("username", credential);
        localStorage.setItem("refresh_token", response.refresh_token);
        localStorage.setItem("device_id", tokenMobile);
        const expirationTime = (
          Date.now() +
          response?.expires_in * 1000
        ).toString();
        localStorage.setItem("expirationTime", expirationTime);
        navigate("/");
      } else throw new Error("Sign in error");
    } catch (err) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage("Login Failed");
      }
      toast.error("Erro, verifique suas credenciais!");
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (identifier && password) {
      loginApp();
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <Stack width="100%" direction="row">
      {loading ? (
        <Stack
          width="100%"
          height="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <CircularLoading />
        </Stack>
      ) : (
        <>
          {isMobile ? (
            <Stack width="100%" padding="60px 20px">
              <Form />
            </Stack>
          ) : (
            <>
              <img
                src={SignInLogo}
                alt="Logo do Fluxmed"
                style={{ maxWidth: "50%", objectFit: "cover", height: "100vh" }}
              />
              <Form />
            </>
          )}
        </>
      )}
    </Stack>
  );
}
