import hash from "object-hash";
import base64 from "base-64";
import * as uuid from "uuid";

import { endpoints, oids } from "../../configs/Settings";
import { HTTPResponseStatus } from "../../utils/enum";
import { useAuth } from "../../utils/useAuth";
import { useAxios } from "../../utils/useAxios";
import { auxiliarData } from "./AuxliarData";
import { appc_utils } from "./appc_utils";
import dayjs from "dayjs";
import { toast } from "react-toastify";

interface IAPPC_POLICY {
  option: "Opt-in" | "Opt-out";
  patient_id: string | any;
}

export async function APPC_POLICY({ option, patient_id }: IAPPC_POLICY) {
  let url = `${endpoints.APPC}`;

  const appc_request = appc_utils.deepCopy(auxiliarData.policy_request);

  const rootVal = `${oids.cpf}`;
  // Realizar validação para oid
  const role = {
    $: {
      classificationScheme: "urn:uuid:cccf5598-8b07-4b77-a05e-ae952c785ead",
      classifiedObject: "doc1",
      id: "cl9",
      objectType:
        "urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification",
      nodeRepresentation: "223565",
    },
    "rim:Slot": [
      {
        $: {
          name: "codingScheme",
        },
        "rim:ValueList": [
          {
            "rim:Value": ["1.3.6.1.4.1.54443.1.1.6.1"],
          },
        ],
      },
    ],
    "rim:Name": [
      {
        "rim:LocalizedString": [
          {
            $: {
              value: "Enfermeiro da estratégia de saúde da família",
            },
          },
        ],
      },
    ],
  };

  const submission_slot = {
    $: {
      name: "submissionTime",
    },
    "rim:ValueList": [
      {
        "rim:Value": [`${dayjs().format("YYYYMMDDHHmmss")}`],
      },
    ],
  };

  const submission_id = {
    $: {
      identificationScheme: "urn:uuid:96fdda7c-d067-4183-912e-bf5ee74998a8",
      id: "ei3",
      objectType:
        "urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:ExternalIdentifier",
      value: `1.42.20130403134532.123.${dayjs().format("YYYYMMDDHHmmss")}.1`,
      registryObject: "ss1",
    },
    "rim:Name": [
      {
        "rim:LocalizedString": [
          {
            $: {
              value: "XDSSubmissionSet.uniqueId",
            },
          },
        ],
      },
    ],
  };
  const doc_id = {
    $: {
      identificationScheme: "urn:uuid:2e82c1f6-a085-4c72-9da3-8640a32e42ab",
      value: `1.42.20130403134532.123.${dayjs().format("YYYYMMDDHHmmss")}`,
      id: "ei5",
      objectType:
        "urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:ExternalIdentifier",
      registryObject: "doc1",
    },
    "rim:Name": [
      {
        "rim:LocalizedString": [
          {
            $: {
              value: "XDSDocumentEntry.uniqueId",
            },
          },
        ],
      },
    ],
  };
  const creation_time = {
    $: {
      name: "creationTime",
    },
    "rim:ValueList": [
      {
        "rim:Value": [`${dayjs().format("YYYYMMDDHHmmss")}`],
      },
    ],
  };
  const start_time = {
    $: {
      name: "serviceStartTime",
    },
    "rim:ValueList": [
      {
        "rim:Value": [
          `${dayjs().subtract(10800000, "ms").format("YYYYMMDDHHmmss")}`,
        ],
      },
    ],
  };
  const stop_time = {
    $: {
      name: "serviceStopTime",
    },
    "rim:ValueList": [
      {
        "rim:Value": [
          `${dayjs().subtract(7200000, "ms").format("YYYYMMDDHHmmss")}`,
        ],
      },
    ],
  };

  appc_request["soap:Envelope"]["soap:Body"][0][
    "urn:ProvideAndRegisterDocumentSetRequest"
  ][0]["lcm:SubmitObjectsRequest"][0]["rim:RegistryObjectList"][0][
    "rim:ExtrinsicObject"
  ][0]["rim:Classification"].push(role);

  appc_request["soap:Envelope"]["soap:Body"][0][
    "urn:ProvideAndRegisterDocumentSetRequest"
  ][0]["lcm:SubmitObjectsRequest"][0]["rim:RegistryObjectList"][0][
    "rim:RegistryPackage"
  ][0]["rim:Slot"][0] = submission_slot;

  appc_request["soap:Envelope"]["soap:Body"][0][
    "urn:ProvideAndRegisterDocumentSetRequest"
  ][0]["lcm:SubmitObjectsRequest"][0]["rim:RegistryObjectList"][0][
    "rim:RegistryPackage"
  ][0]["rim:ExternalIdentifier"].push(submission_id);

  appc_request["soap:Envelope"]["soap:Body"][0][
    "urn:ProvideAndRegisterDocumentSetRequest"
  ][0]["lcm:SubmitObjectsRequest"][0]["rim:RegistryObjectList"][0][
    "rim:ExtrinsicObject"
  ][0]["rim:ExternalIdentifier"].push(doc_id);

  appc_request["soap:Envelope"]["soap:Body"][0][
    "urn:ProvideAndRegisterDocumentSetRequest"
  ][0]["lcm:SubmitObjectsRequest"][0]["rim:RegistryObjectList"][0][
    "rim:ExtrinsicObject"
  ][0]["rim:Slot"].push(creation_time);

  appc_request["soap:Envelope"]["soap:Body"][0][
    "urn:ProvideAndRegisterDocumentSetRequest"
  ][0]["lcm:SubmitObjectsRequest"][0]["rim:RegistryObjectList"][0][
    "rim:ExtrinsicObject"
  ][0]["rim:Slot"].push(start_time);

  appc_request["soap:Envelope"]["soap:Body"][0][
    "urn:ProvideAndRegisterDocumentSetRequest"
  ][0]["lcm:SubmitObjectsRequest"][0]["rim:RegistryObjectList"][0][
    "rim:ExtrinsicObject"
  ][0]["rim:Slot"].push(stop_time);

  appc_request["soap:Envelope"]["soap:Header"][0][
    "wsa:MessageID"
  ][0]._ = `uuid:${uuid.v1()}`;

  let appc_policy;
  switch (option) {
    case "Opt-out":
      appc_policy = appc_utils.deepCopy(auxiliarData.policy_opt_out);
      break;
    case "Opt-in":
      appc_policy = appc_utils.deepCopy(auxiliarData.policy_opt_in);
      break;
    default:
      throw new Error("Policy Unrecognized");
  }

  appc_policy.PolicySet.Policy[0].$.PolicyId = `urn:uuid:${uuid.v1()}`;
  appc_policy.PolicySet.Policy[0].Rule[0].$.RuleId = `urn:uuid:${uuid.v1()}`;

  appc_policy.PolicySet.$.PolicySetId = `urn:uuid:${uuid.v1()}`;

  const xml2js = require("xml2js");
  const builder = new xml2js.Builder();
  let appc_xml = builder.buildObject(appc_policy);
  appc_xml = appc_xml.replace(/#{#Project#patient-id}/g, patient_id);
  appc_xml = appc_xml.replace(/#{#Project#patient-id-oid}/g, rootVal);
  const xml = appc_xml;

  let newBody = auxiliarData.appc_consent_request_fhir;
  const currentDateTime = dayjs();
  const currentDateTimeISO: string = currentDateTime.toISOString();

  const randomValue = Math.random() * 9999;

  // Gere um UUID para documentManifest
  const documentManifestUUID = uuid.v1({
    msecs: parseFloat(`${currentDateTime.valueOf() + randomValue}`),
  });

  // Gere um UUID para documentReference
  const documentReferenceUUID = uuid.v1({
    msecs: parseFloat(`${currentDateTime.valueOf() + randomValue}`),
  });
  const encodedDocument = base64.encode(xml);

  const hashh = hash(encodedDocument);
  const docsize = hashh.length;

  const organizationId = "05490544000100";
  const organizationOID = oids.organization;

  const substValues = [
    rootVal,
    patient_id,
    documentManifestUUID,
    organizationOID,
    organizationId,
    currentDateTime.valueOf(),
    currentDateTimeISO,
    documentReferenceUUID,
    docsize,
    hashh,
    encodedDocument,
  ];

  for (let i = 11; i > 0; i -= 1) {
    const ra = new RegExp(`(${"\\$"}${i})+`, "g");
    newBody = String(newBody).replace(ra, substValues[i - 1]);
  }

  const headers = {
    Accept: "*application/fhir+xml",
    Authorization: await useAuth.getUserToken(),
    "X-User-token": await useAuth.getXUserToken(),
    "Content-Type": "application/fhir+xml",
  };

  const response = await useAxios
    .post(url, newBody, { headers, timeout: 8000 })
    .then((response) => {
      if (
        response.status !== HTTPResponseStatus.Created &&
        response.status !== HTTPResponseStatus.Success
      ) {
        throw new Error(`APPC_REQUEST: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("APPC_POLICY ", error);
      // toast.error(`APPC_POLICY Erro: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
