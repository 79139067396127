import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { useAxios } from "../../../utils/useAxios";

export async function ManoleAuth() {
  const url = `${endpoints.Interdrugs}/auth`;
  const email = "anderson.peligrini@linkconsulting.com.br";
  const password = "Qwerty123!";
  const data = `email=${email}&password=${password}&codeTwoFactorAuthenticator=false&ip=null`;
  const headers = {
    Accept: "*/*",

    "Content-Type": "application/x-www-form-urlencoded",
  };
  const response = await useAxios
    .post(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== 201) {
        throw new Error(`InterDrugs error: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("ManoleAuth ", error);
      // toast.error(`ManoleAuth Erro: ${error?.response?.status}`);
      // throw new Error();
    });
  if (response) return response;
}
