import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

interface IProps {
  id?: string;
  organization?: string;
  sort?: boolean;
  count?: number;
  status?: string;
}

export async function GetContract({
  id,
  organization,
  sort,
  count,
  status,
}: IProps) {
  let url = `${endpoints.ADMFHIR}/Contract`;
  if (id) url = `${url}/${id}`;
  if (organization) url = `${url}?authority=Organization/${organization}`;
  if (sort) url = `${url}&_sort=-_lastUpdated`;
  if (count) url = `${url}&_count=${count}`;
  if (status) url = `${url}&status=${status}`;

  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`GetContract: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetContract ", error);
      // toast.error(`GetContract Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
