import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Stack,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

import dayjs from "dayjs";
import { GenericTable } from "../../../../../components/GenericTable/GenericTable";
import { useDatetime } from "../../../../../utils/useDatetime";
import { QuestionnaireResponseActions } from "./QuestionnaireResponseActions";
import { useState } from "react";
import { QuestionnaireVisualizer } from "./QuestionnaireViasualizer";
import { useFhirData } from "../../../../../utils/useFhirData";

export function QuestionnaireResponse({ data, title }: any) {
  const { formatToDDMMYYYY } = useDatetime;
  const [open, setOpen] = useState<boolean>(false);
  const [responseData, setResponseData] = useState();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      column: "Descrição",
      rowName: "description",
    },
    {
      column: "Especialidade",
      rowName: "serviceType",
    },
    { column: "Profissional", rowName: "practitioner" },
    { column: "Data", rowName: "date" },
  ];

  const rowData = data?.[0]?.entry
    ?.filter((e: any) => e.resource?.resourceType === "QuestionnaireResponse")
    ?.map((el: any, index: number) => {
      const date = formatToDDMMYYYY(dayjs(el?.resource?.authored));
      // const status = el?.status;
      const resource = el?.resource;
      const encounter = data?.[0]?.entry?.find(
        (encounter: any) =>
          encounter?.resource?.id ===
          el?.resource?.encounter?.reference?.split("/")?.[1]
      )?.resource;

      const practitioner = useFhirData?.findParticipant(
        encounter?.participant,
        "Practitioner"
      );

      const serviceType =
        encounter?.serviceType?.[0]?.reference?.display || "-";

      return {
        serviceType,
        description: "Questionário SiiS",
        // status,
        date,
        resource,
        practitioner: practitioner?.display || practitioner?.reference || "-",
      };
    });

  return (
    <Accordion sx={{ width: "100%" }} elevation={5}>
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack direction="row" alignItems="center" gap={1}>
          {title}
          <Avatar
            sx={{
              width: 26,
              height: 26,
              fontSize: "14px",
              fontWeight: 600,
              backgroundColor: "primary100.main",
              color: "primary1000.main",
            }}
          >
            {rowData?.length || "0"}
          </Avatar>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <GenericTable
          tableColumns={columns}
          tableRows={rowData}
          actions={(rowData: any) => (
            <QuestionnaireResponseActions
              row={rowData}
              handleOpen={handleOpen}
              setResponseData={setResponseData}
            />
          )}
        />
      </AccordionDetails>

      {open && (
        <QuestionnaireVisualizer
          data={responseData}
          handleClose={handleClose}
          isOpen={open}
        />
      )}
    </Accordion>
  );
}
