import { Stack } from "@mui/system";
import { FormAutoComplete, FormDatePicker, FormTextInput } from "../../../form";
import { IconButton } from "@mui/material";
import { Search } from "@mui/icons-material";
import dayjs from "dayjs";
import { GetPatient } from "../../../../services/fhir";
import { useDatetime } from "../../../../utils/useDatetime";
import { toast } from "react-toastify";

const optionsGender: Array<any> = [
  { label: "Masculino", value: "male" },
  { label: "Feminino", value: "female" },
];

export function AdvancedSearch({
  control,
  handleSubmit,
  setResult,
  setLoading,
}: any) {
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const mpiPatient = await GetPatient({
        name: data?.name,
        gender: data?.gender?.value,
        birthDate:
          data?.birthDate &&
          useDatetime.formatDateToISO(dayjs(data?.birthDate)),
      })?.then((response) => response?.data);

      const auxPatient = mpiPatient?.entry?.map((el: any) => el?.resource);
      if (auxPatient) setResult(auxPatient);
      else {
        setResult(undefined);
        toast.warn(
          "Nenhum resultado encontrado, verifique os dados e tente novamente"
        );
      }
    } catch (err) {
      console.log("AdvancedSearch", err);
      toast.error("Não foi possível realizar a busca");
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };
  return (
    <form onKeyDown={handleKeyPress}>
      <Stack
        mt={1}
        alignItems="center"
        direction="row"
        spacing={2}
        width="100%"
      >
        <FormTextInput
          label="Nome do paciente"
          name="name"
          control={control}
          required
          autoFocus
        />
        <FormDatePicker
          name="birthDate"
          label="Data de nascimento"
          maxDate={dayjs()}
          control={control}
        />
        <FormAutoComplete
          label="Sexo de nascimento"
          name="gender"
          control={control}
          //loading={patientOrganizationLoading}
          selectOptions={optionsGender}
        />

        <IconButton
          onClick={handleSubmit(onSubmit)}
          sx={{
            backgroundColor: "primary700.main",
            color: "white",
            borderRadius: 2,
            height: 52,
            width: 52,
            boxShadow: "1",
            ":hover": {
              backgroundColor: "primary800.main",
            },
          }}
        >
          <Search />
        </IconButton>
      </Stack>
    </form>
  );
}
