import React from "react";
import { MedicalServices, AccountCircle } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { useViewContext } from "../../../../contexts/ViewContext";

const ICON_SIZE = 40;

export default function ViewButtons({ isOpen }: { isOpen: boolean }) {
  const { access, setAccess } = useSessionContext();
  const { changeView } = useViewContext();

  return (
    <Stack width={isOpen ? "100%" : "60px"} alignItems="center" spacing={1}>
      <Typography variant="subtitle2">
        {isOpen ? "Trocar visão do perfil" : "Visão"}
      </Typography>
      <Stack
        width="100%"
        direction={isOpen ? "row" : "column"}
        justifyContent="center"
      >
        <IconButton aria-label="profissional" onClick={() => changeView()}>
          <MedicalServices
            sx={{
              backgroundColor:
                access.type === "professional"
                  ? "primary.main"
                  : "neutral0.main",
              color:
                access.type === "professional"
                  ? "neutral0.main"
                  : "neutral1000.main",
              width: ICON_SIZE,
              height: ICON_SIZE,
              borderRadius: ICON_SIZE,
              padding: 1,
            }}
          />
        </IconButton>
        <IconButton
          aria-label="paciente"
          onClick={async () => {
            setAccess({ type: "patient" });
          }}
        >
          <AccountCircle
            sx={{
              backgroundColor:
                access.type === "patient" ? "primary.main" : "neutral0.main",
              color:
                access.type === "patient"
                  ? "neutral0.main"
                  : "neutral1000.main",
              width: ICON_SIZE,
              height: ICON_SIZE,
              borderRadius: ICON_SIZE,
              padding: 1,
            }}
          />
        </IconButton>
      </Stack>
    </Stack>
  );
}
