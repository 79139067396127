import { InsertInvitation } from "@mui/icons-material";
import { Avatar, Box, Modal, Stack, Typography } from "@mui/material";
import { useFormatter } from "../../../utils/useFormatter";
import { useSessionContext } from "../../../contexts/SessionContext";
import { useNavigate } from "react-router-dom";
import { FormButtonGroup } from "../../../components/form";
import { useViewContext } from "../../../contexts/ViewContext";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "75vh",
  bgcolor: "background.paper",
  background: "linear-gradient(#009E8C 20%, #ffffff 38%)",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export function ModalSchedule({ handleClose, open }: any) {
  const { user, loading } = useSessionContext();

  const navigate = useNavigate();

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Stack
          direction="column"
          justifyContent="space-around"
          alignItems="center"
          height="90%"
        >
          <Stack alignItems="center" spacing={3}>
            <Avatar
              sx={{
                width: 90,
                height: 90,
                backgroundColor: "primary100.main",
                color: "primary900.main",
              }}
            >
              <Typography variant="h3" fontWeight={600}>
                {user?.name?.slice(0, 1)}
              </Typography>
            </Avatar>

            <Typography variant="h5" fontWeight={600}>
              Bem vindo ao Fluxmed Clínicas,{" "}
              {useFormatter.formatNameDisplay(user?.givenName)}!
            </Typography>
          </Stack>
          <Stack alignItems="center" spacing={3} width="80%">
            <InsertInvitation fontSize="large" color="primary" />
            <Typography variant="h5" fontWeight={600}>
              Crie sua primeira agenda e inicie sua jornada.
            </Typography>
          </Stack>
          <Stack width="80%" direction="row" alignItems="center" spacing={3}>
            <FormButtonGroup
              onGoBackButtonClick={() => {
                localStorage.setItem("scheduleCreated", "true");
                handleClose();
              }}
              onNextButtonClick={() => navigate("/newschedule")}
              goBackButtonText="Fechar"
              nextButtonText="Criar Agenda"
              loading={loading}
            />
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
