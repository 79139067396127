import {
  Divider,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { Controller, useWatch } from "react-hook-form";
import { useMedicalCareContext } from "../../../contexts/MedicalCareContext";
import { InputText } from "../../ClinicalRegister/components/QuestionnaireViewer/components/Form";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
export function StatusAndNote({ type, index, answer }: any) {
  const { control, unregister } = useMedicalCareContext();

  const statusWatch = useWatch({
    name: `${type}.${index}_${answer}.status`,
    control,
  });

  return (
    <Stack>
      <Controller
        name={`${type}.${index}_${answer}.status`}
        control={control}
        render={({ field }) => (
          <>
            <Divider sx={{ mb: 2 }}></Divider>
            <Stack
              direction={"row"}
              spacing={2}
              alignItems={"end"}
              sx={{ paddingX: 2 }}
            >
              <Stack direction={"column"}>
                <FormLabel
                  component="legend"
                  sx={{ color: "#777", fontWeight: 500 }}
                >
                  Status
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  aria-label="position"
                  sx={{ width: "100%" }}
                  {...field}
                  value={field.value ?? ""}
                  onChange={(e) => {
                    field.onChange(e); // Update the form value
                  }}
                >
                  <FormControlLabel
                    key={`1_${index}`}
                    value={1}
                    control={
                      <Radio
                        sx={{
                          color: "#007C6D",
                        }}
                      />
                    }
                    label="Concluido"
                    sx={{ color: "#777" }}
                  />
                  <FormControlLabel
                    key={`2_${index}`}
                    value={2}
                    control={
                      <Radio
                        sx={{
                          color: "#FBBB3C",
                          "&.Mui-checked": {
                            color: "#FBBB3C",
                          },
                        }}
                      />
                    }
                    label="Parcialmente"
                    sx={{ color: "#777" }}
                  />
                  <FormControlLabel
                    key={`3_${index}`}
                    value={3}
                    control={
                      <Radio
                        sx={{
                          color: "#EF4352",
                          "&.Mui-checked": {
                            color: "#EF4352",
                          },
                        }}
                      />
                    }
                    label="Falhou"
                    sx={{ color: "#777" }}
                  />
                </RadioGroup>
              </Stack>
              {statusWatch && (
                <IconButton
                  sx={{ width: "fit-content" }}
                  aria-label="delete"
                  onClick={() => {
                    field.onChange("");
                    unregister(`${type}.${index}_${answer}.nota`);
                  }}
                >
                  <ReplayRoundedIcon sx={{ color: "colors.neutral700" }} />
                </IconButton>
              )}
            </Stack>
          </>
        )}
      />

      {statusWatch && (
        <InputText
          rows={4}
          key={`${type}.${index}_${answer}.nota`}
          label="Nota"
          name={`${type}.${index}_${answer}.nota`}
          control={control}
        />
      )}
    </Stack>
  );
}
