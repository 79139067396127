import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";

import { ContentBoxTitle } from "../../../components/common";
import { IFormInput } from "../types";
import { SignUpRegister } from "../../../services/scim";
import { FormButtonGroup } from "../../../components/form";

type IProps = {
  handleSubmit: any;
  navigateToNextPage: Function;
};

export function TermsOfUse({ handleSubmit, navigateToNextPage }: IProps) {
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [scrollComplete, setScrollComplete] = useState(false);

  const handleScroll = (e: any) => {
    const element = e.target;
    if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
      setScrollComplete(true);
    } else {
      setScrollComplete(false);
    }
  };

  const navigate = useNavigate();

  const onSubmit = async (data: IFormInput) => {
    setLoading(true);
    try {
      await SignUpRegister(data);
      navigateToNextPage();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack width={{ lg: "150%", sm: "130%" }}>
      <ContentBoxTitle>Termos de Uso e Política de Privacidade</ContentBoxTitle>
      <Box
        maxHeight="500px"
        sx={{ backgroundColor: "neutral0.main", overflowY: "scroll" }}
        mb={2}
        mt={2}
        // onScroll={handleScroll}
      >
        <Box
          height={800}
          sx={{
            backgroundColor: "neutral100.main",
          }}
        >
          {/* <iframe
            title="PDF Viewer"
            src="https://fluxmed.com.br/termo-de-uso-e-declaracao-de-confidencialidade/"
            //Posible solution to the security problem in the header option "X-Frame-Options: SAMEORIGIN"
            //src="https://your-proxy-server.com?url=https://fluxmed.com.br/termos-e-condicoes-de-uso-da-plataforma-fluxmed/"
            width="100%"
            height="600px"
          /> */}
          <main>
            <div>
              <p>
                <strong>
                  <u>
                    TERMOS DE USO DA PLATAFORMA FLUXMED E PROTEÇÃO DE DADOS
                    PESSOAIS (“Termo”)
                  </u>
                </strong>
              </p>

              <p>
                <strong>INTRODUÇÃO</strong>
              </p>

              <p>
                Este Termo é oferecido pela{" "}
                <strong>Core Consultoria e Serviços Ltda</strong>
                <strong>.</strong>, empresa de tecnologia com sede em
                Brasília/DF, inscrita no CNPJ nº 05.490.544/0001-00 que, de
                agora em diante, será referida como “
                <strong>Core Consulting</strong>” ou, simplesmente “
                <strong>
                  <u>nós</u>
                </strong>
                ”.
              </p>

              <p>
                A <strong>Core Consulting</strong> é a única e exclusiva
                proprietária das marcas e dos domínios associados à denominada
                “Fluxmed”, “fluxmed.com.br”, fluxmed.cloud e
                coreconsulting.com.br. Assim que, ela é a desenvolvedora,
                proprietária de todos os direitos materiais e imateriais da “
                <strong>Plataforma Fluxmed</strong>” ou simplemente ““
                <strong>Fluxmed</strong>“.
              </p>

              <p>
                A “<strong>Fluxmed</strong>” é uma plataforma de desenvolvimento
                de serviços digitais de saúde, em&nbsp;constante evolução
                tecnológica, incluindo e disponibilizando novas funcionalidades
                com frequência. Isso implica em constante atualização deste
                Termo que estará sempre disponível na Plataforma para a leitura,
                atualização e compreensão pelo <strong>usuário</strong> da{" "}
                <strong>Plataforma Fluxmed</strong>, ou seja,{" "}
                <strong>
                  <u>VOCÊ</u>
                </strong>{" "}
                (ou o seu representante legal).
              </p>

              <p>
                <strong>VOCÊ</strong>
                <strong> </strong>(ou seu representante legal), usuário da{" "}
                <strong>Plataforma Fluxmed</strong> é um paciente do
                estabelecimento de saúde que contratou a{" "}
                <strong>Plataforma Fluxmed</strong> para, por meio dela, prestar
                a <strong>VOCÊ</strong> um serviço de saúde.{" "}
                <strong>VOCÊ</strong> também é um titular de dados pessoais,
                consumidor que, por meio deste Termo, manifesta a sua vontade
                livre, informada e inequívoca e aceita as condições para
                utilizar a <strong>Plataforma</strong> <strong>Fluxmed</strong>{" "}
                por meio de Aplicativo ou pela <em>web</em>.
              </p>

              <p>
                <strong>OBJETIVO E ACEITAÇÃO</strong>
                <strong></strong>
              </p>

              <p>
                Este Termo tem como{" "}
                <strong>
                  <u>OBJETIVO</u>
                </strong>
                <strong> </strong>cumprir com o dever de informar{" "}
                <strong>VOCÊ</strong>, sobre as indicações e limitações de uso,
                as finalidades, o canal de atendimento para dúvidas técnicas,
                seus direitos e obrigações relacionados à utilização da{" "}
                <strong>Plataforma</strong> <strong>Fluxmed</strong>.
              </p>

              <p>
                Além disso, por meio deste Termo queremos mostrar nosso
                compromisso com a Lei Geral de Proteção de Dados Pessoais (Lei
                nº 13.709/2018 &#8211; “<strong>LGPD</strong>”) e reforçar nosso
                dever com a transparência e a prestação de contas ao descrever:
                o ciclo de tratamento de seus dados pessoais e dados pessoais
                sensíveis de saúde, a base legal adotada, os tratamentos
                realizados pela <strong>Core Consulting</strong> e nossa atuação
                como Agente de Tratamento com relação ao estabelecimento de
                saúde que <strong>VOCÊ</strong> vai interagir por meio da{" "}
                <strong>Plataforma Fluxmed</strong>.&nbsp;
              </p>

              <p>
                Através da leitura deste Termo <strong>VOCÊ</strong> também vai
                entender e precisa concordar que a aceitação dele é obrigatória
                para utilizar a <strong>Plataforma Fluxmed</strong>. Se{" "}
                <strong>VOCÊ</strong> não concordar com qualquer item deste
                Termo <strong>VOCÊ</strong> não poderá utilizar a{" "}
                <strong>Plataforma Fluxmed</strong> porque este Termo é de{" "}
                <strong>adesão</strong>.
              </p>

              <p>
                Desde já, <strong>VOCÊ</strong> precisa saber que poderá{" "}
                <strong>revogar</strong> o seu consentimento a qualquer momento
                e sem nenhum custo. Aliás, a <strong>Plataforma Fluxmed</strong>{" "}
                permite que <strong>VOCÊ</strong> faça a gestão de seu
                consentimento. Isso significa que <strong>VOCÊ</strong> poderá
                gerenciar com quem e com quais estabelecimentos de saúde{" "}
                <strong>VOCÊ</strong> permite que seus dados de saúde sejam
                compartilhados e visualizados.
              </p>

              <p>
                A <strong>Plataforma Fluxmed</strong> foi desenvolvida com a
                premissa de que <strong>VOCÊ</strong> é o protagonista de sua
                saúde e titular de seus dados pessoais.
              </p>

              <p>
                Assim que VOCÊ realiza o seu cadastro na plataforma, VOCÊ
                precisa saber que VOCÊ recebe um perfil de usuário com visão de
                paciente, as funcionalidades são centradas em VOCÊ. Quando VOCÊ
                for atendido em uma ou mais de uma organização de saúde, que
                também utiliza a plataforma, fique ciente que a organização de
                saúde passa a ser controladora das suas informações de saúde.
              </p>

              <p>
                <strong>
                  DESCRIÇÃO DAS FUNCIONALIDADES DA PLATAFORMA FLUXMED E
                  LIMITAÇÕES DE USO
                </strong>
              </p>

              <p>
                A “<strong>Fluxmed</strong>” está hospedada em nuvem no Brasil e
                tem funcionalidades como: armazenamento do prontuário
                eletrônico, agendamento de consultas, emissão de documentos
                médicos eletrônicos (prescrição, pedido de exames, atestado),
                realização de teleconsultas por video. Ela também permite que{" "}
                <strong>VOCÊ</strong> visualize todo o histórico de acessos às
                suas informações de saúde sempre que <strong>VOCÊ</strong>{" "}
                quiser, o que é um mecanismo adicional de segurança para VOCÊ.
              </p>

              <p>
                <strong>
                  NO CASO DE URGÊNCIA OU EMERGÊNCIA PROCURE O ATENDIMENTO
                  PRESENCIAL, O PRONTO ATENDIMENTO OU O PRONTO SOCORRO MAIS
                  PRÓXIMO DE VOCÊ.
                </strong>
                <strong></strong>
              </p>

              <p>
                <a>
                  <strong>
                    EM CASO DE QUALQUER DANO QUE VOCÊ SOFRER EM DECORRÊNCIA DO
                    SERVIÇO DE SAÚDE PRESTADO A VOCÊ PELO PESTABELECIMENTO OU
                    PELO PROFISSIONAL DE SAÚDE, VOCÊ NÃO PODERÁ BUSCAR
                    INDENIZAÇÃO PERANTE A CORE CONSULTING.
                  </strong>{" "}
                </a>
              </p>

              <p>
                <strong>
                  A CORE CONSULTING NÃO É A PRESTADORA DO SERVIÇO DE SAÚDE. VOCÊ
                  DEVERÁ BUSCAR OS SEUS DIREITOS UNICAMENTE PERANTE O
                  ESTABELECIMENTO DE SAÚDE.
                </strong>
              </p>

              <p>
                <strong>
                  POR MEIO DESTE TERMO VOCÊ ISENTA A CORE CONSULTING DE QUALQUER
                  RESPONSABILIDADE RELACIONADA À PRESTAÇÃO DE SERVIÇO DE SAÚDE.
                </strong>
                <strong></strong>
              </p>

              <p>
                <strong>
                  CONSENTIMENTO É A BASE LEGAL ADOTADA PELA CORE CONSULTING PARA
                  TRATAR SUAS INFORMAÇÕES DE SAÚDE (art. 11, I da LGPD
                </strong>
              </p>

              <p>
                O tratamento de seus dados de saúde pela{" "}
                <strong>Core Consulting</strong> deve ser feito sempre mediante
                o seu consentimento específico e destacado (art. 11, I da LGPD),
                em seu benefício e para a continuidade de sua assistência.
              </p>

              <p>
                Por meio deste Termo <strong>VOCÊ</strong> concede este
                consentimento ao estabelecimento de saúde que estiver realizando
                o seu atendimento por meio da{" "}
                <strong>Plataforma Fluxmed</strong>.
              </p>

              <p>
                <strong>A</strong> <strong>Plataforma Fluxmed</strong> permite
                que <strong>VOCÊ</strong> faça a gestão de seu consentimento.
                Isso significa que <strong>VOCÊ</strong> poderá gerenciar com
                quem e com quais estabelecimentos de saúde <strong>VOCÊ</strong>{" "}
                permite que suas informações de saúde sejam compartilhadas e
                visualizadas.
              </p>

              <p>
                <strong>
                  COMO SUAS INFORMAÇÕES DE SAÚDE SÃO OPERADAS PELA FLUXMED
                </strong>
                <strong></strong>
              </p>

              <p>
                A <strong>Plataforma Fluxmed</strong> opera as suas informações
                de saúde na forma de armazenamento e processamento informações
                clínicas imputados por <strong>VOCÊ </strong>(“
                <strong>usuário-paciente</strong>”), eventualmente, e por
                profissionais de saúde de uma organização de saúde (“
                <strong>usuário-autorizado</strong>”), em seu benefício e para a
                sua assistência.
              </p>

              <ul>
                <li>
                  <strong>Cadastro de dados pessoais de identificação: </strong>
                  para <strong>VOCÊ</strong> se cadastrar à{" "}
                  <strong>Plataforma Fluxmed</strong>, aplicativo ou{" "}
                  <em>web</em>, <strong>VOCÊ</strong> vai precisar inserir os
                  seguintes dados pessoais de identificação para gerar o{" "}
                  <em>loguin</em> e senha de acesso à{" "}
                  <strong>Plataforma Fluxmed</strong>:{" "}
                </li>
              </ul>

              <ol>
                <li>
                  Nome completo
                  <ol>
                    <li>Data de nascimento</li>
                  </ol>
                  <ol>
                    <li>Sexo de nascimento</li>
                  </ol>
                  <ol>
                    <li>Nº de CPF</li>
                  </ol>
                  <ol>
                    <li>E-mail</li>
                  </ol>
                  <ol>
                    <li>Nº do Celular</li>
                  </ol>
                </li>
              </ol>

              <p>
                <strong>VOCÊ</strong> precisa saber que, para assegurar a sua
                identificação e para evitar fraudes, a{" "}
                <strong>Core Consulting </strong>faz a validação de seus dados
                junto à Receita Federal, envia um token de validação por SMS
                para seu número de telefone informado ou um e-mail para poder
                validar seu cadastro. Por isso solicitamos estas informações
                para <strong>VOCÊ</strong>.
              </p>

              <p>
                Não forneça informações imprecisas sobre sua identificação,
                proteja sua senha não fornecendo-a a ninguém, se precisar que
                outras pessoas além de <strong>VOCÊ </strong>tenha acesso a suas
                informações de saúde, utilize os mecanismos de consentimento
                disponíveis nos aplicativos Fluxmed.
              </p>

              <p>
                Após realizado o cadastro <strong>VOCÊ</strong> poderá utilizar
                as funcionalidades da <strong>Plataforma Fluxmed</strong>, fazer
                a gestão de seus dados pessoais e de sua saúde na palma da mão!
                &nbsp;
              </p>

              <p>
                Caso solicitado pelo estabelecimento de saúde, a{" "}
                <strong>Core Consultin</strong>g pode processar suas informações
                de saúde na forma de tabelas, <em>dashboards</em>. Isso permite
                que o estabelecimento de saúde otimize a gestão da saúde
                populacional de seus pacientes.
              </p>

              <p>
                <strong>VOCÊ</strong> precisa saber que a LGPD proíbe a
                realização de tratamento para fins discriminatórios ilícitos ou
                abusivos.
              </p>

              <p>
                <strong>A CORE CONSULTING É O OPERADOR DE DADOS</strong>
              </p>

              <p>
                <strong>Core Consulting</strong> atua como{" "}
                <strong>Operador</strong> de dados porque ela trata os seus
                dados pessoais conforme as finalidades estabelecidas pelo
                estabelecimento de saúde, que é o <strong>Controlador</strong>{" "}
                de dados. &nbsp;&nbsp;&nbsp;&nbsp;
              </p>

              <p>
                Enquanto <strong>VOCÊ</strong> não for atendido por um
                estabelecimento de saúde por meio da{" "}
                <strong>Plataforma Fluxmed</strong> <strong>VOCÊ</strong> poderá
                pedir à <strong>Core Consulting</strong> para eliminar os seus
                dados cadastrais. <strong>VOCÊ</strong> precisa buscar o canal
                de exercício de direitos da LGPD perante o estabelecimento de
                saúde onde <strong>VOCÊ</strong> foi atendido.
              </p>

              <p>
                Se <strong>VOCÊ</strong> tiver alguma dúvida sobre as
                funcionalidades ou tiver algum problema técnico para utilizar a{" "}
                <strong>Plataforma Fluxmed</strong>, <strong>VOCÊ</strong> pode
                pedir ajuda técnica para a <strong>Core Consulting</strong> por
                meio do seguinte e-mail: contato@fluxmed.com.br.
              </p>

              <p>
                Atenção! Não envie à <strong>Core Consulting</strong> nenhuma
                pergunta relacionada à sua saúde. Procure o estabelecimento de
                saúde, o profissional de saúde ou o seu médico assistencial.
              </p>

              <p>
                <strong>TEMPO DE ARMAZENAMENTO</strong>
                <strong></strong>
              </p>

              <p>
                Ao contrário do estabelecimento de saúde, a{" "}
                <strong>Core Consulting</strong> não é obrigada a conservar seus
                dados pessoais. Por isso a plataforma fornece a organização de
                saúde mecanismos para extração de dados por ela controlados.
              </p>

              <p>
                <strong>MEDIDAS DE SEGURANÇA E SUAS RESPONSABILIDADES</strong>
                <strong></strong>
              </p>

              <p>
                A <strong>Core Consulting</strong> encoraja que{" "}
                <strong>VOCÊ</strong> leia este Termo com calma e atenção antes
                mesmo de realizar o cadastro na{" "}
                <strong>Plataforma Fluxmed</strong>. &nbsp;
              </p>

              <p>
                Se <strong>VOCÊ</strong> tiver qualquer dúvida relacionada ao
                presente Termo <strong>VOCÊ</strong> pode enviar um e-mail para:{" "}
                <a href="mailto:contato@fluxmed.com.br">
                  contato@fluxmed.com.br
                </a>
                .
              </p>

              <p>
                Realizado o cadastro, <strong>VOCÊ</strong> declara e concorda
                que seus dados de <em>login</em> e senha para acessar a{" "}
                <strong>Plataforma Fluxmed</strong> são pessoais, e pertencem a{" "}
                <strong>VOCÊ</strong>. <strong>VOCÊ</strong>{" "}
                <strong>não</strong> pode revelar ou transferir o seu{" "}
                <em>login</em> e senha para ninguém.
              </p>

              <p>
                <strong>VOCÊ</strong> é o responsável pelos seus dados pessoais
                de cadastro, por mais que sejam validados pela{" "}
                <strong>Core Consulting</strong>. Isso significa que a{" "}
                <strong>Core Consulting</strong> não será responsável por
                garantir que <strong>VOCÊ</strong> é a pessoa que cadastrou{" "}
                <strong>VOCÊ</strong> à <strong>Plataforma Fluxmed</strong>.
              </p>

              <p id="Termo-de-uso-e-declaração-de-confidencialidade">
                A <strong>Core Consulting</strong> tampouco será responsável
                pela segurança de seus dados pessoais compartilhados por{" "}
                <strong>VOCÊ</strong> em outros ambientes como e-mail,{" "}
                <em>WhatsApp</em> ou qualquer outros sistemas similares de
                mensageria.
              </p>

              <p>
                Todos os acessos à <strong>Plataforma Fluxmed</strong> são
                rastreáveis, não podem ser alterados e podem ser visualizados
                por <strong>VOCÊ</strong>.
              </p>

              <p>
                <strong>
                  INSTRUÇÕES FINAIS PARA O BOM USO DO APLICATIVO E LIMITES
                </strong>
              </p>

              <p>
                Ao utilizar a <strong>Plataforma Fluxmed</strong>{" "}
                <strong>VOCÊ</strong> reconhece e concorda que a disponibilidade
                e o funcionamento da <strong>Plataforma Fluxmed</strong>, como
                qualquer tecnologia de informação e comunicação, estão sujeitos
                a interrupções devido a fatores que estão fora do controle da{" "}
                <strong>Core Consulting</strong>, incluindo, mas não se
                limitando a:
              </p>

              <ul>
                <li>indisponibilidade de energia elétrica,</li>

                <li>
                  falhas no sinal de internet e velocidade inadequada de
                  conexão,
                </li>

                <li>manutenção programada,</li>

                <li>falhas de software em reparação,</li>

                <li>desastres ambientais.</li>
              </ul>

              <p>
                Em casos de interrupções devido a manutenções programadas,
                falhas de software ou qualquer outro fator que afete a
                disponibilidade da <strong>Plataforma Fluxmed</strong>,{" "}
                <strong>VOCÊ</strong> pode tomar as seguintes ações:
              </p>

              <p>
                <strong>Verificar Comunicados</strong>: Pode ser que exista
                alguma notificação indicando a indisponibilidade.
              </p>

              <p>
                <strong>Reportar Problemas</strong>: Se encontrar um problema
                não comunicado previamente, é útil reportá-lo à equipe de
                suporte técnico da <strong>Plataforma Fluxmed</strong> por meio
                do e-mail:{" "}
                <a href="mailto:contato@fluxmed.com.br">
                  contato@fluxmed.com.br
                </a>
                .
              </p>

              <p>
                <strong>Revisar este Termo</strong>: recomendamos que{" "}
                <strong>VOCÊ</strong> revise este Termo sempre que quiser ou
                quando for necessário.
              </p>

              <p>E, claro:</p>

              <p>
                <strong>
                  NO CASO DE URGÊNCIA OU EMERGÊNCIA PROCURE O ATENDIMENTO
                  PRESENCIAL, O PRONTO ATENDIMENTO OU O PRONTO SOCORRO MAIS
                  PRÓXIMO A VOCÊ.
                </strong>
                <a></a>
              </p>

              <p></p>

              <p></p>
            </div>
          </main>
        </Box>
      </Box>
      <FormGroup
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <FormControlLabel
          control={
            <Checkbox
              // disabled={!scrollComplete}
              color="primary"
              onChange={() => setCheckbox(!checkbox)}
            />
          }
          label={
            <Typography variant="body1" display="flex" flexDirection="row">
              Eu li e estou de acordo com os &nbsp;
              <Typography variant="body1" color="primary700.main">
                Termos de Uso &nbsp;
              </Typography>
              e &nbsp;
              <Typography variant="body1" color="primary700.main">
                Política de Privacidade. &nbsp;
              </Typography>
            </Typography>
          }
        />
      </FormGroup>
      <FormButtonGroup
        onNextButtonClick={handleSubmit(onSubmit)}
        onGoBackButtonClick={() => navigate("/signin")}
        loading={loading}
        nextButtonText="Aceitar"
        goBackButtonText="Recusar"
        disabled={checkbox === false}
      />
    </Stack>
  );
}
