import { Box, DialogTitle, IconButton, Stack, Tab } from "@mui/material";
import { CommonDialog } from "../../../components/common/CommonDialog";
import { useState } from "react";
import { useContractContext } from "../../../contexts/ContractContext";

import { Close } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { PaymentPanel } from "./PaymentPanel";
import { ContractPanel } from "./ContractPanel";

interface IProps {
  open: boolean;
  handleClose: VoidFunction;
}

export function DetailsPlanDialog({ open, handleClose }: IProps) {
  const { setRefetchInactiveContracts } = useContractContext();
  const [tab, setTab] = useState("1");

  const handleChange = (event: React.SyntheticEvent, tabValue: string) => {
    if (tabValue === "2") setRefetchInactiveContracts((prev: boolean) => !prev);
    setTab(tabValue);
  };

  return (
    <CommonDialog isOpen={open} handleClose={handleClose}>
      <DialogTitle align="center" sx={{ padding: 0, marginBottom: 2 }}>
        Detalhes do plano de assinatura
      </DialogTitle>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Pagamentos" value="1" />
            <Tab label="Contratos" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: "16px 0px" }}>
          <Stack width="100%" height="100%">
            <PaymentPanel />
          </Stack>
        </TabPanel>
        <TabPanel value="2" sx={{ p: "16px 0px" }}>
          <ContractPanel />
        </TabPanel>
      </TabContext>
    </CommonDialog>
  );
}
