import { Grid, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { NoDataWarning } from "../../../components/common";
import { GenericTable } from "../../../components/GenericTable/GenericTable";
import { useContractContext } from "../../../contexts/ContractContext";

export function ContractPanel() {
  const { currentContract, inactiveContracts } = useContractContext();
  const contractInformation = {
    typeContract: currentContract?.[0]?.resource?.term?.[0]?.text,
    start: currentContract?.[0]?.resource?.term?.[0]?.applies?.start,
    end: currentContract?.[0]?.resource?.extension?.[1]?.valueDate,
  };

  const columnsContract = [
    { column: "Data de cancelamento", rowName: "cancelledDate" },
    { column: "Plano contratado", rowName: "planType" },
  ];

  const rowsContract = inactiveContracts
    ?.sort((a: any, b: any) => {
      const dateA = dayjs(a?.resource?.term?.[0]?.applies?.end);
      const dateB = dayjs(b?.resource?.term?.[0]?.applies?.end);
      return dateB.diff(dateA);
    })
    .map((el: any) => {
      const date = el?.resource?.term?.[0]?.applies?.end;
      return {
        cancelledDate: date ? dayjs(date).format("DD/MM/YYYY") : "-",
        planType: el?.resource?.term?.[0]?.text || "-",
      };
    });

  return (
    <>
      <Grid container boxShadow="0 0 5px lightgrey" p={2} mb={2}>
        <Grid item xs={12}>
          <Typography
            textAlign="center"
            variant="subtitle2"
            fontWeight={500}
            color="neutral700.main"
            mb={2}
          >
            Contrato ativo
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={600} color="neutral700.main">
            Serviço
            <Typography variant="body1" fontWeight={400}>
              {contractInformation?.typeContract || "-"}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={600} color="neutral700.main">
            Início
            <Typography variant="body1" fontWeight={400}>
              {contractInformation?.start
                ? dayjs(contractInformation?.start).format("DD/MM/YYYY")
                : "-"}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={600} color="neutral700.main">
            Próximo pagamento
            <Typography variant="body1" fontWeight={400}>
              {contractInformation?.end
                ? dayjs(contractInformation?.end).format("DD/MM/YYYY")
                : "-"}
            </Typography>
          </Typography>
        </Grid>
      </Grid>

      {inactiveContracts?.length ? (
        <Stack spacing={2}>
          <Stack boxShadow="0 0 5px lightgrey">
            <Typography
              textAlign="center"
              variant="subtitle2"
              fontWeight={500}
              color="neutral700.main"
              pt={2}
            >
              Contratos cancelados
            </Typography>
            <GenericTable
              tableColumns={columnsContract}
              tableRows={rowsContract}
            />
          </Stack>
        </Stack>
      ) : (
        <NoDataWarning />
      )}
    </>
  );
}
