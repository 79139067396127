import { Skeleton, Stack, Typography } from "@mui/material";
import { ScreenMobile } from "../../components/common/ScreenMobile";
import { HealthMedicineDetails } from "../home/components/HealthMedicineDetails";
import { NoDataWarning } from "../../components/common";

interface IProps {
  title: string;
  auxText: string;
  open: boolean;
  onClose: VoidFunction;
  data: any;
  setRefetch?: any;
  loading?: boolean;
}

export function AuxiliarControlDosage({
  loading,
  title,
  auxText,
  open,
  onClose,
  data,
  setRefetch,
}: IProps) {
  return (
    <ScreenMobile title={title} open={open} onClose={onClose}>
      <Stack width="100%" height="100%" maxHeight="75vh" mb={4} overflow="auto">
        <Typography
          variant="body2"
          fontWeight={600}
          color="neutral700.main"
          margin="16px 0px"
        >
          {auxText}
        </Typography>

        {loading ? (
          <Stack width="100%" height="100%" mt={2}>
            <Skeleton variant="rectangular" width="100%" height="90%" />
          </Stack>
        ) : data?.length ? (
          data?.map((el: any) => (
            <HealthMedicineDetails
              fullData={el.fullResource}
              type={el.type}
              name={el.name}
              result={el.result}
              date={el.date}
              setRefetch={setRefetch}
            />
          ))
        ) : (
          <Stack width="100%" mt={2}>
            <NoDataWarning />
          </Stack>
        )}
      </Stack>
    </ScreenMobile>
  );
}
