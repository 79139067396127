import { Skeleton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { GenericTable } from "../../../components/GenericTable/GenericTable";
import { NoDataWarning } from "../../../components/common";
import dayjs from "dayjs";
import { GetPaymentNotice } from "../../../services/fhir/financial/GetPaymentNotice";
import { useContractContext } from "../../../contexts/ContractContext";

export function PaymentPanel() {
  const { currentContract } = useContractContext();
  const [paymentResponse, setPaymentResponse] = useState<any>();

  const [loading, setLoading] = useState<boolean>(false);
  const columnsPayment = [
    { column: "Data", rowName: "paymentDate" },
    { column: "Valor", rowName: "amount" },
  ];
  const rowsPayment = paymentResponse
    ?.sort((a: any, b: any) => {
      const dateA = dayjs(a?.resource?.paymentDate);
      const dateB = dayjs(b?.resource?.paymentDate);
      return dateB.diff(dateA);
    })
    .map((el: any) => {
      const payment = el?.resource?.paymentDate;
      return {
        paymentDate: payment
          ? dayjs(el?.resource?.paymentDate).format("DD/MM/YYYY")
          : "-",
        amount:
          el?.resource?.amount?.value.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }) || "-",
      };
    });

  useEffect(() => {
    try {
      if (currentContract) {
        const detailsPlan = async () => {
          setLoading(true);
          try {
            const response = await GetPaymentNotice({
              contractId: currentContract?.[0]?.resource?.id,
              status: "active",
            });
            if (response?.entry) {
              setPaymentResponse(response.entry);
            } else {
              setPaymentResponse([]);
            }
          } catch (error) {
            console.error("Error fetching payment notice:", error);
            setPaymentResponse([]);
          } finally {
            setLoading(false);
          }
        };
        detailsPlan();
      }
    } catch (err) {
      console.log("err", err);
    }
  }, [currentContract]);

  return loading ? (
    <Skeleton width="100%" height="200px" sx={{ marginTop: -2 }} />
  ) : paymentResponse?.length ? (
    <Stack boxShadow="0 0 5px lightgrey">
      <GenericTable tableColumns={columnsPayment} tableRows={rowsPayment} />
    </Stack>
  ) : (
    <NoDataWarning />
  );
}
