import { Add, Edit } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { MedicationForm } from "../MedicationForm";
import { useInterventionsContext } from "../../../../contexts/InterventionsContext";
import { toast } from "react-toastify";
import { SyntheticEvent } from "react";

export function PrescriptionCare({ setSelect }: any) {
  const {
    control,
    medicationOptions,
    action,
    setValue,
    handleSubmit,
    setClean,
    clean,
    isEdit,
    setMedicationTabValue,
    medicationTabValue,
  } = useInterventionsContext();

  const onSubmit = async (data: any) => {
    try {
      if (!data.medication) {
        toast.warn("Selecione algum item pra efetuar a prescrição");
        throw new Error("Formulário vazio");
      } else {
        const auxForm = [
          data?.quantity && `${data?.quantity}`,
          data?.form?.label && `${data?.form?.label}`,
          data?.frequency?.label && `a cada ${data?.frequency?.label}`,
          data?.time?.label && `por ${data?.time?.label}`,
        ]
          ?.filter((e: string) => e)
          ?.join(" ");
        const auxItem = {
          name: data?.medication?.name,
          label: data?.medication?.label,
          tag: data?.medication?.tag,
          inputValue: medicationOptions.find(
            (e: any) => e.value === data.medication.value
          ) || {
            name: data.medication.name,
            value: data.medication.name,
            label: data.medication.name,
          },
          quantity: data?.quantity,
          frequency: data?.frequency,
          formValue: data?.form,
          time: data?.time,
          note: data?.note,
          form: auxForm,
          type: "medication",
          code: data?.medication?.code,
          tabValue: `${medicationTabValue}`,
        };

        action(auxItem);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setClean(true);
    }
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setMedicationTabValue(newValue);
  };

  return (
    <Stack width="100%" maxHeight="65vh" overflow="auto">
      <Button
        sx={{ width: "fit-content", textTransform: "none" }}
        startIcon={<Add />}
        onClick={() => setSelect(true)}
      >
        Selecionar protocolo salvo
      </Button>
      <MedicationForm
        tabValue={medicationTabValue}
        handleChange={handleChange}
        render={!clean}
        control={control}
        setValue={setValue}
      />

      <Stack width="100%" direction="row" mt={2} mb={2} justifyContent="right">
        <Button
          sx={{ width: "fit-content", border: "1px solid #007C6D" }}
          onClick={handleSubmit(onSubmit)}
          variant="text"
        >
          {isEdit ? <Edit /> : <Add />}
        </Button>
      </Stack>
    </Stack>
  );
}
