import { History } from "@mui/icons-material";
import { useSessionContext } from "../../contexts/SessionContext";
import { IFeatures } from "../../utils/interfaces";
import { AccessHistory } from "./AccessHistory";
import { currentFeatures } from "../../configs/Settings";

export function useAccessHistory() {
  const { access } = useSessionContext();

  const configs: IFeatures = {
    active: access?.type === "patient" && !currentFeatures.register,
    path: "/history",
    icon: History,
    text: "Histórico de acessos",

    sideMenu: false,

    avatarMenu: true,
    avatarIndex: 2,

    component: () => <AccessHistory />,
  };

  return configs;
}
