import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import { useValidate } from "../../../utils/useValidate";
import { OAuthApplication } from "../../auth";

export async function ProfileSearch(authType?: string, credential?: string) {
  const url = `${endpoints.Scim2}/Users/.search`;
  const username =
    authType === "application" ? credential : localStorage.getItem("username");
  const data = {
    schemas: ["urn:ietf:params:scim:api:messages:2.0:SearchRequest"],
    filter: `${useValidate.signinCredentialType(`${username}`)} Eq ${username}`,
    domain: "PRIMARY",
    startIndex: 1,
    count: 10,
  };

  const bearerToken =
    authType === "application"
      ? await OAuthApplication()
      : await useAuth.getUserToken();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: bearerToken,
  };

  const response = await useAxios
    .post(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`ProfileSearch error: ${response.status}`);
      }

      return response.data;
    })
    .catch((error) => {
      console.error("ProfileSearch ", error);
      // toast.error(`ProfileSearch Erro: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
