import { Text, View, StyleSheet } from "@react-pdf/renderer";
import dayjs from "dayjs";
import { useFhirData } from "../../../utils/useFhirData";

const styles = StyleSheet.create({
  section: {
    padding: 40,
    flexGrow: 1,
  },
  headerMedicalCertificate: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    marginBottom: 10,
  },
  titleMedicalCertificate: {
    fontSize: 22,
    width: "100%",
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
    textTransform: "uppercase",
  },
  text: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
    paddingVertical: 5,
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  textHeader: {
    fontSize: 12,
    fontFamily: "Helvetica",
    textTransform: "lowercase",
  },
  textResponse: {
    fontSize: 12,
    fontFamily: "Helvetica",
  },
  tranformUppercase: {
    textTransform: "uppercase",
  },
  textBold: {
    fontSize: 8,
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
  },
  textAuxiliar: {
    fontSize: 6,
    fontFamily: "Helvetica",
    textTransform: "lowercase",
  },
  contentContainer: {
    marginBottom: 20,
    flexGrow: 1,
  },
  observationsContainer: {
    backgroundColor: "#e6e6e6",
    padding: 10,
    marginBottom: 20,
  },
  footerMedicalCertificate: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 10,
    borderTop: "1px solid #000",
    marginTop: "auto", // Move the footer to the bottom of the page
  },
  pageBreak: {
    marginTop: 40,
    pageBreakBefore: "always",
  },
  newPageTopMargin: {
    marginTop: 30, // Add margin to the top of new pages
  },
  marginTopSecondPage: {
    marginTop: 40, // Add a larger margin for the second page and subsequent pages
  },
});

export function MedicalCertificate({ filterMedicalCertificate, dataPdf }: any) {
  const crm = dataPdf?.[0]?.professionalData?.identifier?.[0]?.value
    .split("/")[1]
    .slice(2);
  const ufCrm = dataPdf?.[0]?.professionalData?.identifier?.[0]?.value
    .split("/")[1]
    .slice(0, 2);

  const splitObservationsIntoPages = (observations: any[]) => {
    const pages = [];
    const pageSize = 8;

    for (let i = 0; i < observations.length; i += pageSize) {
      pages.push(observations.slice(i, i + pageSize));
    }

    return pages;
  };

  const observationPages = splitObservationsIntoPages(filterMedicalCertificate);

  const renderObservations = (observations: any[]) => (
    <View style={{ height: "auto", width: "100%" }}>
      {observations.map((el: any, index: number) => (
        <View key={index} style={{ marginBottom: 10 }}>
          <Text style={styles.textResponse}>{el.note || ""}</Text>
        </View>
      ))}
    </View>
  );

  return (
    <View style={styles.section}>
      {filterMedicalCertificate.length > 0 && (
        <>
          <View style={styles.headerMedicalCertificate}>
            <Text style={styles.titleMedicalCertificate}>RELATÓRIO</Text>
          </View>

          {observationPages.map((pageObservations, index) => (
            <View
              key={index}
              break={index > 0}
              style={[
                index > 0 ? styles.marginTopSecondPage : {},
                { flexGrow: 1 }, // Ensure content grows to fill page
              ]}
            >
              <View style={styles.contentContainer}>
                <Text style={[styles.textHeader, styles.tranformUppercase]}>
                  Confirmo que o(a) Sr.(a){" "}
                  <Text style={[styles.tranformUppercase, styles.bold]}>
                    {dataPdf?.[0]?.patientData || ""}
                  </Text>
                  , encontra-se sob meus cuidados profissionais.
                </Text>
                <Text style={styles.text}>Observações: </Text>
                <View style={styles.observationsContainer}>
                  {renderObservations(pageObservations)}
                </View>
              </View>

              {index === observationPages.length - 1 && (
                <View style={styles.footerMedicalCertificate}>
                  <Text style={styles.text}>
                    NOME DO(A) MÉDICO(A):{" "}
                    <Text style={styles.textResponse}>
                      {dataPdf?.[0]?.professionalData?.practitioner.display ||
                        ""}
                    </Text>
                  </Text>
                  <Text style={styles.text}>
                    CRM: <Text style={styles.textResponse}>{crm || ""}</Text>
                  </Text>
                  <Text style={styles.text}>
                    UF: <Text style={styles.textResponse}>{ufCrm || ""}</Text>
                  </Text>
                </View>
              )}
              {index === observationPages.length - 1 && (
                <>
                  <Text style={styles.text}>
                    LOCAL DE ATENDIMENTO:
                    <Text style={styles.textResponse}>
                      {dataPdf?.[0]?.location?.alias?.[0] || "-"}
                    </Text>
                  </Text>
                  <Text style={styles.text}>
                    ENDEREÇO:
                    <Text style={styles.textResponse}>
                      {useFhirData.formatAddress(dataPdf?.[0].location)}
                    </Text>
                  </Text>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "40px",
                    }}
                  >
                    <Text style={styles.text}>
                      CIDADE:{" "}
                      <Text style={styles.textResponse}>
                        {dataPdf?.[0]?.location?.address.city || "-"}
                      </Text>
                    </Text>
                    <Text style={styles.text}>
                      UF:{" "}
                      <Text style={styles.textResponse}>
                        {dataPdf?.[0]?.location?.address.state || "-"}
                      </Text>
                    </Text>
                  </View>
                  <Text style={styles.text}>
                    DATA DE EMISSÃO:{" "}
                    <Text style={styles.textResponse}>
                      {dayjs().format("DD/MM/YYYY") || "-"}
                    </Text>
                  </Text>
                </>
              )}
            </View>
          ))}
        </>
      )}
    </View>
  );
}
