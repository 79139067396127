import { Text, View, StyleSheet } from "@react-pdf/renderer";
import dayjs from "dayjs";
import { useFhirData } from "../../../utils/useFhirData";

const styles = StyleSheet.create({
  sectionProcedure: {
    padding: 40,
    flexDirection: "column",
    flexGrow: 1,
  },
  headerProcedure: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    height: 80,
    textTransform: "uppercase",
    marginBottom: 20,
  },
  contentProcedure: {
    flexGrow: 1,
    marginBottom: 0,
  },
  footerProcedure: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: 6,
  },
  titleProcedure: {
    fontSize: 22,
    width: 320,
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
    textTransform: "uppercase",
  },
  text: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
    padding: "5px 0px",
  },
  textResponse: {
    fontSize: 12,
    fontFamily: "Helvetica",
    textTransform: "uppercase",
  },
  box: {
    marginBottom: 10,
  },
  footerContainer: {
    marginTop: 6,
  },
  marginTopSecondPage: {
    marginTop: 40,
  },
  marginTopSubsequentPages: {
    marginTop: 30,
  },
  pageContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  footerSpacing: {
    height: 50,
  },
});

export function ExamSolicitation({ filterExam, dataPdf }: any) {
  const crm = dataPdf?.[0]?.professionalData?.identifier?.[0]?.value
    .split("/")[1]
    .slice(2);

  const ufCrm = dataPdf?.[0]?.professionalData?.identifier?.[0]?.value
    .split("/")[1]
    .slice(0, 2);

  const splitExamsIntoPages = (exams: any[]) => {
    const pages = [];
    for (let i = 0; i < exams.length; i += 9) {
      if ((i === 0 && exams.length === 8) || exams.length === 9) {
        pages.push(exams.slice(i, i + 8));
        pages.push(exams.slice(i + 8));
      } else {
        pages.push(exams.slice(i, i + 9));
      }
    }
    return pages;
  };

  const examPages = splitExamsIntoPages(filterExam);

  const renderExams = (exams: any[]) => (
    <View style={{ height: "auto", width: "100%", backgroundColor: "#e6e6e6" }}>
      {exams.map((el: any, index: number) => (
        <View key={index} style={styles.box} wrap={false}>
          <Text style={styles.text}>
            Tipo de Exame:
            <Text style={styles.textResponse}> {el.name} </Text>
          </Text>
          {el.note && (
            <Text style={styles.text}>
              Obs:
              <Text style={styles.textResponse}> {el.note} </Text>
            </Text>
          )}
        </View>
      ))}
    </View>
  );

  const renderFooter = () => (
    <View style={styles.footerContainer}>
      <View style={styles.footerProcedure}>
        <Text style={styles.text}>
          Nome do(a) Médico(a):
          <Text style={styles.textResponse}>
            {dataPdf?.[0]?.professionalData?.practitioner.display || ""}
          </Text>
        </Text>
        <View style={{ display: "flex", flexDirection: "row", gap: 3 }}>
          <Text style={styles.text}>
            CRM: <Text style={styles.textResponse}>{crm || ""}</Text>
          </Text>
          <Text style={styles.text}>
            UF: <Text style={styles.textResponse}>{ufCrm || ""}</Text>
          </Text>
        </View>
      </View>

      <View style={styles.footerProcedure}>
        <Text style={styles.text}>
          Local de Atendimento:
          <Text style={styles.textResponse}>
            {dataPdf?.[0]?.location?.alias?.[0] || "-"}
          </Text>
        </Text>
        <Text style={styles.text}>
          Endereço:
          <Text style={styles.textResponse}>
            {useFhirData.formatAddress(dataPdf?.[0].location)}
          </Text>
        </Text>
        <Text style={styles.text}>
          Data de Emissão:
          <Text style={styles.textResponse}>
            {dayjs().format("DD/MM/YYYY")}
          </Text>
        </Text>
      </View>
    </View>
  );

  return (
    <View style={styles.sectionProcedure}>
      <View style={styles.headerProcedure}>
        <Text style={styles.titleProcedure}>Solicitação de Exame</Text>
      </View>

      {examPages.map((pageExams, index) => (
        <View
          key={index}
          break={index > 0}
          style={[
            styles.pageContainer,
            index > 0 ? styles.marginTopSubsequentPages : {},
          ]}
        >
          <View style={styles.contentProcedure}>
            {index === 0 && (
              <>
                <Text style={styles.text}>
                  Paciente:
                  <Text style={styles.textResponse}>
                    {dataPdf?.[0]?.patientData || ""}
                  </Text>
                </Text>
                <Text style={styles.text}>
                  Indicação Clínica:
                  {dataPdf?.[4]
                    ?.filter((el: any) => el.type === "service")
                    .map((el: any, index: number) => (
                      <Text key={index} style={styles.textResponse}>
                        {index > 0 && "\n"}
                        {el?.condition?.label} - {el?.condition?.value}
                      </Text>
                    ))}
                </Text>
                <Text style={styles.text}>Prescrição:</Text>
              </>
            )}
            {renderExams(pageExams)}
          </View>

          {index === examPages.length - 1 && renderFooter()}
        </View>
      ))}
    </View>
  );
}
