import { Stack } from "@mui/material";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";

import { TabsAtendimento } from "../TabsAtendimento";
import { TabsIntervenções } from "../TabsIntervenções";
import { TabsAssinatura } from "../TabsAssinatura";
import { EHRRecordsDialog } from "../../../ClinicalRegister/components/EHRRecordsDialog";
import { useLocation } from "react-router-dom";

export function RegisterArea() {
  const { activeStep, openSummary, setOpenSummary } = useMedicalCareContext();
  const handleCloseSummary = () => {
    setOpenSummary(false);
  };

  const { state } = useLocation();

  return (
    <Stack sx={{ width: "100%", height: "65vh" }}>
      {activeStep === 0 && <TabsAtendimento />}
      {activeStep === 1 && <TabsIntervenções />}
      {activeStep === 2 && (
        <TabsAssinatura
          patientName={state?.patientName}
        />
      )}

      {state?.encounterReference ? (
        <>
          <EHRRecordsDialog
            open={openSummary}
            handleClose={handleCloseSummary}
            references={state?.encounterReference}
          />
        </>
      ) : (
        <></>
      )}
    </Stack>
  );
}
