import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { GetBinary } from "../../../services/fhir/patientsummary/Binary/GetBinary";
import { CircularLoading } from "../../../components/common";
import { isMobile } from "react-device-detect";
import { Close } from "@mui/icons-material";

interface IProps {
  handleClose: any;
  isOpen: any;
  data: any;
  title?: string;
  loadedBase64?: any;
  setLoaded?: any;
}

export function IframeDialog({
  handleClose,
  isOpen,
  data,
  title,
  loadedBase64,
  setLoaded,
}: IProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [base64, setBase64] = useState<any>();

  useEffect(() => {
    async function getDocumentPdf() {
      const response = await GetBinary({ urlBinary: data });
      setBase64(response);
      if (setLoaded) {
        setLoaded(response);
      }
    }
    if (!loadedBase64) {
      getDocumentPdf();
    }
    setLoading(false);
  }, [data]);

  const handleDialogClose = (event: any, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      handleClose(event, reason);
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "10px",
          padding: 2,
          maxWidth: "850px",
          height: "900px",
        },
      }}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      onClose={handleDialogClose}
      open={isOpen}
    >
      <IconButton
        onClick={() => handleClose(null, "iconButtonClick")}
        sx={{ position: "absolute", right: 5, top: 5 }}
      >
        <Close />
      </IconButton>
      <DialogTitle textAlign="left">{title ? title : "Documento"}</DialogTitle>
      <DialogContent>
        {(base64?.data && !loading) || loadedBase64?.data ? (
          <iframe
            title="PDF Viewer"
            src={`data:${
              base64?.contentType || loadedBase64?.contentType
            };base64,${base64?.data || loadedBase64?.data}#toolbar=0`}
            width="100%"
            height="700px"
          />
        ) : (
          <CircularLoading />
        )}
      </DialogContent>
    </Dialog>
  );
}
