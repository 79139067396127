import { useState } from "react";
import { useForm } from "react-hook-form";
import { SimpleSearch } from "./components/SimpleSearch";
import { AdvancedSearch } from "./components/AdvancedSearch";
import { SearchControls } from "./components/SearchControls";
import { SearchResults } from "./components/SearchResults";
import { CircularLoading } from "../../common";
import { Stack } from "@mui/material";

export function SearchPatient() {
  const methods = useForm();
  const { control, handleSubmit, reset } = methods;
  const [checkbox, setCheckbox] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const [result, setResult] = useState();

  const handleCheck = () => {
    setCheckbox((prev) => !prev);
    reset();
  };
  return (
    <>
      {!checkbox ? (
        <SimpleSearch
          control={control}
          handleSubmit={handleSubmit}
          setResult={setResult}
          setLoading={setLoading}
        />
      ) : (
        <AdvancedSearch
          control={control}
          handleSubmit={handleSubmit}
          setResult={setResult}
          setLoading={setLoading}
        />
      )}
      <SearchControls handleCheck={handleCheck} />
      {loading && (
        <Stack>
          <CircularLoading />{" "}
        </Stack>
      )}
      {result && !loading && <SearchResults data={result} />}
    </>
  );
}
