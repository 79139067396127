import { useState } from "react";
import { CommonDialog } from "../../../components/common/CommonDialog";
import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import {
  FormButtonGroup,
  FormMultipleAutoComplete,
} from "../../../components/form";
import { useForm } from "react-hook-form";
import { PatchPractitionerRole } from "../../../services/fhir";
import { auxiliarData } from "../../../services/fhir/AuxiliarData";
import { toast } from "react-toastify";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";
import { useSessionContext } from "../../../contexts/SessionContext";

interface IProps {
  isOpen: boolean;
  handleClose: Function;
  row: any;
  refetchPractitionerRole: any;
}

function translateRoles(roles: any) {
  const translationMap: any = {
    owner: "CEO",
    manager: "Gerente",
    practitioner: "Profissional",
  };

  return roles.map((role: any) => {
    return { label: translationMap[role], value: role };
  });
}

export function ManagePractitionersDialog({
  isOpen,
  handleClose,
  row,
  refetchPractitionerRole,
}: IProps) {
  const { user } = useSessionContext();
  const { organization } = useOrganizationContext();
  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm<{ role: any }>({
    defaultValues: { role: translateRoles(row?.roles) },
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty },
  } = methods;

  const watchPaper = watch("role");

  const auxPaper = watchPaper.map((el: any) => el);

  const onSubmit = async (data: { role: any }) => {
    try {
      setLoading(true);

      const rolesSelected = auxPaper.map((el: any) => el.value);
      let orderDict: { [key: string]: number } = {
        owner: 0,
        manager: 1,
        practitioner: 2,
      };
      let sortedList = rolesSelected.sort(
        (a: number, b: number) => orderDict[a] - orderDict[b]
      );

      const patchBody = auxiliarData.PatchPractitionerRole(rolesSelected);
      const cnpj = organization?.id?.split("-")[1];

      await PatchPractitionerRole({
        practitionerId: `${cnpj}-${row?.practitionerId}`,
        operation: "replace",
        path: "/code",
        content: patchBody,
      });

      if (sortedList[0] === "owner" && rolesSelected.length === 1) {
        await PatchPractitionerRole({
          practitionerId: `${cnpj}-${row?.practitionerId}`,
          operation: "remove",
          path: "/specialty",
        });
      }

      refetchPractitionerRole();
      toast.success("Função atualizada com sucesso!");
      if (row?.practitionerId.includes(user.username)) window.location.reload();
      handleClose();
    } catch (err) {
      console.log("erro:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CommonDialog handleClose={handleClose} isOpen={isOpen}>
      <DialogTitle textAlign="center">Alterar função</DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          color="neutral700.main"
          textAlign="center"
          mb={2}
        >
          {row?.practitionerName}
        </Typography>
        <FormMultipleAutoComplete
          variant="filled"
          control={control}
          // defaultValue={row?.paper}
          label="Função"
          name="role"
          selectOptions={[
            { label: "CEO", value: "owner" },
            { label: "Gerente", value: "manager" },
            { label: "Profissional", value: "practitioner" },
          ].sort((el: any, val: any) => (el.label < val.label ? 1 : -1))}
        />

        <Stack
          width="100%"
          direction="column"
          justifyContent="space-between"
          mt={3}
        >
          <FormButtonGroup
            onGoBackButtonClick={handleClose}
            onNextButtonClick={handleSubmit(onSubmit)}
            goBackButtonText="Cancelar"
            nextButtonText="Confirmar"
            disabled={!isDirty || !watchPaper.length}
            loading={loading}
          />
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
