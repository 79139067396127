import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Stack,
  Typography,
} from "@mui/material";
import { GenericTable } from "../../../../components/GenericTable/GenericTable";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

const type: { [key: string]: string } = {
  medication: "Medicamento prescrito",
  statement: "Medicação informada pelo paciente",
  prescription: "Item da prescrição",
};

export function InterdrugsDialog({
  isOpen,
  handleClose,
  response,
  itens,
}: any) {
  const medication = itens?.[0];
  const prescriptions = itens?.[1];

  const columns = [
    { column: "Medicamento", rowName: "medication" },
    { column: "Princípio ativo", rowName: "substance" },
    { column: "Tipo", rowName: "type" },
  ];

  const rows = [...(medication || []), ...(prescriptions || [])]?.map(
    (el: any) => {
      return {
        medication: el?.name,
        substance: el?.substance || "",
        type: el?.type,
      };
    }
  );

  const verifyItens = [...(medication || []), ...(prescriptions || [])]?.map(
    (el: any) => el?.substance?.toLowerCase() || el?.name?.toLowerCase()
  );

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: { borderRadius: "5px", padding: 4 },
      }}
      maxWidth="lg"
      onClose={(event, reason) => handleClose()}
    >
      <Stack minWidth="750px" spacing={2}>
        <Stack direction="row" spacing={1}>
          <Typography variant="body1" color="neutral1000.main" fontWeight={500}>
            Termos verficados:
          </Typography>
          <Typography variant="body1" color="neutral700.main">
            {verifyItens?.join(" , ")}
          </Typography>
        </Stack>

        <Stack>
          <Accordion
            sx={{
              border: "0.2px solid gray",
              boxShadow: "none",
              position: "initial",
            }}
          >
            <AccordionSummary
              expandIcon={<GridExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              Lista de medicamentos ativos
            </AccordionSummary>
            <AccordionDetails>
              <GenericTable tableColumns={columns} tableRows={rows} />
            </AccordionDetails>
          </Accordion>
        </Stack>

        <Stack spacing={1}>
          <Typography variant="body1" color="neutral1000.main" fontWeight={500}>
            Interações encontradas:
          </Typography>

          {response?.items?.map((interaction: any, index: number) => {
            return (
              <Stack
                key={index}
                p={1}
                gap={1}
                sx={{
                  background: "#F1F3F9",
                  borderRadius: "5px",
                  width: "100%",
                }}
              >
                {interaction?.medicamentos && (
                  <Stack direction="row" spacing={1}>
                    <Typography
                      variant="body1"
                      color="neutral1000.main"
                      fontWeight={500}
                    >
                      Substancias:
                    </Typography>
                    <Typography variant="body1" color="neutral700.main">
                      {interaction?.medicamentos?.join(" - ")}
                    </Typography>
                  </Stack>
                )}
                {interaction?.nome && (
                  <Stack direction="row" spacing={1}>
                    <Typography
                      variant="body1"
                      color="neutral1000.main"
                      fontWeight={500}
                    >
                      Descrição:
                    </Typography>
                    <Typography variant="body1" color="neutral700.main">
                      {interaction?.nome}
                    </Typography>
                  </Stack>
                )}
                {interaction?.gravidade && (
                  <Stack direction="row" spacing={1}>
                    <Typography
                      variant="body1"
                      color="neutral1000.main"
                      fontWeight={500}
                    >
                      Gravidade:
                    </Typography>
                    <Typography variant="body1" color="neutral700.main">
                      {interaction?.gravidade}
                    </Typography>
                  </Stack>
                )}
                {interaction?.efeito && (
                  <Stack direction="row" spacing={1}>
                    <Typography
                      variant="body1"
                      color="neutral1000.main"
                      fontWeight={500}
                    >
                      Efeito:
                    </Typography>
                    <Typography variant="body1" color="neutral700.main">
                      {interaction?.efeito}
                    </Typography>
                  </Stack>
                )}
                {interaction?.probabilidade && (
                  <Stack direction="row" spacing={1}>
                    <Typography
                      variant="body1"
                      color="neutral1000.main"
                      fontWeight={500}
                    >
                      Probabilidade:
                    </Typography>
                    <Typography variant="body1" color="neutral700.main">
                      {interaction?.probabilidade}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            );
          })}
        </Stack>

        {response?.notFoundTerms?.length && (
          <Stack direction="row" spacing={1}>
            <Typography
              variant="body1"
              color="neutral1000.main"
              fontWeight={500}
            >
              Termos não encontrados:
            </Typography>
            <Typography variant="body1" color="neutral700.main">
              {response?.notFoundTerms?.join(" , ")}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Dialog>
  );
}
