import { Box, Grid, Stack, Tab, Tabs } from "@mui/material";
import { ReactNode, SyntheticEvent, useState } from "react";
import { ExamsFields } from "./ExamsFields";
import { ExamesHistory } from "./ExamesHistory";
import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ maxHeight: "60vh", pt: 2, overflow: "auto" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function Exames() {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { questionnaire, loadingResponse } = useQuestionnaireContext();

  const examsItens = questionnaire?.item?.find(
    (el: any) => el.text === "Exames Físicos"
  );

  const generalFields = examsItens?.item?.find(
    (el: any) => el.text === "Geral"
  );
  const neurologicFields = examsItens?.item?.find(
    (el: any) => el.text === "Neurológico"
  );

  return (
    <>
      <Stack width="100%" overflow="auto">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Geral" {...a11yProps(0)} />
            <Tab label="Neurologico" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Grid container spacing={3}>
            {generalFields?.item?.map((el: any) => (
              <ExamsFields item={el} type="geral" />
            ))}
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Grid container spacing={2}>
            {neurologicFields?.item?.map((el: any) => (
              <ExamsFields item={el} type="neurologico" />
            ))}
          </Grid>
        </CustomTabPanel>
      </Stack>
      {!loadingResponse && (
        <Stack
          width="100%"
          minWidth="600px"
          overflow="auto"
          maxHeight="60vh"
          paddingRight={3}
        >
          <ExamesHistory filter={value} />
        </Stack>
      )}
    </>
  );
}
