import { Box, Stack, Typography } from "@mui/material";
import { PageSubTitle } from "./PageSubTitle";
import {
  FormButtonGroup,
  FormSelectInput,
  FormTextInput,
} from "../../../components/form";
import { HelpOutline } from "@mui/icons-material";
import { useWatch } from "react-hook-form";
import { useState } from "react";
import { SearchCPF } from "../../../services/scim";
import { toast } from "react-toastify";
import { useFormatter } from "../../../utils/useFormatter";
import { useSessionContext } from "../../../contexts/SessionContext";
import dayjs from "dayjs";
import { GetPatient } from "../../../services/fhir";

interface IProps {
  control: any;
  handleSubmit: Function;
  onBackButtonClick: Function;
  navigateToNextPage: Function;
  setValue: any;
  setMinorPatient: any;
}

const KinshipOptions = [
  { display: "Filho(a)", value: "father" }, //Colocar o value referente ao patient Pai
  { display: "Dependente legal", value: "legalDependent" },
];

export function DependentRegister({
  control,
  handleSubmit,
  onBackButtonClick,
  navigateToNextPage,
  setValue,
  setMinorPatient,
}: IProps) {
  const { user, relatedPerson } = useSessionContext();
  const [loading, setLoading] = useState<boolean>(false);
  let cpfValue = useWatch({ name: "cpf", control });

  const onSubmit = async () => {
    const cpf = cpfValue?.replace(/[.-]/g, "");

    if (
      relatedPerson?.find(
        (el: any) => el?.resource?.patient?.reference?.split("-")?.[1] === cpf
      )
    ) {
      onBackButtonClick();
      toast.warn(
        "O paciente informado já está incluído na lista de dependentes"
      );
      return;
    }

    setLoading(true);
    if (user?.username === cpf) {
      toast.warning(
        "CPF digitado é o mesmo do titular. Tente novamente com outro CPF."
      );
      setLoading(false);
      return;
    }

    try {
      const getPatientResponse = await GetPatient({ identifier: cpf });

      if (getPatientResponse?.data) {
        const { name, birthDate, identifier } = getPatientResponse.data;

        setValue("patientData", getPatientResponse.data);
        setValue("name", name?.[0]?.text);
        setValue("birthdate", dayjs(birthDate).format("DD/MM/YYYY"));
        setValue("identifier", identifier?.[0]?.value);
        setValue("ownerName", user?.name);
        navigateToNextPage();
        return;
      }

      throw new Error();
    } catch (err) {
      const serproSearch = await SearchCPF(cpf);

      if (serproSearch?.status === 422) {
        setValue("identifier", cpf);
        setValue("ownerName", user?.name);
        setMinorPatient(true);
        navigateToNextPage();
        return;
      } else if (serproSearch?.status !== 400) {
        const { nome, nascimento, ni } = serproSearch?.data || {};
        toast.success("CPF validado com sucesso!");
        setValue("name", nome);
        setValue(
          "birthdate",
          dayjs(nascimento, "DDMMYYYY").format("DD/MM/YYYY")
        );
        setValue("identifier", ni);
        setValue("ownerName", user?.name);
        navigateToNextPage();
      } else {
        toast.warn("CPF não encontrado, confirme os dados!");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack spacing={10} width="60%" alignSelf="center">
      <Stack spacing={3}>
        <PageSubTitle
          subText="Digite o CPF do dependente e selecione o grau de parentesco que melhor se encaixa com você."
          subTitle="Qual CPF e o grau de parentesco?"
        />
        <Stack direction="row" spacing={3}>
          <FormTextInput
            label="CPF"
            name="cpf"
            control={control}
            required={true}
            //pattern={!cpfT && useRegex.cpf}
            mask={useFormatter.formatCPF}
            maxLength={14}
            autoFocus
          />
          <FormSelectInput
            control={control}
            name="relationship"
            label="Grau de parentesco"
            selectOptions={KinshipOptions}
            required
          />
        </Stack>
      </Stack>
      <Box
        sx={{
          width: "100%",
          height: "fit-content",
          borderRadius: 2,
          bgcolor: "neutral200.main",
        }}
      >
        <Stack padding={2} spacing={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <HelpOutline sx={{ color: "neutral700.main" }} />
            <Typography
              variant="body1"
              color="neutral700.main"
              fontWeight={600}
            >
              O que é responsavel legal?
            </Typography>
          </Stack>
          <Typography variant="body1" color="neutral700.main">
            O responsável legal é nomeado por lei ou por um tribunal e é
            encarregado de garantir o cuidado e o bem-estar do menor sob sua
            responsabilidade.
          </Typography>
        </Stack>
      </Box>
      <FormButtonGroup
        onGoBackButtonClick={() => onBackButtonClick()}
        onNextButtonClick={handleSubmit(onSubmit)}
        loading={loading}
      />
    </Stack>
  );
}
