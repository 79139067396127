import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
} from "@mui/material";
import { ContentBoxTitle } from "../../../components/common";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  PutOrganization,
  PutPractitioner,
  PutPractitionerRole,
} from "../../../services/fhir";
import { useSessionContext } from "../../../contexts/SessionContext";
import { handleKeyPress } from "../../../utils/useKeyPress";
import { FormButtonGroup, FormStack } from "../../../components/form";
import { IFormInput } from "../types";
import { oids } from "../../../configs/Settings";

interface IProps {
  handleSubmit: any;
  onBackButtonClick: Function;
  onNextButtonClick: Function;
}

export function TermsOfUse({
  handleSubmit,
  onBackButtonClick,
  onNextButtonClick,
}: IProps) {
  const [checkbox, setCheckbox] = useState(false);
  const { user } = useSessionContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: IFormInput) => {
    // Verifica se o checkbox está marcado
    if (!checkbox) {
      toast.error(
        "A aceitação dos Termos de Uso e Política de Privacidade é obrigatória para continuar."
      );
      return; // Interrompe a execução da função aqui
    }

    data.organization.id =
      `${oids.cnpj}-${data.organization?.identifier[0]?.value}` || "";

    const organizationId = data.organization.id;
    setLoading(true);
    try {
      const organizationResponse = await PutOrganization(data.organization);
      const auxDescription = organizationResponse?.parameter?.find(
        (el: any) => el.name === "description"
      )?.valueString;
      const { id: practitionerId } = await PutPractitioner({
        name: user.name,
        identifier: user.username,
        birthDate: user.birthDate,
      });
      await PutPractitionerRole({
        organizationId,
        organizationDisplay: auxDescription,
        practitionerId,
        practitionerDisplay: user.name,
      });
      toast.success("Organização criada com sucesso!");
      onNextButtonClick(organizationId);
    } catch (err) {
      toast.error("Não foi possível criar a organização");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onKeyDown={(event) =>
        handleKeyPress(event, loading, handleSubmit(onSubmit))
      }
      style={{ height: "100%", width: "100%" }}
    >
      <FormStack>
        <ContentBoxTitle>
          Termos de Uso e Política de Privacidade
        </ContentBoxTitle>
        <Box
          maxHeight="492px"
          sx={{
            width: "100%",
            overflowY: "scroll",
            mt: 2,
            mb: 2,
          }}
        >
          <Box
            height="70vh"
            sx={{
              backgroundColor: "neutral100.main",
            }}
          >
            <main>
              <div>
                <p>
                  O presente documento contém os termos e as condições de uso da{" "}
                  <strong>Plataforma Fluxmed</strong>, que pertence à{" "}
                  <strong>CORE CONSULTORIA E SERVIÇOS LTDA.</strong>, CNPJ/MF
                  sob o nº 05.490.544/0001-00, situada no SIG QUADRA 1, 385,
                  sala 404, Zona Industrial, CEP: 70.610-410, Brasília/DF (
                  <strong>Core Consulting</strong>). Este documento é de{" "}
                  <strong>adesão</strong> por parte do Contratante identificada
                  por meio da <strong>Plataforma Fluxmed</strong> no momento de
                  criação de seu cadastro (<strong>Contratante</strong>).
                </p>

                <p>
                  A <strong>Contratante</strong> e a{" "}
                  <strong>Core Consulting</strong>, sempre que em conjunto
                  referidas, para efeitos deste documento de adesão, serão
                  designadas como “<strong>Partes</strong>” ou, individualmente,
                  como “Parte”, e concordam, em boa-fé e com livre e espontânea
                  vontade, o quanto segue.
                </p>

                <p>
                  <strong>Preliminarmente, considerando que</strong>:
                </p>

                <ul>
                  <li>
                    A <strong>Contratante</strong> é uma organização de saúde
                    que exerce atividades de atenção à saúde humana. O CNPJ e
                    CNAE da Contratante será verificado junto à Receita Federal
                    no momento da realização do cadastro na plataforma.
                  </li>
                </ul>

                <ul>
                  <li>
                    A <strong>Core Consulting</strong> é uma empresa de
                    tecnologia que exerce atividades de tecnologia da informação
                    voltadas para o desenvolvimento de programas de computador
                    sob encomenda. É a detentora da marca “
                    <strong>Fluxmed</strong>”, conforme certificado de registro
                    de marca emitido pelo Instituto Nacional da Propriedade
                    Industrial (“INPI”), n. 922355134, bem como de todos os
                    direitos materiais e imateriais a ela relacionados.
                  </li>
                </ul>

                <ul>
                  <li>
                    A<strong> Core Consulting</strong> é a desenvolvedora e
                    detentora de todos os direitos de propriedade material e
                    imaterial relacionados ao <em>software</em> que compreende a{" "}
                    <strong>
                      Plataforma Interoperável de Desenvolvimento de Serviços
                      Digitais para a Saúde{" "}
                    </strong>
                    (“<strong>Plataforma</strong>”) denominada “
                    <strong>Plataforma</strong> <strong>Fluxmed</strong>”.
                  </li>
                </ul>

                <ul>
                  <li>
                    A <strong>Plataforma</strong> consiste em{" "}
                    <strong>03 (três)</strong> funcionalidades, desenvolvidas em
                    conformidade com a Lei Geral de Proteção de Dados Pessoais
                    (Lei n. 13.709/2018 – “<strong>LGPD</strong>”):
                  </li>
                </ul>

                <ul>
                  <li>
                    <strong>Fluxmed Siis</strong>: ideal para, por exemplo,
                    &nbsp;consultórios individuais, pequenas clínicas,
                    credenciados ou não de planos de saúde. Permite o registro
                    clínico, agendamento para pacientes e profissionais de
                    saúde, teleatendimento, prescrições eletrônicas CFM/ICP
                    Brasil. Consiste em um Registro Eletrônico de Saúde (SRES)
                    do paciente, atendendo aos padrões de representação,
                    terminologia e interoperabilidade de forma integrada em
                    padrão HL7 FHIR.
                  </li>
                </ul>

                <ul>
                  <li>
                    <strong>Fluxmed API´s</strong>:&nbsp; plataforma de
                    interoperabilidade HL7 FHIR cujo público-alvo são os
                    interessados em utilizar os recursos da Plataforma em
                    produtos ou sistemas de terceiros ou próprios, permitindo o
                    acesso padronizado HL7 FHIR a uma moderna plataforma de
                    desenvolvimento para serviços digitais de saúde, que entrega
                    valor agregado, agendamentos, registros clínicos, MPI,
                    gestão de terminologias e <em>data lake</em> de informações
                    clínicas, e ainda, interoperabilidade escalável.{" "}
                    <strong>
                      Trata-se de serviço acessório que estará sujeito às
                      condições de termo de uso específico com preço
                      específico.&nbsp;
                    </strong>
                  </li>
                </ul>

                <ul>
                  <li>
                    <strong>Fluxmed Analítico</strong>: permite o tratamento de
                    dados já ingeridos e processados na Plataforma e,
                    consequentemente a gestão de saúde populacional,
                    administrativas e do cuidado a partir do da base de dados
                    com informações clínicas e administrativas normalizadas, é
                    possível criar painéis analíticos anonimizados, alinhados às
                    necessidades de gestão de saúde populacional e muito mais,
                    com segurança e sigilo.{" "}
                    <strong>
                      Trata-se de serviço acessório que estará sujeito às
                      condições de termo de uso específico com preço
                      específico.&nbsp;
                    </strong>
                  </li>
                </ul>

                <ul>
                  <li>
                    Em resumo, a <strong>Plataforma</strong> consiste em um{" "}
                    <em>software</em> que realiza funções de comunicação e
                    arquivamento de dados de saúde (prontuário eletrônico,
                    agendamento, transmissão de dados e comunicação).&nbsp;
                    Assim sendo, a <strong>Plataforma</strong> não oferece
                    funcionalidades relacionadas a prevenção, diagnóstico,
                    tratamento, reabilitação ou anticoncepção e, desta forma,
                    ela não se enquadra nas regras descritas na Resolução da
                    Diretoria Colegiada (RDC) nº 185 de 22 de outubro de 2001 e
                    na RDC nº 657/2022, ambas da Agência Nacional de Vigilância
                    Sanitária (<strong>Anvisa</strong>). Portanto, a{" "}
                    <strong>Plataforma</strong> não é considerada um{" "}
                    <em>software</em> como dispositivo médico (
                    <em>Software as a Medical Device – SaMD</em>) e não precisa
                    ser regularizada junto a Gerência de Tecnologia em
                    Equipamentos (GQUIP/GGTPS/ANVISA).
                  </li>
                </ul>

                <ul>
                  <li>
                    A <strong>Contratante</strong> tem o interesse de contratar
                    e usar a <strong>Plataforma</strong>{" "}
                    <strong>Fluxmed</strong>, hospedado e acessível centralmente
                    via internet/nuvem localizada no Brasil, na modalidade de{" "}
                    <em>Software</em> como <em>Serviço</em> (Software as a
                    Service &#8211; SaaS), acompanhada de serviços prestados
                    pela <strong>Core Consulting</strong>. A{" "}
                    <strong>Contratante</strong> será exclusivamente responsável
                    pela gestão da informação clínica e todas as ações e
                    atividades relacionadas à prestação de serviços de saúde e
                    pela atuação de seus profissionais da saúde, que devem estar
                    a ela vinculados.
                  </li>
                </ul>

                <ul>
                  <li>
                    As Partes são titulares de informações comerciais e técnicas
                    de caráter sigiloso, exclusivas, relacionadas com o seu ramo
                    de atuação, sendo o caráter confidencial dessas informações
                    e documentação de valor substancial e inestimável para elas.
                    As Partes declaram e garantem que todos os direitos,
                    materiais e imateriais, relacionados à Plataforma e aos
                    serviços a ela relacionados são de propriedade exclusiva da{" "}
                    <strong>Core Consulting</strong>.
                  </li>
                </ul>

                <ul>
                  <li>
                    Todas as informações e/ou materiais que digam respeito,
                    direta ou indiretamente, ao objeto deste documento, em razão
                    de sua natureza estratégica, deverão ser tratados com o mais
                    absoluto sigilo e a mais rigorosa confidencialidade, de modo
                    a evitar, por qualquer meio ou forma, o seu conhecimento
                    e/ou utilização por parte de terceiros, seja durante a sua
                    vigência ou mesmo após ela, sob pena de a Parte infratora
                    arcar com as perdas e danos resultantes do descumprimento
                    desta obrigação, sem prejuízo das medidas judiciais e
                    administrativas cabíveis;
                  </li>
                </ul>

                <ul>
                  <li>
                    O dever de privacidade e sigilo de dados pessoais sensíveis
                    referentes à saúde encontra abrigo constitucional, eis que o
                    artigo 5º, X, da Constituição Federal qualifica como direito
                    fundamental a inviolabilidade da intimidade, a vida privada,
                    a honra e a imagem das pessoas, assegurado o direito a
                    indenização pelo dano material ou moral decorrente de sua
                    violação. Infra constitucionalmente, o artigo 21 do Código
                    Civil estabelece que “a vida privada da pessoa natural é
                    inviolável, e o juiz, a requerimento do interessado, adotará
                    as providências necessárias para impedir ou fazer cessar ato
                    contrário a esta norma”.
                  </li>
                </ul>

                <ul>
                  <li>
                    As Partes protegem a confidencialidade de dados pessoais e
                    dados sensíveis que lhe são confiados pelos titulares desses
                    dados. Para isso, vêm implementando medidas de segurança,
                    técnicas e administrativas, aptas a proteger dados pessoais
                    e dados sensíveis contra acessos não autorizados e de
                    situações acidentais, ou qualquer forma de tratamento
                    inadequado, necessárias ao cumprimento da Lei Geral de
                    Proteção de Dados (Lei nº 13.709/2018). Regras de boas
                    práticas e de governança garantem que o tratamento de dados
                    pessoais e sensíveis seja lícito, leal, transparente e
                    limitado às finalidades autorizadas a que se destina. A
                    coleta de dados pessoais e dados sensíveis para tratamento é
                    realizada pelas Partes com base em medidas necessárias para
                    assegurar a exatidão, integridade, confidencialidade, e
                    anonimização, bem como garantir o respeito à liberdade,
                    privacidade, inviolabilidade da intimidade, imagem, enfim,
                    todos os direitos dos titulares, inclusive o exercício do
                    direito de solicitar acesso, correção e eliminação de dados
                    pessoais e sensíveis armazenados em banco de dados e
                    sistemas digitais.
                  </li>
                </ul>

                <ul>
                  <li>
                    Cada Parte observará e compromete-se a respeitar todos os
                    direitos de propriedade intelectual, industrial e{" "}
                    <em>know how</em> da outra Parte, sendo que nenhum Parte
                    poderá utilizar de nenhum modo a marca, logotipo, nome
                    comercial, domínio de internet, nem qualquer outro sinal
                    distintivo pertencente à outra Parte sem sua prévia e
                    expressa autorização por escrito e assinada por seus devidos
                    representantes legais.
                  </li>
                </ul>

                <ul>
                  <li>
                    As Partes observam a legislação brasileira que versa sobre
                    privacidade e proteção de dados pessoais, inclusive a
                    Constituição Federal, o Código de Defesa do Consumidor, o
                    Código Civil, a Lei Geral de Proteção de Dados “LGPD” (Lei
                    Federal nº 13.709/2018), o Marco Civil da Internet (Lei
                    Federal nº 12.965/2014), seu decreto regulamentador (Decreto
                    nº 8.771/2016) e, relativamente à Contratante, a Lei n.{" "}
                    <a href="https://www.planalto.gov.br/ccivil_03/_Ato2011-2014/2013/Lei/L12842.htm">
                      12.842/2013
                    </a>
                    &nbsp;(Lei do Ato Médico), a Lei n.{" "}
                    <a href="https://www.planalto.gov.br/ccivil_03/LEIS/L8078.htm">
                      8.078/90
                    </a>
                    &nbsp;(Código de Defesa do Consumidor) e, nas hipóteses
                    cabíveis, aos ditames da&nbsp;
                    <a href="https://www.planalto.gov.br/ccivil_03/_Ato2015-2018/2018/Lei/L13787.htm">
                      Lei nº 13.787/18
                    </a>
                    &nbsp;(Lei do Prontuário Eletrônico), , as demais normas
                    setoriais ou gerais sobre proteção dos dados pessoais
                    previstos em Resoluções do Conselho Federal de Medicina,
                    como o Código de Ética Médica, e outros conselhos de classe
                    da área da saúde.
                  </li>
                </ul>

                <ul>
                  <li>
                    &nbsp;A <strong>Contratante</strong> reconhece e concorda
                    que a <strong>Plataforma</strong> não realiza a gravação de
                    teleconsulta.
                  </li>
                </ul>

                <ul>
                  <li>
                    Para os efeitos deste Documento, a{" "}
                    <strong>Contratante</strong> atua como “controlador de
                    dados”, a quem compete as decisões referentes ao tratamento
                    de dados pessoais de seu paciente, recaindo sobre a{" "}
                    <strong>Contratante</strong> as obrigações atribuídas pela
                    LGPD. É perante o controlador de dados que o paciente
                    titular de dados exerce os seus direitos dispostos no art.
                    18 da LGPD. Deve, ainda, comprovar que o consentimento para
                    compartilhar dados de saúde com a <strong>Fluxmed</strong>{" "}
                    foi obtido do paciente titular de dados. Recai sobre a{" "}
                    <strong>Contratante</strong> a obrigação de comunicar
                    tempestivamente à Autoridade Nacional de Proteção de Dados
                    (“<strong>ANPD</strong>”) e ao paciente-titular a ocorrência
                    de incidente de segurança que possa acarretar risco ou dano
                    relevante ao paciente -titular.
                  </li>
                </ul>

                <ul>
                  <li>
                    A <strong>Core Consulting</strong> atua como “operador de
                    dados” perante a <strong>Contratante</strong> e deve
                    realizar o tratamento de dados pessoais compartilhados pela{" "}
                    <strong>Contratante</strong> em nome da{" "}
                    <strong>Contratante </strong>e relacionadas às finalidades
                    da <strong>Plataforma Fluxmed</strong> contratadas
                    &nbsp;pela<strong> Contratante</strong>.<strong> </strong>A{" "}
                    <strong>Core</strong> <strong>Consulting</strong> só poderá
                    tratar os dados para a finalidade previamente estabelecida
                    pela <strong>Contratante</strong>, que é o controlador.
                  </li>
                </ul>

                <ul>
                  <li>
                    O tratamento de dados pessoais e dados pessoais sensíveis do
                    paciente da <strong>Contratante</strong> fica sujeito à
                    coleta, por parte da <strong>Contratante</strong>, do
                    consentimento livre, informado e inequívoco do
                    titular-paciente pelo qual ele concorda com o tratamento de
                    seus dados pessoais e dados pessoais sensíveis (art. 11, I
                    da LGPD) pela <strong>Contratante</strong>, para finalidades
                    determinadas relacionadas à assistência e gestão de sua
                    saúde. A própria <strong>Plataforma Fluxmed</strong> oferece
                    mecanismos para a observância do consentimento, conforme
                    “Termos e Condições de Uso” e Política de Privacidade, ambos
                    dispostos no ambiente logado e não logado do Aplicativo/
                    <em>web</em>, que pode ser revogado a qualquer momento pelo
                    paciente.
                  </li>
                </ul>

                <ul>
                  <li>
                    As Partes declaram que reconhecem que dados pessoais
                    sensíveis como os de saúde pertencem a uma categoria
                    especial, sendo as hipóteses para o tratamento mais
                    rigorosas. As Partes declaram e garantem que a LGPD veda o
                    tratamento de dados pessoais que possa resultar em
                    discriminações ilícitas ou abusivas como, por exemplo, a
                    prática de seleção de riscos.
                  </li>
                </ul>

                <ul>
                  <li>
                    Considera-se “
                    <strong>
                      paciente-titular de dados (ou “paciente-titular”)
                    </strong>
                    ” a pessoa natural assistida pela Contratante no âmbito da
                    prestação de serviços de saúde ofertados pela{" "}
                    <strong>Contratante</strong>. O paciente é a pessoa natural
                    a quem se referem os dados pessoais que são objeto de
                    tratamento no contexto do objeto deste Documento.
                  </li>
                </ul>

                <ul>
                  <li>
                    Considera-se “<strong>usuário autorizado</strong>” o
                    profissional de saúde vinculado à Contratante que receberá o{" "}
                    <em>login</em> de acesso à{" "}
                    <strong>Plataforma Fluxmed</strong>. Recai sobre a{" "}
                    <strong>Contratante</strong> a responsabilidade
                    integral&nbsp; sobre os atos do usuário-autorizado que
                    impliquem em tratamento irregular e inseguro de dados de
                    saúde e em incidentes de segurança sobre dados pessoais do
                    paciente-titular. &nbsp;&nbsp;
                  </li>
                </ul>

                <p>
                  As Partes declaram que concordam e aceitam, em boa-fé e livre
                  vontade, os Termos e Condições de Uso da{" "}
                  <strong>Plataforma FLuxmed</strong>, conforme o presente
                  documento, que mutuamente outorgam e aceitam:
                </p>

                <p>
                  <strong>CLÁUSULA PRIMEIRA &#8211; OBJETO</strong>
                </p>

                <ol>
                  <li>
                    Este documento tem a finalidade de determinar as condições e
                    os termos de uso da <strong>Plataforma Fluxmed</strong> e as
                    obrigações das Partes cujo objeto compreende:
                  </li>
                </ol>

                <ul>
                  <li>
                    A adesão, por parte da <strong>Contratante</strong>, de uso
                    do <em>software</em> denominado <strong>Fluxmed</strong> da{" "}
                    <strong>Core Consulting</strong>.
                  </li>
                </ul>

                <ol>
                  <li>
                    Incluídos no preço de subscrição, os serviços atrelados ao
                    objeto do Documento e prestados pela{" "}
                    <strong>Core Consulting</strong> serão os seguintes:
                  </li>
                </ol>

                <ul>
                  <li>
                    <strong>Suporte Técnico e Manutenção: </strong>Assistência
                    para resolver questões técnicas para que o <em>software</em>{" "}
                    funcione de maneira otimizada. Isso inclui atualizações
                    regulares para corrigir <em>bugs</em> e melhorar a segurança
                    e a disponibilidade. Por padrão as atualizações de software
                    referente a correção de bugs, melhoria de segurança e
                    disponibilidade serão realizados pela monitoração ativa da
                    plataforma. Será disponibilizado um canal digital para
                    abertura de chamados de suporte técnico. Será realizada
                    triagem de criticidade do chamado com resposta ao cliente em
                    até 24 horas. De acordo com a criticidade, alta, média ou
                    baixa, será informado o prazo de ajuste, que pode ser de até
                    5 dias úteis.
                  </li>
                </ul>

                <ul>
                  <li>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>Instalação e Configuração: </strong>Ajuda na
                    instalação do <em>software</em> e na configuração inicial
                    para assegurar que o <em>software</em> esteja pronto para
                    uso conforme as necessidades específicas do usuário da{" "}
                    <strong>Contratante</strong>. Os manuais do usuário serão
                    disponibilizados na própria Plataforma e integrarão o
                    presente documento.
                  </li>
                </ul>

                <ul>
                  <li>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>Customização: </strong>se desejado pela{" "}
                    <strong>Contratante</strong>, serviços que adaptam o{" "}
                    <em>software</em> às necessidades específicas da{" "}
                    <strong>Contratante</strong>, incluindo o desenvolvimento de
                    funcionalidades personalizadas ou integrações com outros
                    sistemas, não estão inclusos no preço desta subscrição. Para
                    customizações, entre em contato com a{" "}
                    <strong>Core Consulting</strong> através do site.
                  </li>
                </ul>

                <ul>
                  <li>
                    <strong>
                      <em>Backup</em>
                    </strong>
                    <strong> e Recuperação de Dados:</strong> a Plataforma é
                    hospedada centralmente via internet/nuvem.. O{" "}
                    <em>backup</em> da Plataforma será automatizado com recursos
                    da nuvem. A Plataforma oferece mecanismos para que a{" "}
                    <strong>Contratante</strong> realize <em>backups</em> de
                    seus registros e é a responsável por isso.
                  </li>
                </ul>

                <ul>
                  <li>
                    <strong>
                      Atualizações e <em>Upgrades</em>:{" "}
                    </strong>
                    Fornecimento de novas versões do <em>software</em> para
                    garantir acesso contínuo às últimas funcionalidades,
                    melhorias de desempenho e correções de segurança. É possível
                    que novas funcionalidades resultem em ajuste no preço.
                  </li>
                </ul>

                <ol>
                  <li>
                    As Partes declaram e reconhecem que o paciente é o
                    protagonista de sua própria saúde e o titular de seus dados
                    pessoais e dados pessoais sensíveis, podendo revogar, ou
                    não, o consentimento de acesso às suas
                    informações.&nbsp;&nbsp;&nbsp;&nbsp;
                  </li>
                </ol>

                <p>
                  <strong>CLÁUSULA SEGUNDA – RESPONSABILIDADES &nbsp;</strong>
                </p>

                <p>
                  2.1. A <strong>Core Consulting</strong> é responsável por:
                </p>

                <ol>
                  <li>
                    Observar com os termos e usos deste documento, manter a
                    disponibilidade e qualidade da Plataforma.
                  </li>

                  <li>Manter atualizados os Manuais de usuário.</li>

                  <li>
                    Investir em constantes evoluções tecnológicas em prol da
                    saúde digital.
                  </li>

                  <li>
                    No caso de cancelamento ou extinção do Documento, por
                    qualquer motivo, a <strong>Core Consulting </strong>
                    obriga-se a manter a <strong>Plataforma</strong> disponível
                    à <strong>Contratante</strong>, com acesso apenas de leitura
                    de dados, pelo período de 30 (trinta) dias para que a{" "}
                    <strong>Contratante</strong> possa proceder com a
                    observância da <strong>Lei n. 13.787/2018</strong> que versa
                    sobre a digitalização e a utilização de sistemas
                    informatizados para a guarda, o armazenamento e o manuseio
                    de prontuário de paciente.
                  </li>

                  <li>
                    Notificar a <strong>Contratante</strong> no caso de
                    incidente de segurança em até 48 (quarenta e oito) horas do
                    conhecimento do incidente e prestar os esclarecimentos
                    necessários e relacionados ao incidente à{" "}
                    <strong>Contratante</strong>.
                  </li>

                  <li>
                    Manter a emissão tempestiva de Notas Fiscais eletrônicas,
                    referentes à subscrição de uso da{" "}
                    <strong>Contratante</strong>.
                  </li>
                </ol>

                <p>
                  Informar por e-mail a <strong>Contratante</strong> sobre
                  problemas de pagamento mantendo o uso da{" "}
                  <strong>Plataforma Fluxmed</strong> por 10 dias após a
                  informação do problema do pagamento.
                </p>

                <p>
                  2.2. Além das obrigações previstas neste instrumento, e na
                  legislação aplicável, a <strong>Contratante</strong> se
                  compromete, ainda, a:
                </p>

                <ol>
                  <li>
                    Efetuar todos os pagamentos da subscrição tempestivamente. ;
                  </li>

                  <li>
                    Garantir e manter uma conexão de internet com velocidade e
                    estabilidade suficientes para suportar o desempenho eficaz
                    da <strong>Plataforma</strong>.
                  </li>

                  <li>
                    Observar as instruções <strong>&nbsp;</strong>para a
                    utilização da Plataforma e Manuais de usuário
                  </li>

                  <li>
                    Não realizar modificações, atualizações ou alterações no{" "}
                    <em>software</em> (<strong>Plataforma</strong>) sem a prévia
                    aprovação por escrito da <strong>Core Consulting</strong>.
                    Qualquer modificação desautorizada pode levar a problemas de
                    compatibilidade ou funcionamento e resultar em violação das
                    condições de uso do <em>software</em>, isentando a{" "}
                    <strong>Core Consulting</strong> de qualquer
                    responsabilidade.
                  </li>

                  <li>
                    A <strong>Contratante</strong> está proibida de
                    sublicenciar, alugar ou emprestar o uso <em>software</em> (
                    <strong>Plataforma</strong>) a terceiros.
                  </li>

                  <li>
                    Em caso de instabilidades, falhas ou qualquer outro problema
                    técnico com o <em>software </em>(<strong>Plataforma</strong>
                    ), a <strong>Contratante</strong> se obriga a abrir chamados
                    de suporte técnico imediatamente ao conhecimento do
                    problema.
                  </li>
                </ol>

                <p>
                  <strong>
                    CLÁUSULA TERCEIRA – LIMITAÇÃO DE RESPONSABILIDADE E FORÇA
                    MAIOR
                  </strong>
                </p>

                <p>
                  3.1.
                  <strong>
                    {" "}
                    Limitação de Responsabilidade e Força Maior.{" "}
                  </strong>
                  As Partes declaram e concordam que a responsabilidade total da{" "}
                  <strong>Core Consulting,</strong> sob qualquer circunstância
                  decorrente ou relacionada ao objeto deste documento, se
                  comprovada será limitada à natureza da atuação econômica da{" "}
                  <strong>Core Consulting </strong>e suas responsabilidades.
                  Para não haver dúvidas, em nenhuma hipótese a{" "}
                  <strong>Core Consulting</strong> será responsabilizada por
                  danos sofridos pelo paciente da <strong>Contratante</strong>{" "}
                  relacionados à prestação de serviço de saúde pela{" "}
                  <strong>Contratante</strong>.
                </p>

                <p>
                  3.2.1.<strong> Força Maior</strong>: Nenhuma das partes será
                  responsável por falhas ou atrasos no desempenho de suas
                  obrigações sob este documento devido a eventos de força maior,
                  que incluem, mas não se limitam a, desastres naturais,
                  guerras, atos de terrorismo, greves, falhas ou atrasos de
                  Internet, falhas de energia, e intervenções governamentais. Em
                  tais circunstâncias, os prazos para o desempenho serão
                  prorrogados por um período de tempo razoável, considerando a
                  duração do evento de força maior. A seguir encontram-se
                  exemplos que isentam a <strong>Core</strong>{" "}
                  <strong>Consulting</strong> de qualquer responsabilidade
                  perante a <strong>Contratante</strong>: (a){" "}
                  <strong>falhas na internet contratada pela </strong>
                  contratante: a empresa de tecnologia não pode ser
                  responsabilizada por problemas de conectividade que ocorram
                  devido a falhas na internet contratada pela
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contratante; (b){" "}
                  <strong>manutenção programada</strong>: interrupções agendadas
                  para manutenção ou atualizações do sistema, sobre as quais a
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contratante foi previamente
                  notificada; (c) <strong>desastres naturais</strong>: eventos
                  fora do controle da empresa, como terremotos, furacões,
                  enchentes ou outros desastres naturais que afetem a
                  infraestrutura necessária para o serviço; (d){" "}
                  <strong>ataques cibernéticos de terceiros</strong>: ataques
                  por <em>hackers</em> ou outras entidades maliciosas que não
                  sejam resultado de negligência por parte da{" "}
                  <strong>Core Consulting</strong>; (e){" "}
                  <strong>falhas de equipamentos da </strong>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Contratante</strong>:
                  problemas causados por <em>hardware</em> obsoleto ou mal
                  mantido pela <strong>Contratante</strong>, que afetem a
                  operação do SaaS&nbsp;&nbsp;&nbsp;&nbsp; ; (f){" "}
                  <strong>alterações não autorizadas pel</strong>
                  <strong>a Core Consulting</strong>&nbsp;&nbsp;&nbsp;&nbsp; :
                  modificações feitas pela <strong>Contratante</strong> na
                  configuração ou no uso da plataforma sem consentimento ou
                  aprovação da <strong>Core Consulting</strong>; (g){" "}
                  <strong>
                    conflitos de <em>software</em> causados por terceiros
                  </strong>
                  : interferências ou incompatibilidades causadas por{" "}
                  <em>software</em> ou <em>hardware</em> de terceiros não
                  fornecidos pela <strong>Core Consulting</strong>; (h){" "}
                  <strong>limitações tecnológicas conhecidas</strong>:
                  restrições ou limitações tecnológicas da plataforma que são
                  conhecidas e documentadas e sobre as quais a{" "}
                  <strong>Contratante</strong> foi informada. (i){" "}
                  <strong>uso inadequado da plataforma</strong>: uso do{" "}
                  <em>software</em> de maneira contrária às instruções
                  fornecidas ou às práticas recomendadas estabelecidas pela{" "}
                  <strong>Core Consulting</strong>; (j){" "}
                  <strong>força maior</strong>: qualquer outro evento fora do
                  controle razoável da <strong>Core Consulting</strong>, como
                  greves, tumultos, guerra, pandemias, ou intervenções
                  governamentais que impeçam a continuidade dos serviços; (g)
                  Quando comprovado que o problema técnico reside na nuvem, a{" "}
                  <strong>Core Consulting</strong> não será responsável por
                  eventos como instabilidade ou até mesmo indisponibilidade
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;da Plataforma.
                </p>

                <p>
                  <strong>
                    CLÁUSULA QUARTA – VIGÊNCIA E PREÇO DOS SERVIÇOS
                  </strong>
                </p>

                <p>
                  <a></a>4.1.&nbsp; A vigência da subscrição de uso e o preço
                  serão disponibilizados <em>online</em> pelos recursos da{" "}
                  <strong>Plataforma Fluxmed</strong> e poderão ser ajustados ao
                  longo da evolução tecnológica das funcionalidades. Os
                  pagamentos serão antecipados. No caso de extinção antecipada
                  da subscrição, prevalecerá o preço contratado e nenhum estorno
                  será efetuado por parte da <strong>Core Consulting</strong>.
                </p>

                <p>
                  <strong>CLÁUSULA QUINTA – RESCISÃO</strong>
                </p>

                <p>
                  5.1. A subscrição poderá ser cancelada pela{" "}
                  <strong>Contratante</strong> a qualquer momento pelos
                  mecanismos oferecidos pela <strong>Plataforma Fluxmed</strong>
                  .
                </p>

                <p>
                  5.2. A <strong>Core Consulting</strong> poderá cancelar a
                  subscrição por justo motivo, devendo notificar a{" "}
                  <strong>Contratante</strong> com 10 (dez) dias de
                  antecedência. Caso for detectado o uso indevido da{" "}
                  <strong>Plataforma Fluxmed</strong>, por parte da{" "}
                  <strong>Contratante</strong>, a Core Consulting notificará a
                  Contratante e suspenderá imediatamente a subscrição até que
                  seja corrigido.
                </p>

                <p>
                  <strong>CLÁUSULA SEXTA – CONFIDENCIALIDADE</strong>
                </p>

                <p>
                  6.1. As Partes se comprometem a guardar o mais absoluto sigilo
                  das informações fornecidas pela outra parte para a consecução
                  do objeto deste Documento, consideradas de valor inestimável
                  para ambas, comprometendo-se a as usarem-nas apenas para os
                  fins específicos a que se refere a presente contratação. A
                  parte que não cumprir esta obrigação será responsável por
                  eventuais danos causados que venham a ser apurados,
                  oportunamente.
                </p>

                <p>
                  6.2. As Partes, neste ato, obrigam-se, por si, seus
                  representantes, prepostos, empregados e/ou subcontratados a
                  tratar com absoluto sigilo e confidencialidade toda e qualquer
                  informação, dados econômicos ou técnicos, desenhos, projetos,
                  procedimentos, manuais, disponibilizados por ambas durante a
                  vigência do presente documento, não podendo, em nenhuma
                  hipótese, revelá-los a terceiros e/ou divulgá-los sob qualquer
                  forma e pretexto, ou utilizá-los em proveito próprio ou de
                  terceiros para fins estranhos ao do presente documento, salvo
                  mediante expressa autorização por escrito dos demais
                  interessados.
                </p>

                <p>
                  6.3. As partes adotarão rigorosas medidas de proteção das
                  informações confidenciais da outra Parte para evitar que elas
                  sejam de qualquer modo divulgadas, reveladas, publicadas,
                  vendidas, cedidas ou de qualquer outra forma transferidas pela
                  Parte Receptora, seus representantes, prepostos, empregados
                  e/ou subcontratados.
                </p>

                <p>
                  6.4. A <strong>Contratante</strong> é a única responsável pela
                  origem das informações por ela prestadas para a{" "}
                  <strong>&nbsp;Core Consulting</strong> para a consecução dos
                  Serviços ora contratados e neste ato expressamente declara que
                  o conteúdo da base de vidas e das informações nela contidas e
                  que serão fornecidas à <strong>&nbsp;Core Consulting</strong>{" "}
                  não viola qualquer lei ou qualquer direito de terceiros,
                  incluindo, sem limitação, direitos de propriedade intelectual
                  e direitos autorais, isentando e indenizando a{" "}
                  <strong>Core Consulting</strong>, a qualquer tempo, mesmo após
                  a expiração ou término deste documento por qualquer motivo, de
                  qualquer responsabilidade que decorra da informação equivocada
                  ou falsa dos dados fornecidos à{" "}
                  <strong>&nbsp;Core Consulting</strong>.
                </p>

                <p>
                  6.4.1. A <strong>&nbsp;Core Consulting</strong> está isenta de
                  responsabilidade pelo conteúdo de toda e qualquer informação,
                  dados pessoais, econômicos ou técnicos relacionados ao
                  beneficiário, disponibilizados pela{" "}
                  <strong>Contratante</strong>, a qual é responsável pela origem
                  e exatidão das informações que disponibilizará à{" "}
                  <strong>&nbsp;Core Consulting</strong> para a realização dos
                  serviços objeto do presente instrumento.
                </p>

                <p>
                  6.5. A <strong>&nbsp;Core Consulting</strong> expressamente
                  compromete-se a adotar as medidas técnicas e organizacionais
                  necessárias para garantir a segurança dos dados pessoais dos
                  aderentes da Contratante e evitar a sua alteração, perda e
                  acesso não autorizados, dada a natureza pessoal dos dados
                  armazenados e os riscos a que estão expostos, quer da ação
                  humana ou o ambiente físico ou eletrônico em que estejam
                  armazenados.
                </p>

                <p>
                  6.6. A <strong>Contratante</strong> está ciente, ainda, de que
                  o prontuário, guias, informativos enviados pela{" "}
                  <strong>&nbsp;Core Consulting</strong> aos beneficiários são
                  documentos não compartilháveis, cuja propriedade pertence
                  exclusivamente ao beneficiário, sendo que{" "}
                  <strong>Core Consulting</strong> reserva o direito de não
                  fornecer cópia à <strong>Contratante</strong>, em hipótese
                  alguma, sob pena de violação do sigilo e privacidade do
                  beneficiário, exceto mediante o consentimento inequívoco, nos
                  termos da Lei.
                </p>

                <p>
                  6.7. As obrigações relativas à confidencialidade das
                  informações técnicas e de negócio, trocadas entre as Partes
                  para a execução deste Documento, perdurarão pelo prazo de 02
                  (dois) anos após a extinção deste Documento, exceto no que se
                  tratar de segredo comercial e industrial, e direitos
                  personalíssimos de indivíduos, como dados pessoais sensíveis.
                </p>

                <p>
                  <strong>
                    CLÁUSULA SÉTIMA – PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS
                  </strong>
                </p>

                <p>
                  7.1. Relativamente à Lei Geral de Proteção de Dados Pessoais
                  (Lei 13.709/2018 – LGPD), sem prejuízo das demais disposições
                  dispostas neste instrumento, as Partes declaram o seguinte:
                </p>

                <ul>
                  <li>
                    As Partes protegem a confidencialidade de dados pessoais e
                    dados sensíveis que lhe são confiados pelos titulares desses
                    dados em razão deste documento. Declaram, inclusive em nome
                    de seus empregados, cooperados, prepostos e subcontratados
                    que cumprem a legislação brasileira sobre privacidade,
                    disposta na Constituição Federal, o Código de Defesa do
                    Consumidor, o Código Civil, a Lei Geral de Proteção de Dados
                    “LGPD” (Lei Federal nº 13.709/2018), o Marco Civil da
                    Internet (Lei Federal nº 12.965/2014), seu decreto
                    regulamentador (Decreto nº 8.771/2016) e demais normas
                    setoriais ou gerais sobre proteção de dados pessoais. Para
                    isso, vêm implementando medidas de segurança, técnicas e
                    administrativas, aptas a proteger dados pessoais e dados
                    sensíveis contra acessos não autorizados e de situações
                    acidentais, ou qualquer forma de tratamento inadequado,
                    necessárias ao cumprimento da Lei Geral de Proteção de Dados
                    (Lei nº 13.709/2018).
                  </li>
                </ul>

                <ul>
                  <li>
                    Regras de boas práticas e de governança garantem que o
                    tratamento de dados pessoais e sensíveis seja lícito, leal,
                    limitado às finalidades autorizadas a que se destina e
                    respeitando-se os princípios da transparência, necessidade,
                    proporcionalidade e não-discriminação. A coleta de dados
                    pessoais e dados sensíveis para tratamento é realizada pelas
                    Partes com base em medidas necessárias para assegurar a
                    exatidão, integridade, confidencialidade, e anonimização,
                    bem como garantir o respeito à liberdade, privacidade,
                    inviolabilidade da intimidade, imagem, enfim, todos os
                    direitos e liberdades dos titulares, inclusive o exercício
                    do direito de solicitar acesso, correção e eliminação de
                    dados pessoais e sensíveis armazenados em banco de dados e
                    sistemas digitais, exceto quando a conservação permitida por
                    lei ou obrigação regulatória (art. 16. I da LGPD)
                  </li>
                </ul>

                <ul>
                  <li>
                    As Partes deverão possuir política de privacidade,
                    diretrizes de segurança da informação e mecanismos de
                    mitigação de riscos, com vistas a garantir a segurança do
                    tratamento de dados pessoais e dados pessoais sensíveis, por
                    exemplo, relatório de impacto à proteção de dados pessoais
                    (art. 5º, XVII da LGPD), a fim de evitar incidentes de
                    segurança como vazamento de informações, inclusive causados
                    internamente, com evidências acessíveis de que tal conteúdo
                    é comunicado e disseminado para todos seus aderentes .
                  </li>
                </ul>

                <ul>
                  <li>
                    Para fins deste Documento a <strong>Core Consulting</strong>{" "}
                    atua como “Operador de dados”, nos termos da LGPD e a{" "}
                    <strong>Contratante</strong> atua como “Controlador de
                    dados”, nos termos da LGPD.
                  </li>
                </ul>

                <ul>
                  <li>
                    A <strong>Core Consulting</strong> responderá solidariamente
                    com a <strong>Contratante</strong> pelos danos causados pelo
                    tratamento quando ficar comprovado que ela (i) descumpriu as
                    obrigações da legislação de proteção de dados ou (ii) quando
                    não tiver seguido as instruções lícitas da{" "}
                    <strong>Contratante</strong>, aplicando-se as hipóteses de
                    isenção de responsabilidade previstas no art. 43 da LGPD.
                  </li>
                </ul>

                <p>
                  <strong>CLÁUSULA OITAVA – DISPOSIÇÕES FINAIS</strong>
                </p>

                <p>
                  8.1. O presente documento é firmado por pessoas inteiramente
                  distintas, não podendo vir a ser caracterizado como qualquer
                  forma de sociedade ou associação, sendo certo que as Partes
                  não terão qualquer outro tipo de relação, a não ser o
                  decorrente da relação regida por este Documento.
                </p>

                <p>
                  8.2. Cada Parte será totalmente responsável por suas
                  obrigações trabalhistas, fiscais ou civis, contraídas,
                  inexistindo qualquer forma de vínculo trabalhista entre as
                  Partes, e outras pessoas jurídicas de qualquer forma
                  relacionadas com este Documento;
                </p>

                <p>
                  8.5. As Partes comprometem-se a: (i) agir dentro das leis e
                  regulamentos aplicáveis e obedecer aos mais estritos e
                  rigorosos conceitos e princípios da ética, moralidade e boa-fé
                  na condução dos negócios conjuntos, incluindo, mas não se
                  limitando, a evitar relações, contatos e/ou parcerias
                  comerciais com quaisquer agentes que por qualquer meio
                  sabidamente participem ou tenham participado em atividades
                  ilícitas, de qualquer espécie; (ii) possuir todas as
                  autorizações e licenças para operar seu negócio da forma como
                  atualmente é operado e manter, durante o período de vigência
                  deste Documento, todas as aprovações, permissões, registros e
                  autorizações governamentais ou não governamentais exigidos
                  para a consecução dos objetivos deste Documento, sem quaisquer
                  restrições ou condições; (iii) não prometer, oferecer ou dar,
                  direta ou indiretamente, pagamento, doação, compensação,
                  vantagens financeiras ou não financeiras ou benefícios de
                  qualquer espécie a agente público ou a terceira pessoa a ele
                  relacionada com o propósito de influenciar qualquer ato ou
                  decisão desse agente público no exercício de seu ofício; (iv)
                  não realizar quaisquer ações ou omissões que constituam
                  prática ilegal ou de corrupção, nos termos da Lei nº
                  12.846/2013, do Decreto nº 8.420/2015, do U.S. Foreign Corrupt
                  Practices Act de 1977 (“FCPA”), da Lei contra Subornos do
                  Reino Unido (“UK Bribery Act”), ou de quaisquer outras leis ou
                  regulamentos aplicáveis (“Leis Anticorrupção”), ainda que não
                  relacionadas com o presente Documento.
                </p>

                <p>
                  8.6. As Partes declaram e garantem mutuamente, inclusive
                  perante seus fornecedores de bens e serviços, que: (a) exercem
                  suas atividades em conformidade com a legislação vigente a
                  elas aplicável, e que detém as aprovações necessárias à
                  celebração deste Documento, e ao cumprimento das obrigações
                  nele previstas; (b) não utilizam de trabalho ilegal, e
                  comprometem-se a não utilizar práticas de trabalho análogo ao
                  escravo, ou de mão de obra infantil, salvo este último na
                  condição de aprendiz, observadas as disposições da
                  Consolidação das Leis do Trabalho, seja direta ou
                  indiretamente, por meio de seus respectivos fornecedores de
                  produtos e de serviços; (c) não empregam menor até 18 anos,
                  inclusive menor aprendiz, em locais prejudiciais à sua
                  formação, ao seu desenvolvimento físico, psíquico, moral e
                  social, bem como em locais e serviços perigosos ou insalubres,
                  em horários que não permitam a frequência à escola e, ainda,
                  em horário noturno, considerando este o período compreendido
                  entre as 22h e 5h; (d) não utilizam práticas de discriminação
                  negativa, e limitativas ao acesso na relação de emprego ou a
                  sua manutenção, tais como, mas não se limitando a, motivos de:
                  sexo, origem, cor, condição física, religião, estado civil,
                  idade, situação familiar ou estado gravídico; (e)
                  comprometem-se a proteger e preservar o meio ambiente, bem
                  como a prevenir e erradicar práticas danosas ao meio ambiente,
                  executando seus serviços em observância à legislação vigente
                  no que tange à Política Nacional do Meio Ambiente, dos Crimes
                  Ambientais e a Política Nacional de Resíduos Sólidos, bem como
                  dos atos legais, normativos e administrativos relativos à área
                  ambiental e correlatas, emanados das esferas Federal,
                  Estaduais e Municipais.
                </p>

                <p>
                  8.7. As Partes neste ato declaram que o presente Documento
                  constituirá título executivo extrajudicial podendo ser
                  prontamente promovida a sua execução caso qualquer das partes
                  incorrer em inadimplência, nos termos do artigo 784, inciso
                  III do Código de Processo Civil.
                </p>

                <p>
                  8.8. Todos os entendimentos anteriores (orais ou realizados de
                  qualquer outra forma) relacionados ao presente Documento que
                  não estejam incorporados neste Documento não são válidos, não
                  devendo ser considerados para fins de sua interpretação.
                </p>

                <p>
                  8.9. Qualquer omissão ou tolerância das partes na exigência do
                  fiel cumprimento dos termos e condições deste Documento, ou no
                  exercício das prerrogativas dele decorrentes, não constituirá
                  novação ou renúncia, nem afetará o direito da parte de
                  exercê-lo a qualquer tempo.
                </p>

                <p>
                  <strong>CLÁUSULA NONA &#8211; FORO E LEI APLICÁVEL</strong>
                </p>

                <p id="termo-de-uso-pj">
                  9.1. A legislação da República Federativa do Brasil regerá a
                  interpretação, validade e operação objeto deste Documento, bem
                  como o cumprimento de todas as obrigações nele estabelecidas.
                  9.2. As Partes envidarão os melhores esforços para resolver
                  amigavelmente e de boa-fé quaisquer controvérsias resultantes
                  da interpretação e ou cumprimento das obrigações dispostas
                  neste instrumento, mesmo após sua rescisão ou término. Caso a
                  autocomposição não seja possível entre as Partes, dentro de
                  prazo plausível entre elas estabelecido, elas concordam com a
                  jurisdição exclusiva do foro da Cidade de Brasília/DF,
                  renunciando expressamente a qualquer outro, por mais
                  privilegiado que seja
                </p>
              </div>
            </main>
          </Box>
        </Box>
        <FormGroup sx={{ width: "100%", textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                onChange={() => setCheckbox(!checkbox)}
              />
            }
            label="Eu li e estou de acordo com os Termos de Uso e Politica de Privacidade."
          />
        </FormGroup>
        <Stack
          direction="row-reverse"
          width="100%"
          mt={3}
          spacing={3}
          justifyContent="space-between"
        >
          <FormButtonGroup
            maxWidth
            onGoBackButtonClick={() => onBackButtonClick()}
            onNextButtonClick={handleSubmit(onSubmit)}
            goBackButtonText="Recusar"
            nextButtonText="Aceitar"
            loading={loading}
            disabled={checkbox === false}
          />
        </Stack>
      </FormStack>
    </form>
  );
}
