import { endpoints } from "../../../configs/Settings";
import { useAxios } from "../../../utils/useAxios";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";

export async function SearchCPF(cpf: string) {
  const url = `${endpoints.SIGNUP}/1.0.0/searchCPF`;
  const data = { cpf };
  const bearerToken = await useAuth.getUserToken();

  const headers = {
    Accept: "*/*",
    Authorization: bearerToken,
    "Content-Type": "application/json",
  };

  const response = await useAxios
    .post(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.UnprocessableEntity) {
        if (response.status !== HTTPResponseStatus.Success) {
          throw new Error(`SearchCPF: ${response.status}`);
        }
      }
      return { status: HTTPResponseStatus.Success, data: response.data };
    })
    .catch((error) => {
      if (error.response.status === HTTPResponseStatus.UnprocessableEntity) {
        return {
          status: HTTPResponseStatus.UnprocessableEntity,
          data: {},
        };
      }

      return {
        status: HTTPResponseStatus.BadRequest,
        data: `${error}`,
      };
    });
  if (response) return response;
}
