import { Delete, Description, Warning } from "@mui/icons-material";
import { IconButton, TableCell, Tooltip } from "@mui/material";
import { useConsentContext } from "../../../contexts/ConsentContext";
import { useNavigate } from "react-router-dom";

export function ConsentActions({ row, type }: any) {
  const { setRevokeDialog, setSubjectName, setSubjectId, setRenewDialog } =
    useConsentContext();
  const navigate = useNavigate();

  return (
    <>
      {type === "received" ? (
        <TableCell align="center">
          {row?.isValid && (
            <Tooltip title="Acessar Registros ">
              <IconButton
                onClick={() => {
                  if (row.identifier)
                    navigate("/summary", {
                      state: {
                        patientId: row?.identifier,
                        purpose: row?.motivo,
                      },
                    });
                }}
              >
                <Description />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      ) : (
        <TableCell align="center">
          <Tooltip title={row.isValid ? "Revogar" : "Renovar"}>
            <IconButton
              onClick={() => {
                setSubjectId(row?.identifier);
                setSubjectName(row?.name);
                if ((row.isValid && row.vigency) || !row.vigency)
                  setRevokeDialog(true);
                else setRenewDialog(true);
              }}
            >
              {row.vigency ? (
                row.isValid ? (
                  <Delete sx={{ color: "error600.main" }} />
                ) : (
                  <Warning sx={{ color: "warning500.main" }} />
                )
              ) : (
                <Delete sx={{ color: "error600.main" }} />
              )}
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
    </>
  );
}
