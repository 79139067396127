import { toast } from "react-toastify";
import { endpoints } from "../../../../configs/Settings";
import { HTTPResponseStatus } from "../../../../utils/enum";
import { useAuth } from "../../../../utils/useAuth";
import { useAxios } from "../../../../utils/useAxios";

interface formatFields {
  urlBinary: string;
}

export async function GetBinary({ urlBinary }: formatFields) {
  const url = `${endpoints.MHD}/${urlBinary}`;

  const subjectId = await useAuth.getSubjectId();
  const organizationId = await useAuth.getOrganizationId();

  const headers = {
    Accept: "application/fhir+json",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
    "x-user-token": await useAuth.getXUserToken(),
    ...(subjectId && { "subject-id": subjectId }),
    ...(organizationId && { "organization-id": organizationId }),
    purposeofuse: "Atendimento",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`Get DocumentReference: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetBinary ", error);
      // toast.error(`GetBinary Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
