import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

interface ConsentContextValue {
  newConsentDialog: boolean;
  setNewConsentDialog: Dispatch<SetStateAction<boolean>>;
  vigency: number;
  setVigency: Dispatch<SetStateAction<number>>;
  confirmConsentDialog: boolean;
  setConfirmConsentDialog: Dispatch<SetStateAction<boolean>>;
  revokeDialog: boolean;
  setRevokeDialog: Dispatch<SetStateAction<boolean>>;
  renewDialog: boolean;
  setRenewDialog: Dispatch<SetStateAction<boolean>>;
  subjectName: string;
  setSubjectName: Dispatch<SetStateAction<string>>;
  subjectId: string;
  setSubjectId: Dispatch<SetStateAction<string>>;
  consentType: string;
  setConsentType: Dispatch<SetStateAction<string>>;
  resetStates: Function;
}

export const ConsentContext = createContext<ConsentContextValue>(null!);

export function ConsentContextProvider({ children }: { children: ReactNode }) {
  const [vigency, setVigency] = useState<number>(30);
  const [revokeDialog, setRevokeDialog] = useState<boolean>(false);
  const [renewDialog, setRenewDialog] = useState<boolean>(false);
  const [newConsentDialog, setNewConsentDialog] = useState<boolean>(false);
  const [confirmConsentDialog, setConfirmConsentDialog] =
    useState<boolean>(false);
  const [subjectId, setSubjectId] = useState<string>("");
  const [subjectName, setSubjectName] = useState<string>("");
  const [consentType, setConsentType] = useState<string>("cpf");

  function resetStates() {
    setNewConsentDialog(false);
    setRenewDialog(false);
    setRevokeDialog(false);
    setSubjectId("");
    setSubjectName("");
    setConfirmConsentDialog(false);
    setVigency(30);
  }

  const contextValue = {
    vigency,
    setVigency,
    confirmConsentDialog,
    setConfirmConsentDialog,
    newConsentDialog,
    setNewConsentDialog,
    revokeDialog,
    setRevokeDialog,
    renewDialog,
    setRenewDialog,
    subjectId,
    setSubjectId,
    subjectName,
    setSubjectName,
    resetStates,
    consentType,
    setConsentType,
  };

  return (
    <ConsentContext.Provider value={contextValue}>
      {children}
    </ConsentContext.Provider>
  );
}

export function useConsentContext() {
  return useContext(ConsentContext);
}
