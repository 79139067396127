import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { CommonDialog } from "../../../components/common/CommonDialog";
import { FormAutoComplete, FormTextInput } from "../../../components/form";
import { ArrowForward, Publish } from "@mui/icons-material";
import { PrimaryButton, SecondaryButton } from "../../../components/common";
import { useNavigate } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { useSessionContext } from "../../../contexts/SessionContext";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { GetMedication } from "../../../services/terminology";
import { PatientMedicationOption } from "../../medicaldocumentation/components/prescription/PatientMedicationOption";
import { PostMedicationRequest } from "../../../services/fhir";

interface IProps {
  setModalOpen: Function;
  isOpen: boolean;
  setRefetch: any;
}

const unit = [
  {
    value: "GRAN",
    label: "Grânulos",
    inputType: "number",
  },
  {
    value: "COM",
    label: "Comprimido",
    inputType: "number",
  },
  {
    value: "CAP",
    label: "Cápsula",
    inputType: "number",
  },
  {
    value: "SOL OR",
    label: "Solução Oral",
  },
  {
    value: "SOL INJ",
    label: "Solução Injetável",
  },
  {
    value: "SOL",
    label: "Solução",
  },

  {
    value: "SUSP",
    label: "Suspensão",
  },
  {
    value: "POM",
    label: "Pomada",
  },
  {
    value: "GEL",
    label: "Gel",
  },
  {
    value: "XAR",
    label: "Xarope",
  },
  {
    value: "INJ",
    label: "Injetável",
  },
  {
    value: "COL",
    label: "Colírio",
  },
  {
    value: "AER",
    label: "Aerossol",
  },
  {
    value: "SUP",
    label: "Supositório",
    inputType: "number",
  },
  {
    value: "AMP",
    label: "Ampola",
    inputType: "number",
  },
  {
    value: "CTG",
    label: "Cartucho",
    inputType: "number",
  },
  {
    value: "FLD",
    label: "Fluido",
  },
  {
    value: "CJ  ",
    label: "Conjunto",
    inputType: "number",
  },
  {
    value: "ENV",
    label: "Envelope ou Sachê",
  },
  {
    value: "BISN",
    label: "Bisnaga",
    inputType: "number",
  },
  {
    value: "DISP",
    label: "Dispositivo",
  },
  {
    value: "BALA",
    label: "Bala",
    inputType: "number",
  },
  {
    value: "LAQ",
    label: "Laca",
  },
];

const frequency = [
  { value: 3, label: "3 h" },
  { value: 6, label: "6 h" },
  { value: 8, label: "8 h" },
  { value: 12, label: "12 h" },
  { value: 24, label: "24 h" },
];

const time = [
  { value: '', label: "tempo indeterminado" },
  { value: 1, label: "1 dia" },
  { value: 2, label: "2 dias" },
  { value: 3, label: "3 dias" },
  { value: 5, label: "5 dias" },
  { value: 7, label: "7 dias" },
  { value: 15, label: "15 dias" },
];

export function ModalNewMedication({
  setModalOpen,
  isOpen,
  setRefetch,
}: IProps) {
  const { user } = useSessionContext();
  const navigate = useNavigate();

  const methods = useForm();
  const { handleSubmit, control, setValue } = methods;

  let selectedMedication = useWatch({ name: "medication", control });
  let selectedForm = useWatch({ name: "form", control });

  function verityInputType() {
    const option = unit.find((el: any) => el.value === selectedForm?.value);
    return option?.inputType || "text";
  }

  function extrairNumero(string: string) {
    const match = string?.match(/\d+/);
    return match ? Number(match[0]) : null;
  }

  const [medication, setMedication] = useState([]);
  const [customMedication, setCustomMedication] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const medicationOptions =
    medication?.map((el: any, index: number) => {
      const item = {
        class:
          el?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDClasseTerapeutica"
          )?.valueString || "-",
        type:
          el?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDTipoProduto"
          )?.valueString || "-",
        description:
          el?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDSubstancia"
          )?.valueString || "-",
        presentation:
          el?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDApresentacao"
          )?.valueString || "-",
        tag:
          el?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDTarja"
          )?.valueString || "-",
        name:
          el?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDProduto"
          )?.valueString || "-",
        value: index,
        code: el?.code,
      };
      return { ...item, label: `${item?.name} - ${item.presentation}` };
    }) || [];

  const onSubmit = async (data: any) => {
    try {
      const auxForm = [
        data?.quantity && `${data?.quantity}`,
        data?.form?.label && `${data?.form?.label}`,
        data?.frequency?.label && `a cada ${data?.frequency?.label}`,
        data?.time?.label && `por ${data?.time?.label}`,
      ]
        ?.filter((e: string) => e)
        ?.join(" ");
      const auxItem = {
        name: data?.medication?.name,
        label: data?.medication?.label,
        tag: data?.medication?.tag,
        inputValue: medicationOptions.find(
          (e: any) => e.value === data.medication.value
        ) || {
          name: data.medication.name,
          value: data.medication.name,
          label: data.medication.name,
        },
        quantity: data?.quantity,
        frequency: data?.frequency,
        formValue: data?.form,
        time: data?.time,
        note: data?.note,
        form: auxForm,
        type: "medication",
        code: data?.medication?.code,
        tabValue: `1`,
        practitionerId: user?.username,
        practitionerName: user?.name,
      };
      await PostMedicationRequest([auxItem], undefined, {
        subject: user?.username,
      });
    } catch (err) {
      console.log("erro medication: ", err);
      return;
    }
    setRefetch((prev: any) => !prev);
    toast.success("Medicamento registrado com sucesso!");
    setModalOpen(false);
  };

  useEffect(() => {
    async function fetchMedication() {
      const medicationResponse = await GetMedication(
        "1",
        selectedMedication?.name
      );
      setMedication(
        medicationResponse?.data?.entry?.map((el: any) => el.resource)
      );
      setLoading(false);
    }

    if (selectedMedication?.code) setCustomMedication(false);

    const debounce = setTimeout(() => {
      setLoading(true);
      fetchMedication();
    }, 500);
    return () => clearTimeout(debounce);
  }, [selectedMedication, setValue]);

  return (
    <CommonDialog handleClose={() => setModalOpen(false)} isOpen={isOpen}>
      <DialogTitle textAlign="center">
        <Typography variant="h5" fontWeight={600}>
          Registrar novo medicamento
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <FormAutoComplete
                required
                loading={loading}
                name="medication"
                freeSolo={customMedication}
                control={control}
                onInputChange={(e: any) => {
                  if (e?.target?.value?.length)
                    setValue("medication", {
                      name: e?.target?.value,
                      label: e?.target?.value,
                      value: e?.target?.value,
                    });
                }}
                label={`Selecione o medicamento`}
                renderOption={(option: any) => (
                  <PatientMedicationOption option={option} />
                )}
                noOptionsText={
                  <Button
                    sx={{ width: "fit-content", textTransform: "none" }}
                    onClick={() => setCustomMedication(true)}
                    endIcon={<Publish />}
                  >
                    Adicionar novo medicamento
                  </Button>
                }
                selectOptions={!loading ? medicationOptions : []}
              />
            </Grid>

            <Grid item xs={6}>
              <FormTextInput
                type={verityInputType()}
                name="quantity"
                control={control}
                label="Posologia"
                pattern={/^\d+(\.\d+)?\s*\w*(\s+.*)?$/}
              />
            </Grid>
            <Grid item xs={6}>
              <FormAutoComplete
                onInputChange={(e: any) => {
                  if (e?.target?.value?.length)
                    setValue("form", {
                      name: e?.target?.value,
                      label: e?.target?.value,
                      value: e?.target?.value,
                    });
                }}
                freeSolo
                name="form"
                control={control}
                label="Forma"
                selectOptions={unit}
              />
            </Grid>

            <Grid item xs={6}>
              <FormAutoComplete
                freeSolo
                onInputChange={(e: any) => {
                  if (e?.target?.value?.length) {
                    const debounce = setTimeout(() => {
                      if (extrairNumero(e?.target?.value) !== null)
                        setValue("frequency", {
                          name: `${extrairNumero(e?.target?.value)} h` || "",
                          label: `${extrairNumero(e?.target?.value)} h` || "",
                          value: extrairNumero(e?.target?.value) || "",
                        });
                      else {
                        setValue("frequency", {
                          name: "",
                          label: "",
                          value: "",
                        });
                      }
                    }, 1000);
                    return () => clearTimeout(debounce);
                  }
                }}
                name="frequency"
                control={control}
                label="Intervalo (horas)"
                selectOptions={frequency}
              />
            </Grid>
            <Grid item xs={6}>
              <FormAutoComplete
                freeSolo
                onInputChange={(e: any) => {
                  if (e?.target?.value?.length) {
                    const debounce = setTimeout(() => {
                      if (extrairNumero(e?.target?.value) !== null)
                        setValue("time", {
                          name: `${extrairNumero(e?.target?.value)} dias` || "",
                          label:
                            `${extrairNumero(e?.target?.value)} dias` || "",
                          value: extrairNumero(e?.target?.value),
                        });
                      else {
                        setValue("time", {
                          name: "",
                          label: "",
                          value: "",
                        });
                      }
                    }, 1000);
                    return () => clearTimeout(debounce);
                  }
                }}
                name="time"
                control={control}
                label="Tempo (dias)"
                selectOptions={time}
              />
            </Grid>

            <Grid item xs={12}>
              <FormTextInput
                multiline
                rows={3}
                label="Observação"
                name="note"
                control={control}
              />
            </Grid>
          </Grid>

          <Button
            component="label"
            variant="text"
            endIcon={<ArrowForward />}
            sx={{
              width: "fit-content",
              color: "primary700.main",
              margin: "8px 0",
              padding: "8px",
            }}
            onClick={() => navigate("/medicalprescription")}
          >
            <Typography
              variant="subtitle2"
              textTransform="lowercase"
              sx={{
                "&::first-letter": {
                  textTransform: "uppercase",
                },
              }}
            >
              Minhas receitas médicas
            </Typography>
          </Button>
          <Stack direction="row" gap={12}>
            <SecondaryButton
              width={"100% "}
              height="50px"
              onClick={() => navigate("/home")}
              id="goBackButton"
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              width={"100% "}
              height="50px"
              onClick={handleSubmit(onSubmit)}
            >
              Salvar
            </PrimaryButton>
          </Stack>
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
