import { FamilyRestroom } from "@mui/icons-material";
import { useSessionContext } from "../../contexts/SessionContext";
import { IFeatures } from "../../utils/interfaces";
import { Myfamily } from "./Myfamily";

export function useMyfamily() {
  const { access } = useSessionContext();

  const configs: IFeatures = {
    active: access.type === "patient",
    path: "/myfamily",
    icon: FamilyRestroom,
    text: "Minha família",

    sideMenu: true,
    sideMenuIndex: 4,

    component: () => <Myfamily />,
  };
  return configs;
}
