import { Stack, Typography } from "@mui/material";

import { TratamentoHistory } from "./TratamentoHistory";
import { MedicationForm } from "../MedicationForm";
import { useForm } from "react-hook-form";
import { InfoOutlined } from "@mui/icons-material";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { useLocation } from "react-router-dom";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { toast } from "react-toastify";
import { SyntheticEvent, useEffect, useState } from "react";
import { PrimaryButton } from "../../../../components/common";
import { PostMedicationRequest } from "../../../../services/fhir";
import { useInterventionsContext } from "../../../../contexts/InterventionsContext";

export function TratamentosDeclarado() {
  const { state } = useLocation();
  const { user } = useSessionContext();
  const { setRefetch, setLoadingTreatments } = useMedicalCareContext();

  const references = state?.encounterReference;

  const methods = useForm();
  const { handleSubmit, control, reset, setValue } = methods;

  const { action, medicationOptions } = useInterventionsContext();

  const onSubmit = async (data: any) => {
    setLoadingTreatments(true);
    if (data && references) {
      try {
        const auxForm = [
          data?.quantity && `${data?.quantity}`,
          data?.form?.label && `${data?.form?.label}`,
          data?.frequency?.label && `a cada ${data?.frequency?.label}`,
          data?.time?.label && `por ${data?.time?.label}`,
        ]
          ?.filter((e: string) => e)
          ?.join(" ");
        const auxItem = {
          name: data?.medication?.name,
          label: data?.medication?.label,
          tag: data?.medication?.tag,
          inputValue: medicationOptions.find(
            (e: any) => e.value === data.medication.value
          ) || {
            name: data.medication.name,
            value: data.medication.name,
            label: data.medication.name,
          },
          quantity: data?.quantity,
          frequency: data?.frequency,
          formValue: data?.form,
          time: data?.time,
          note: data?.note,
          form: auxForm,
          type: "medication",
          code: data?.medication?.code,
          tabValue: `${tabValue}`,
          practitionerId: user?.username,
          practitionerName: user?.name,
        };
        await PostMedicationRequest([auxItem], references, {
          subject: references?.subject,
        });
        action(auxItem);
      } catch (err) {
        toast.error("Não foi possível registrar o tratamento, tente novamente");
      } finally {
        setRender(false);
        setRefetch((prev: boolean) => !prev);
        reset();
      }
    }
  };

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    reset();
    setTabValue(newValue);
  };

  const [render, setRender] = useState<boolean>(false);

  useEffect(() => {
    if (!render) setRender(true);
  }, [render]);

  return (
    <>
      <Stack width="100%" maxHeight="65vh" overflow="auto">
        <Stack
          direction="row"
          //bgcolor="primary200.main"
          // bgcolor={bgColor ? `${bgColor}` : "transparent"}
          alignItems="center"
          spacing={1}
          width="100%"
          height="60px"
          justifyContent="left"
        >
          <InfoOutlined />
          <Typography variant="subtitle2" fontWeight={400}>
            Tratamentos declarados pelo paciente
          </Typography>
        </Stack>
        <MedicationForm
          tabValue={tabValue}
          handleChange={handleChange}
          render={render}
          control={control}
          setValue={setValue}
        />
        <Stack
          width="100%"
          direction="row"
          mt={2}
          mb={2}
          justifyContent="right"
        >
          <PrimaryButton
            onClick={handleSubmit(onSubmit)}
            width="fit-content"
            height="40px"
          >
            Salvar
          </PrimaryButton>
        </Stack>
      </Stack>

      <Stack
        width="100%"
        minWidth="600px"
        overflow="auto"
        maxHeight="60vh"
        paddingRight={3}
      >
        <TratamentoHistory />
      </Stack>
    </>
  );
}
