import { Divider, Stack } from "@mui/material";

import { useEffect, useState } from "react";
import { PDFRender } from "./PDFRender";
import { PDFViewer } from "@react-pdf/renderer";
import { useInterventionsContext } from "../../../contexts/InterventionsContext";

interface IProps {
  setBase64?: any;
  dataPdf: any;
}

export function DocumentVisualization({ setBase64, dataPdf }: IProps) {
  const [blob, setBlob] = useState<any>();
  const { selectedInterventions } = useInterventionsContext();

  // const tags = [
  //   {
  //     name: "VERMELHA",
  //     value: "CE",
  //     color: "#db3f30",
  //     display: "Controle especial - Tarja vermelha",
  //   },
  //   {
  //     name: "PRETA",
  //     value: "CE",
  //     color: "white",
  //     background: "black",
  //     display: "Controle especial - Tarja preta",
  //   },
  //   {
  //     name: "AMARELA",
  //     value: "AM",
  //     color: "#FEE2A9",
  //     display: "Receita amarela",
  //   },
  //   {
  //     name: "AZUL",
  //     value: "AZ",
  //     color: "#A4CBE8",
  //     display: "Receita azul",
  //   },
  //   {
  //     name: "SEM TARJA",
  //     value: "BR",
  //     color: "white",
  //     display: "Receita branca",
  //   },
  //   {
  //     name: "EX",
  //     display: "Exame",
  //     value: "EX",
  //     color: "#FDCF72",
  //   },
  //   {
  //     name: "AT",
  //     value: "AT",
  //     color: "#009E8C",
  //     display: "Atestado",
  //   },
  // ];

  const pdfList = [
    {
      type: "simple",
      data: selectedInterventions.medicationSimple?.filter((e: any) => {
        return !e?.sign;
      }),
    },
    {
      type: "exam",
      data: selectedInterventions.service?.filter((e: any) => !e?.sign),
    },
    {
      type: "procedure",
      data: selectedInterventions.procedure?.filter((e: any) => !e?.sign),
    },
  ];

  const blobToBase64 = (blob: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    async function generateBase64() {
      if (blob) {
        const convertToBase64 = Object?.keys(blob)?.map(async (el: any) => {
          return {
            [el]: {
              base64: await blobToBase64(blob?.[el]?.blob),
              info: blob?.[el]?.info,
              type: el,
            },
          };
        });
        if (convertToBase64) {
          const base64Result = await Promise.all(convertToBase64);
          setBase64(base64Result);
        }
      }
    }
    if (blob) generateBase64();
    else setBase64(undefined);
  }, [blob, setBase64]);

  return (
    <Stack width="100%" spacing={2}>
      {pdfList
        ?.filter((e: any) => e?.data?.length)
        ?.map((el: any, index: number, array: any) => (
          <Stack width="100%" alignItems="center" key={index}>
            <PDFViewer
              style={{ width: "1100px", height: "1150px" }}
              showToolbar={false}
            >
              <PDFRender
                dataPdf={dataPdf}
                content={el}
                blob={blob}
                setBlob={setBlob}
              />
            </PDFViewer>

            <Divider
              sx={{
                width: "100%",
                background: "neutral600.main",
                paddingY: 1,
              }}
            />
          </Stack>
        ))}

      {selectedInterventions.certificates
        ?.filter((e: any) => !e?.sign)
        ?.map((el: any, index: number, array: any) => {
          const auxContent = { type: `certificate-${index}`, data: [el] };
          return (
            <Stack
              justifyContent="center"
              alignItems="center"
              key={`certificate-${index}`}
            >
              <PDFViewer
                style={{ width: "1100px", height: "1150px" }}
                showToolbar={false}
              >
                <PDFRender
                  index={index}
                  dataPdf={dataPdf}
                  content={auxContent}
                  blob={blob}
                  setBlob={setBlob}
                />
              </PDFViewer>
              <Divider
                sx={{
                  width: "100%",
                  background: "neutral600.main",
                  paddingY: 1,
                }}
              />
            </Stack>
          );
        })}

      {selectedInterventions.medicationSpecial
        ?.filter((e: any) => !e?.sign)
        ?.map((el: any, index: number, array: any) => {
          const auxContent = { type: `special-${index}`, data: [el] };
          return (
            <Stack width="100%" alignItems="center" key={`special-${index}`}>
              <PDFViewer
                style={{ width: "1100px", height: "1150px" }}
                showToolbar={false}
              >
                <PDFRender
                  index={index}
                  dataPdf={dataPdf}
                  content={auxContent}
                  blob={blob}
                  setBlob={setBlob}
                />
              </PDFViewer>

              <Divider
                sx={{
                  width: "100%",
                  background: "neutral600.main",
                  paddingY: 1,
                }}
              />
            </Stack>
          );
        })}
    </Stack>
  );
}
