import { GenericTimeline } from "../GenericTimeline";
import { Stack } from "@mui/material";

import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import dayjs from "dayjs";
import { PlanoDeTratamentoAccordion } from "./PlanoDeTratamentoAccordion";

const style: any = {
  Concluído: "#007C6D",
  Parcialmente: "#FBBB3C",
  Falhou: "#EF4352",
};

const styleType = {
  "long-term": "#183B56",
  "short-term": "#5F6C85",
};

export function PlanoDeTratamentoHistory() {
  const { response } = useQuestionnaireContext();

  const responseEntries = response?.entry?.map((el: any) => el?.resource);

  const treatmentPlansResponses = responseEntries?.map((el: any) => {
    const answerDate = dayjs(el?.authored)?.format("DD MMM YYYY");
    const filterItems = el?.item
      ?.filter((e: any) => e?.text === "Planos de tratamento")?.[0]
      ?.item?.filter((subItem: any) => {
        return (
          // subItem?.item?.[1]?.answer?.[0]?.valueCoding &&
          subItem?.item?.[2]?.answer?.[0]?.valueCoding &&
          subItem?.item?.[0]?.answer
        );
      });
    const displayReferences = el?.item?.filter(
      (e: any) => e?.linkId === "displayReferences"
    )?.[0]?.item;
    const displayInfo = {
      healthcare: displayReferences?.[0]?.answer?.[0]?.valueString || "",
      practitioner: displayReferences?.[1]?.answer?.[0].valueString || "",
    };
    return { answerDate, filterItems, displayInfo };
  });

  const verifyResponse = treatmentPlansResponses?.filter(
    (el: any) => el.filterItems?.length
  );

  return (
    <Stack spacing={1}>
      <GenericTimeline
        data={verifyResponse}
        dotColor={style}
        component={(data: any, index: number) => (
          <PlanoDeTratamentoAccordion
            style={style}
            styleType={styleType}
            data={data}
            index={index}
          />
        )}
      />
    </Stack>
  );
}
