import { toast } from "react-toastify";
import { endpoints } from "../../../../configs/Settings";
import { HTTPResponseStatus } from "../../../../utils/enum";

import { useAuth } from "../../../../utils/useAuth";
import { useAxios } from "../../../../utils/useAxios";
import { auxiliarData } from "../../AuxiliarData";

export async function PostMedicationStatement(
  objData: any,
  encounter?: any,
  patientIdentifier?: any
) {
  let url = `${endpoints.EHRPOSTER}/MedicationStatement`;

  const data = auxiliarData.MedicationStatement(
    objData,
    encounter,
    patientIdentifier
  );

  const headers = {
    Accept: "application/fhir+json",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };

  const response = await useAxios
    .post(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (
        response.status !== HTTPResponseStatus.Created &&
        response.status !== HTTPResponseStatus.Success
      ) {
        throw new Error(`PostMedicationStatement: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("PostMedicationStatement ", error);
      // toast.error(`PostMedicationStatement Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
