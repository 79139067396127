import { useEffect } from "react";
import { LargeContentBox } from "../../components/common";
import { PrivateMainLayout } from "../../components/layout";

import { Form } from "./components/form/Form";
import { useLocationContext } from "../../contexts/LocationContext";
import { useHealthcareServiceContext } from "../../contexts/HealthcareServiceContext";

export function Scheduler() {
  const { refetchLocation } = useLocationContext();
  const { refetchHealthcareService } = useHealthcareServiceContext();

  useEffect(() => {
    refetchLocation();
    refetchHealthcareService();
  }, []);
  return (
    <PrivateMainLayout>
      <LargeContentBox>
        <Form />
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
