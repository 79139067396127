import { Box, Button, Stack, Typography } from "@mui/material";
import { CardDescription } from "./CardDescription";
import { useFormatter } from "../../utils/useFormatter";
import { useSessionContext } from "../../contexts/SessionContext";
import { useDatetime } from "../../utils/useDatetime";
import dayjs from "dayjs";
import { VideoCameraFront } from "@mui/icons-material";
import VideoConferencia from "../videoConferencia/VideoConferencia";
import { useClinicalRegisterContext } from "../../contexts/ClinicalRegisterContext";

interface IProps {
  data: any;
  selected: number;
  index: number;
  onClick: any;
  locationTeleconsulting?: boolean;
  setOpenAppointmentDetails?: any;
}

const statusColor: any = {
  cancelled: "error400.main",
  proposed: "neutral300.main",
  pending: "neutral300.main",
  arrived: "primary300.main",
  booked: "primary300.main",
};
const internalStatusColor: any = {
  cancelled: "error600.main",
  proposed: "neutral600.main",
  pending: "neutral600.main",
  arrived: "primary700.main",
  booked: "primary700.main",
};
const translateName: any = {
  cancelled: "Cancelado",
  proposed: "Pendente",
  pending: "Pendente",
  arrived: "Confirmado",
  booked: "Confirmado",
};

export function CardSchedule({
  data,
  selected,
  index,
  onClick,
  locationTeleconsulting,
  setOpenAppointmentDetails,
}: IProps) {
  const { access, user } = useSessionContext();
  const { inProgress } = useClinicalRegisterContext();
  const { formatDateTimeToHour, formatDateTimeToWeekdayDate } = useFormatter;
  const { formatDate } = useDatetime;
  const isLate = dayjs(new Date(data.startTime))
    ?.add(10, "minute")
    ?.isBefore(dayjs());

  const status = data.status;
  const { formatNameDisplay } = useFormatter;

  function generateBorderColor() {
    if (inProgress === data?.id) {
      return "#CBD2E1";
    }
    if (isLate && access?.type === "professional") {
      return "#FBBB3C";
    }
    if (selected === index) {
      return internalStatusColor[status];
    } else {
      return statusColor[status];
    }
  }

  function generateBackgroundColor() {
    if (inProgress === data?.id) {
      return "#CBD2E1";
    }
    if (isLate && access?.type === "professional") {
      return "#FBBB3C";
    } else {
      return statusColor[status];
    }
  }

  function generateInternalColor() {
    if (inProgress === data?.id) {
      return "#3F444D";
    }
    if (isLate && access?.type === "professional") {
      return "#DB7712";
    } else {
      return internalStatusColor[status];
    }
  }

  function generateText() {
    if (inProgress === data?.id) {
      return "Iniciado";
    }
    if (isLate && access?.type === "professional") {
      return "Atrasado";
    } else {
      return translateName[status];
    }
  }

  return (
    <Button
      sx={{
        margin: 0,
        padding: 0,
        border: `${selected === index ? "3px solid" : "1px solid"}`,
        borderColor: generateBorderColor(),
        borderRadius: "10px",
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        gap: "20px",
        color: "inherit",
        marginY: "5px",
        boxSizing: "border-box",
      }}
      onClick={onClick}
    >
      <Stack width="100%" direction={{ xs: "column", sm: "row" }}>
        <Stack
          display="flex"
          flexDirection="column"
          spacing={1}
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: generateBackgroundColor(),
            padding: "8px 16px",
            borderRadius: "7px 0px 0px 7px",
          }}
          width={{ xs: "100%", sm: "20%" }}
          minWidth="128px"
          height="100%"
        >
          <Typography variant="subtitle2" fontWeight={400}>
            {formatDateTimeToWeekdayDate(data.startTime) || "-"}
          </Typography>
          <Typography variant="body2">
            {formatDateTimeToHour(data.startTime) || "-"}
          </Typography>
          <Box
            width="100%"
            sx={{
              color: "neutral0.main",
              backgroundColor: generateInternalColor(),
              borderRadius: "5px",
            }}
          >
            <Typography textTransform="capitalize" sx={{ padding: "1px" }}>
              {generateText()}
            </Typography>
          </Box>
        </Stack>

        <Stack minWidth={350} width="100%" alignSelf="center" ml={3}>
          {access.type === "patient" ? (
            <>
              <CardDescription
                title="Médico"
                description={data.practitioner}
                titleSize="60px"
              />
              <CardDescription
                title="Serviço"
                description={data.healthcare}
                titleSize="60px"
              />
              <CardDescription
                title="Clínica"
                description={data.location}
                titleSize="60px"
              />
            </>
          ) : (
            <>
              <CardDescription
                title="Paciente"
                description={formatNameDisplay(data.patientName)}
                titleSize="90px"
              />
              <CardDescription
                title="Nascimento"
                description={
                  dayjs(data.patientBirthday).isValid()
                    ? `${formatDate(
                        data.patientBirthday
                      )} - ${useFormatter.formatAge(data.patientBirthday)} anos`
                    : data.patientBirthday
                }
                titleSize="90px"
              />
              <CardDescription
                title="Sexo"
                description={data.patientSex}
                titleSize="90px"
              />
            </>
          )}
        </Stack>

        <Stack
          direction="row-reverse"
          alignSelf={access.type === "patient" ? "flex-start" : "center"}
          justifyContent="space-between"
          width="100%"
          mr={2}
        >
          <Stack
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            mt={1}
          >
            {" "}
            {access.type === "patient" && (
              <Typography
                variant="body1"
                textTransform="capitalize"
                color="primary800.main"
                onClick={() => setOpenAppointmentDetails(true)}
                sx={{ "&:hover": { textDecoration: "underline" } }}
              >
                Detalhes
              </Typography>
            )}
            {locationTeleconsulting && (
              <>
                {access?.type === "patient" ? (
                  <VideoConferencia
                    roomName={`Teleconsulta-${data?.id}`}
                    displayName={user.name}
                    email={user?.email?.[0]}
                    cardSchedule
                  />
                ) : (
                  <VideoCameraFront />
                )}
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Button>
  );
}
