import { Box, Button, Stack, Tab, Tabs } from "@mui/material";
import { ExamsFields } from "../ExamsFields";
import { Add } from "@mui/icons-material";
import { ReactNode, SyntheticEvent, useState } from "react";
import { ProcedureAndCertificate } from "../ProcedureAndCertificate";
import { useInterventionsContext } from "../../../../contexts/InterventionsContext";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ maxHeight: "60vh", pt: 2, overflow: "auto" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function ProcedureAndExams({ setSelect }: any) {
  const { subTabValue, setSubTabValue } = useInterventionsContext();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setSubTabValue(newValue);
  };

  return (
    <Stack width="100%" maxHeight="65vh" overflow="auto">
      <Button
        sx={{ width: "fit-content", textTransform: "none" }}
        startIcon={<Add />}
        onClick={() => setSelect(true)}
      >
        Selecionar protocolo salvo
      </Button>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={subTabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Exames" {...a11yProps(0)} />
          <Tab label="Procedimentos" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={subTabValue} index={0}>
        <ExamsFields />
      </CustomTabPanel>
      <CustomTabPanel value={subTabValue} index={1}>
        <ProcedureAndCertificate />
      </CustomTabPanel>
    </Stack>
  );
}
