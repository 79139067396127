import { TableBody, TableCell, TableRow } from "@mui/material";
import { StatusRow } from "./StatusRow";

export function GenericTableBody({
  rows,
  startIndex,
  endIndex,
  rowPath,
  actions,
}: any) {
  return (
    <TableBody>
      {rows?.slice(startIndex, endIndex).map((row: any, index: number) => {
        return (
          <TableRow
            key={index}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              backgroundColor: row.ends === "Inativo" ? "grey.100" : "inherit", // Estiliza como desabilitado se inativo
            }}
          >
            {rowPath?.map((el: any, index: number) => (
              <TableCell
                sx={{
                  textDecoration:
                    row.ends === "Inativo" ? "line-through" : "none",
                }}
                align="center"
                key={`tableBodyCell-${index}`}
              >
                {el === "status" ? (
                  <StatusRow status={row?.[el]} />
                ) : (
                  row?.[el] || "-"
                )}
              </TableCell>
            ))}
            {actions && actions(row)}
          </TableRow>
        );
      })}
    </TableBody>
  );
}
