import { toast } from "react-toastify";
import { endpoints } from "../../configs/Settings";
import { HTTPResponseStatus } from "../../utils/enum";
import { useAuth } from "../../utils/useAuth";
import { useAxios } from "../../utils/useAxios";

export async function GetPolicy(patientID: string) {
  const url = `${endpoints.CONSENT}/recurso/${patientID}/politica`;
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/json",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`Get Policy: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetPolicy ", error);
      // toast.error(`GetPolicy Erro: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
