import { IconButton, TableCell, Tooltip } from "@mui/material";
import { Description, PersonAdd } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { useFormatter } from "../../../utils/useFormatter";
import { useState } from "react";
import { CreateAppointmentDialog } from "../../schedulemanager/components/creatappointmentdialog";

export function PatientSearchActions({ row }: any) {
  const navigate = useNavigate();
  const [createAppointmentDialogOpen, setCreateAppointmentDialogOpen] =
    useState<boolean>(false);
  return (
    <TableCell align="center">
      <Tooltip title="Realizar encaixe">
        <IconButton onClick={() => setCreateAppointmentDialogOpen(true)}>
          <PersonAdd />
        </IconButton>
      </Tooltip>
      {createAppointmentDialogOpen && (
        <CreateAppointmentDialog
          isOpen={createAppointmentDialogOpen}
          setModalOpen={setCreateAppointmentDialogOpen}
          cpfT={useFormatter.formatCPF(row?.identifier)}
          walkin
        />
      )}

      <Tooltip title="Acessar Registros ">
        <IconButton
          onClick={() => {
            if (row.identifier)
              navigate("/summary", {
                state: {
                  patientId: row?.identifier?.replace(/[^a-zA-Z0-9]/g, ""),
                },
              });
          }}
        >
          <Description />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
}
