export function IframeVisualization({ data }: any) {
  return (
    <iframe
      title="PDF Viewer"
      src={`${data}#toolbar=0`}
      width="fit-content"
      height="100%"
    />
  );
}
