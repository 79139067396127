import { Dialog, IconButton, Stack } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../../../components/common";
import { PostDocumentReference } from "../../../services/fhir/patientsummary/documentreference/PostDocumentReference";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { Close } from "@mui/icons-material";

export function ConfirmDocumentDialog({
  fileBase64,
  setRefetch,
  isOpenDialog,
  onCloseDialog,
  setRenderButton,
  data,
  encounterReference,
  basedOn,
}: any) {
  const basedOnReference = basedOn
    ?.map(
      (el: any) =>
        `<fhir:basedOn>
      <fhir:reference value="${el.reference}"/>
      </fhir:basedOn>
      `
    )
    ?.join("");

  const handleConfirm = async () => {
    try {
      await PostDocumentReference(data, encounterReference, basedOnReference);
      toast.success("Resultado de exame, enviado com sucesso!");
      if (setRenderButton) setRenderButton(false);
      setRefetch((prev: boolean) => !prev);
    } catch (err) {
      console.log("PostDocument", err);
      toast.warn("Não foi possível finalizar a operação, tente novamente");
    } finally {
      onCloseDialog();
    }
  };

  const handleDialogClose = (event: any, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      onCloseDialog(event, reason);
    }
  };

  return (
    <Dialog
      open={isOpenDialog}
      onClose={handleDialogClose}
      PaperProps={{
        sx: { padding: "8px 10px" },
      }}
      maxWidth="md"
      fullScreen={isMobile}
    >
      <Stack width={{ xs: "100%", md: "700px" }}>
        <Stack mb={5}>
          <IconButton
            onClick={() => onCloseDialog(null, "iconButtonClick")}
            sx={{ position: "absolute", right: 5, top: 5 }}
          >
            <Close />
          </IconButton>
        </Stack>
        <iframe height="700px" title="addFile" src={fileBase64} />

        <Stack direction="row" justifyContent="space-between" gap={2} mt={1}>
          <SecondaryButton
            width="250px"
            height="50px"
            onClick={() => onCloseDialog()}
            id="goBackButton"
          >
            Voltar
          </SecondaryButton>

          <PrimaryButton width="250px" height="50px" onClick={handleConfirm}>
            Confirmar
          </PrimaryButton>
        </Stack>
      </Stack>
    </Dialog>
  );
}
