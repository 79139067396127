import { Text, View, StyleSheet } from "@react-pdf/renderer";
import dayjs from "dayjs";
import { useFhirData } from "../../../utils/useFhirData";

const styles = StyleSheet.create({
  sectionProcedure: {
    padding: 40,
    flexDirection: "column",
    flexGrow: 1,
  },
  headerProcedure: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    height: 80,
    textTransform: "uppercase",
    marginBottom: 20,
  },
  contentProcedure: {
    flexGrow: 1,
    marginBottom: 0,
  },
  footerProcedure: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: 6,
  },
  titleProcedure: {
    fontSize: 22,
    width: 320,
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
    textTransform: "uppercase",
  },
  text: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
    padding: "5px 0px",
  },
  textResponse: {
    fontSize: 12,
    fontFamily: "Helvetica",
    textTransform: "uppercase",
  },
  box: {
    marginBottom: 10,
  },
  footerContainer: {
    marginTop: 6,
  },
  marginTopSecondPage: {
    marginTop: 40,
  },
  marginTopSubsequentPages: {
    marginTop: 30,
  },
  pageContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
});

export function ProcedureRecipe({ filterProcedure, dataPdf }: any) {
  const crm = dataPdf?.[0]?.professionalData?.identifier?.[0]?.value
    .split("/")[1]
    .slice(2);

  const ufCrm = dataPdf?.[0]?.professionalData?.identifier?.[0]?.value
    .split("/")[1]
    .slice(0, 2);

  const splitProceduresIntoPages = (procedures: any[]) => {
    const pages = [];
    for (let i = 0; i < procedures.length; i += 10) {
      if ((i === 0 && procedures.length === 9) || procedures.length === 10) {
        pages.push(procedures.slice(i, i + 9));
        pages.push(procedures.slice(i + 9));
      } else {
        pages.push(procedures.slice(i, i + 10));
      }
    }
    return pages;
  };

  const procedurePages = splitProceduresIntoPages(filterProcedure);

  const renderProcedures = (procedures: any[]) => (
    <View style={{ height: "auto", width: "100%", backgroundColor: "#e6e6e6" }}>
      {procedures.map((el: any, index: number) => (
        <View key={index} style={styles.box} wrap={false}>
          <Text style={styles.text}>
            Nome do Procedimento:
            <Text style={styles.textResponse}> {el?.name} </Text>
          </Text>
          {el.note && (
            <Text style={styles.text}>
              Obs:
              <Text style={styles.textResponse}> {el?.note} </Text>
            </Text>
          )}
        </View>
      ))}
    </View>
  );

  const renderFooter = () => (
    <View style={styles.footerContainer}>
      <View style={styles.footerProcedure}>
        <Text style={styles.text}>
          Nome do(a) Médico(a):
          <Text style={styles.textResponse}>
            {dataPdf?.[0]?.professionalData?.practitioner.display || ""}
          </Text>
        </Text>
        <View style={{ display: "flex", flexDirection: "row", gap: 3 }}>
          <Text style={styles.text}>
            CRM: <Text style={styles.textResponse}>{crm || ""}</Text>
          </Text>
          <Text style={styles.text}>
            UF: <Text style={styles.textResponse}>{ufCrm || ""}</Text>
          </Text>
        </View>
      </View>

      <View style={styles.footerProcedure}>
        <Text style={styles.text}>
          Local de Atendimento:
          <Text style={styles.textResponse}>
            {dataPdf?.[0]?.location?.alias?.[0] || "-"}
          </Text>
        </Text>
        <Text style={styles.text}>
          Endereço:
          <Text style={styles.textResponse}>
            {useFhirData.formatAddress(dataPdf?.[0].location)}
          </Text>
        </Text>
        <Text style={styles.text}>
          Data de Emissão:
          <Text style={styles.textResponse}>
            {dayjs().format("DD/MM/YYYY")}
          </Text>
        </Text>
      </View>
    </View>
  );

  return (
    <View style={styles.sectionProcedure}>
      <View style={styles.headerProcedure}>
        <Text style={styles.titleProcedure}>Procedimentos</Text>
      </View>

      {procedurePages.map((pageProcedures, index) => (
        <View
          key={index}
          break={index > 0}
          style={[
            styles.pageContainer,
            index > 0 ? styles.marginTopSubsequentPages : {},
          ]}
        >
          <View style={styles.contentProcedure}>
            {index === 0 && (
              <>
                <Text style={styles.text}>
                  Paciente:
                  <Text style={styles.textResponse}>
                    {dataPdf?.[0]?.patientData || ""}
                  </Text>
                </Text>
                <Text style={styles.text}>Prescrição:</Text>
              </>
            )}
            {renderProcedures(pageProcedures)}
          </View>

          {index === procedurePages.length - 1 && renderFooter()}
        </View>
      ))}
    </View>
  );
}
