import React from "react";
import { Typography, Stack } from "@mui/material";

import logo from "../../../assets/fluxmed-logo-horizontal.png";
import {
  ContentBoxTitle,
  PrimaryButton,
  PrimaryLink,
} from "../../../components/common";
import { FormTextInput } from "../../../components/form";
import { useFormatter } from "../../../utils/useFormatter";
import { useRegex } from "../../../utils/useRegex";

export function ContactForm({ onClick, control, loading }: any) {
  return (
    <Stack
      height="100%"
      justifyContent={{ xs: "flex-start", sm: "center" }}
      alignItems="center"
      width="100%"
      spacing={4}
    >
      <img src={logo} alt="Logo fluxmed" width={185} height={44} />
      <ContentBoxTitle>Problemas para entrar?</ContentBoxTitle>
      <Typography
        variant="body1"
        sx={{ color: "neutral600.main" }}
        textAlign="center"
      >
        Insira o número de CPF associado à sua conta Fluxmed.
      </Typography>
      <FormTextInput
        name="credential"
        control={control}
        label="CPF"
        pattern={useRegex.cpf}
        mask={useFormatter.formatCPF}
        maxLength={14}
        autoFocus
        placeholder="Digite seu CPF"
        required
      />
      <PrimaryButton
        width="100%"
        height="52px"
        onClick={onClick}
        loading={loading}
      >
        Continuar
      </PrimaryButton>
      <Stack direction={{ sm: "column", md: "row" }} alignItems="center">
        <Typography variant="body1">Lembra da sua senha? &ensp;</Typography>
        <PrimaryLink link="/signin" text="Entrar" />
      </Stack>
    </Stack>
  );
}
