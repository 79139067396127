import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

import dayjs from "dayjs";
import { GenericTable } from "../../../../../components/GenericTable/GenericTable";
import { ServiceRequestActions } from "./ServiceRequestAction";
import { DocumentReference } from "fhir/r5";
import { useDict } from "../../../../../utils/useDict";
import { ReactNode, SyntheticEvent, useState } from "react";

const normalizeDictKey = (key: string): string => {
  return key.toLowerCase().replace(/\s+/g, "");
};

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ maxHeight: "60vh", pt: 2, overflow: "auto" }}>
          {children}
        </Box>
      )}
    </div>
  );
}
export function ServiceRequest({
  data,
  title,
  handleOpen,
  setBinaryUrl,
  setRefetch,
  resultsData,
  exames,
}: any) {
  const results = resultsData?.[0]?.entry;

  const documentReference = data?.[0]?.entry?.filter(
    (el: any) =>
      el?.resource?.resourceType === "DocumentReference" &&
      el?.resource?.category?.[0].coding?.[0].display !== "Procedimento"
  );

  const columns = [
    {
      column: "Descrição",
      rowName: "description",
    },
    { column: "Data", rowName: "date" },
    { column: "Categoria", rowName: "category" },
    { column: "Profissional solicitante", rowName: "practitioner" },
  ];

  const rowData = exames?.[0]?.entry
    ?.filter(
      (valueFilter: any) =>
        valueFilter?.resource?.category?.[0]?.text !== "procedure"
    )
    ?.map((el: any, index: number) => {
      const description =
        el?.resource?.code?.concept?.coding?.[0]?.display || "-";
      const date = dayjs(el?.resource?.authoredOn).format("DD/MM/YYYY") || "-";

      const categoryDisplay =
        el?.resource?.category?.[0]?.coding?.[0]?.display || "-";
      const category =
        useDict.category[normalizeDictKey(categoryDisplay)] || categoryDisplay;

      const practitioner = el?.resource?.contained?.[0]?.name?.[0]?.text || "-";
      const status = el?.resource?.status;

      const docReference = documentReference?.find(
        (doc: fhir5.BundleEntry<DocumentReference | any>, index: number) => {
          const findRef = doc?.resource?.basedOn?.find((based: any) =>
            based?.reference?.includes(el?.resource?.id)
          );

          return findRef;
        }
      );

      const auxResults = results?.find((result: any) => {
        const findRef =
          result?.resource?.basedOn
            ?.find((bas: any) => bas.reference.includes("ServiceRequest"))
            ?.reference?.split("/")?.[1] === el?.resource?.id;

        return findRef;
      });

      const resultUrl =
        auxResults?.resource?.content?.[0]?.attachment?.url || "";

      const binaryUrl =
        docReference?.resource?.content?.[0]?.attachment?.url || "";

      return {
        description,
        date,
        category,
        practitioner,
        resultUrl,
        binaryUrl,
        status,
      };
    })
    ?.sort((a: any, b: any) => {
      const dateA = dayjs(a.date, "DD/MM/YYYY");
      const dateB = dayjs(b.date, "DD/MM/YYYY");
      return dateB.diff(dateA);
    });

  const rows =
    documentReference
      ?.filter(
        (filterEl: any) =>
          filterEl?.resource?.category?.[0]?.coding?.[0]?.display ===
          "Solicitação"
      )
      .map((el: fhir5.BundleEntry<DocumentReference | any>, index: number) => {
        const auxPractitioner = el?.resource?.contained.find(
          (contained: any) => contained?.resourceType === "Practitioner"
        );
        const auxSpecialty =
          el?.resource?.practiceSetting?.coding?.[0]?.display;

        const auxDescription = exames?.[0]?.entry?.filter((request: any) => {
          const findRef = el?.resource?.basedOn?.find((basedOn: any) =>
            basedOn?.reference.includes(request.resource.id)
          );
          return findRef;
        });

        const auxResults = results?.find((result: any) => {
          const findRef = auxDescription?.find(
            (service: any) =>
              service?.resource?.id ===
              result?.resource?.basedOn
                ?.find((bas: any) => bas.reference.includes("ServiceRequest"))
                ?.reference?.split("/")?.[1]
          );
          return findRef;
        });

        const resultUrl =
          auxResults?.resource?.content?.[0]?.attachment?.url || "";

        const auxType = el?.resource?.type?.coding?.[0]?.display || "-";

        return {
          id: index,
          type: auxType,
          description:
            auxDescription?.map((serviceRequest: any) => (
              <p>
                {serviceRequest?.resource?.code?.concept?.coding?.[0]?.display}
              </p>
            )) || "",

          date: dayjs(el?.resource?.date).format("DD/MM/YYYY") || "-",
          specialty: auxSpecialty || "-",

          practitioner:
            auxPractitioner?.name?.[0]?.given?.[0] ||
            auxPractitioner?.identifier?.[0]?.value,
          binaryUrl: el?.resource?.content?.[0]?.attachment?.url || "",
          auxResults,
          resultUrl,
          fullResource: el?.resource,
        };
      }) || [];

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Accordion sx={{ width: "100%" }} elevation={5}>
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack direction="row" alignItems="center" gap={1}>
          {title}
          <Avatar
            sx={{
              width: 26,
              height: 26,
              fontSize: "14px",
              fontWeight: 600,
              backgroundColor: "primary100.main",
              color: "primary1000.main",
            }}
          >
            {rows?.length || "0"}
          </Avatar>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
          >
            <Tab label="Realizados" {...a11yProps(0)} />
            <Tab label="Pendentes" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <CustomTabPanel value={selectedTab} index={0}>
          <GenericTable
            tableColumns={columns}
            tableRows={rows?.filter((el: any) => el?.auxResults)}
            actions={(rowData: any) => (
              <ServiceRequestActions
                row={rowData}
                handleOpen={handleOpen}
                setBinaryUrl={setBinaryUrl}
              />
            )}
          />
        </CustomTabPanel>

        <CustomTabPanel value={selectedTab} index={1}>
          <GenericTable
            tableColumns={columns}
            tableRows={rows?.filter((el: any) => !el?.auxResults)}
            actions={(rowData: any) => (
              <ServiceRequestActions
                setRefetch={setRefetch}
                row={rowData}
                handleOpen={handleOpen}
                setBinaryUrl={setBinaryUrl}
              />
            )}
          />
        </CustomTabPanel>
      </AccordionDetails>
    </Accordion>
  );
}
