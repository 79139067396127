import { Typography } from "@mui/material";
import { FormSelectInput } from "../../../components/form";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";

export function OrganizationOptions({ control }: any) {
  const { organizationList } = useOrganizationContext();

  const options = organizationList
    ?.map((e: any) => ({
      value: e?.resource?.id,
      display: `${e?.resource?.alias?.[0]}`,
    }))
    ?.filter((e: any) => e.value);

  return (
    <>
      <Typography variant="body1" fontWeight={400}>
        Selecione a unidade de saúde com quem você deseja compartilhar suas
        informações de saúde:
      </Typography>

      <FormSelectInput
        name="organization"
        label="Unidade de saúde"
        selectOptions={options || []}
        control={control}
        required
      />
    </>
  );
}
