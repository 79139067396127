import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { useAxios } from "../../../utils/useAxios";

export async function ManoleVerification(terms: any, auth: any) {
  const url = `${endpoints.Interdrugs}/interactions`;
  const data = {
    terms: terms,
  };

  const headers = {
    Accept: "*/*",
    Authorization: `Bearer ${auth}`,
    "Content-Type": "application/json",
  };
  const response = await useAxios
    .post(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== 201) {
        throw new Error(`InterDrugs Verification error: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("ManoleVerification ", error);
      // toast.error(`ManoleVerification Erro: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
