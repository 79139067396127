import { Document, Page, StyleSheet } from "@react-pdf/renderer";
import { MedicationSimpleRecipe } from "./MedicationSimpleRecipe";
import { MedicationSpecialControl } from "./MedicationSpecialControl";
import { ExamSolicitation } from "./ExamSolicitation";
import { MedicalCertificate } from "./MedicalCertificate";
import { ProcedureRecipe } from "./ProcedureRecipe";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#f9f9f9",
    width: "100%",
  },
});

export function PDFRender({ dataPdf, content, setBlob, blob }: any) {
  return (
    <Document
      onRender={(props) => {
        if (!blob) {
          setBlob((prev: any) => ({
            ...prev,
            [content?.type]: { blob: props?.blob, info: content?.data },
          }));
        }
      }}
      style={{ width: "100%" }}
    >
      {/* Condicional para cada tipo de conteúdo */}
      {content?.type === "simple" && (
        <Page wrap={true} size="A4" style={styles.page}>
          <MedicationSimpleRecipe
            filterMedication={content?.data}
            dataPdf={dataPdf}
          />
        </Page>
      )}

      {content?.type.includes("special") && (
        <Page wrap={true} size="A4" style={styles.page}>
          <MedicationSpecialControl
            filterSpecialMedication={content?.data}
            dataPdf={dataPdf}
          />
        </Page>
      )}

      {content?.type.includes("procedure") && (
        <Page wrap={true} size="A4" style={styles.page}>
          <ProcedureRecipe filterProcedure={content?.data} dataPdf={dataPdf} />
        </Page>
      )}

      {content?.type === "exam" && (
        <Page wrap={true} size="A4" style={styles.page}>
          <ExamSolicitation filterExam={content?.data} dataPdf={dataPdf} />
        </Page>
      )}

      {content?.type.includes("certificate") && (
        <Page wrap={true} size="A4" style={styles.page}>
          <MedicalCertificate
            filterMedicalCertificate={content?.data}
            dataPdf={dataPdf}
          />
        </Page>
      )}
    </Document>
  );
}
