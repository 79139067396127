import { endpoints } from "../../../configs/Settings";
import { OAuthApplication } from "../../auth/OAuthApplication";
import { useAxios } from "../../../utils/useAxios";
import { auxiliarData } from "../AuxiliarData";
import { HTTPResponseStatus } from "../../../utils/enum";

export async function SignUpValidate(objData: any) {
  const url = `${endpoints.SIGNUP}/1.0.0/validate`;
  const data = auxiliarData.SignUpValidate(objData);
  const bearerToken = await OAuthApplication();

  const headers = {
    Accept: "*/*",
    Authorization: bearerToken,
    "Content-Type": "application/json",
  };

  const response = await useAxios
    .post(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Created) {
        throw new Error(`OAuthRequest error: ${response.status}`);
      }
      return { status: HTTPResponseStatus.Created, data: response.data };
    })
    .catch((error) => {
      if (error?.response.status === HTTPResponseStatus.BadRequest) {
        return { status: HTTPResponseStatus.BadRequest, data: "CPF inválido" };
      }
      if (error?.response.status === HTTPResponseStatus.Conflict) {
        if (error.response.data.detail.includes("User already exists")) {
          return {
            status: HTTPResponseStatus.Conflict,
            data: "Usuário já cadastrado na plataforma",
          };
        }
        return {
          status: HTTPResponseStatus.BadRequest,
          data: "Dados não encontrados na base da receita federal",
        };
      }
      return {
        status: HTTPResponseStatus.BadRequest,
        data: "Houve um problema interno. Por favor, tente novamente mais tarde",
      };
    });
  if (response) return response;
}
