import { Stack } from "@mui/material";
import { CardJourney } from "./CardJourney";
import {
  ContentPaste,
  Difference,
  FamilyRestroom,
  FileCopy,
  ListAltOutlined,
  Medication,
  Restore,
  Search,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ExamSolicitation from "../../examsolicitation/ExamSolicitation";
import MedicalPrescription from "../../medicalprescription/MedicalPrescription";
import MedicalNote from "../../medicalnote/MedicalNote";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { usePatientHealthCareInformationContext } from "../../../contexts/PatientHealthCareInformationContext";
import { NewMedication } from "../../NewMedication/NewMedication";
import { Procedures } from "../../procedures/Procedures";
import { Myfamily } from "../../myfamily/Myfamily";

export default function JourneyCards() {
  const {
    documentReferenceExam,
    documentReferenceNote,
    documentReferencePrescription,
  } = usePatientHealthCareInformationContext();
  const [openMedicalPrescription, setOpenMedicalPrescription] =
    useState<boolean>(false);
  const [openMedicalRequest, setOpenMedicalRequest] = useState<boolean>(false);
  const [openProcedures, setOpenProcedures] = useState<boolean>(false);
  const [openMedicalCertificate, setOpenMedicalCertificate] =
    useState<boolean>(false);
  const [openNewMedication, setOpenNewMedication] = useState<boolean>(false);
  const [openFamily, setOpenFamily] = useState<boolean>(false);

  const navigate = useNavigate();
  return (
    <Stack
      width="100%"
      maxWidth={{ xs: "87vw", sm: "100%" }}
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{
        borderRadius: { xs: "8px", md: "30px" },
      }}
    >
      <Stack
        direction="row"
        width="100%"
        overflow={{ xs: "auto", md: "visible" }}
        spacing={{ xs: 1, sm: 2 }}
        mb={{ xs: 1 }}
      >
        <CardJourney
          icon={<FamilyRestroom sx={{ color: "primary700.main" }} />}
          text="Minha familia"
          width="100%"
          onClick={() =>
            isMobile ? setOpenFamily(true) : navigate("/myfamily")
          }
        />

        <CardJourney
          icon={<ListAltOutlined sx={{ color: "primary700.main" }} />}
          text="Solicitações de exames"
          notification={documentReferenceExam?.length}
          width="100%"
          onClick={() =>
            isMobile
              ? setOpenMedicalPrescription(true)
              : navigate("/examsolicitation")
          }
        />
        <CardJourney
          icon={<Difference sx={{ color: "primary700.main" }} />}
          text="Receitas médicas"
          notification={documentReferencePrescription?.length}
          width="100%"
          onClick={() =>
            isMobile
              ? setOpenMedicalRequest(true)
              : navigate("/medicalprescription")
          }
        />

        <CardJourney
          icon={<Medication sx={{ color: "primary700.main" }} />}
          text="Novo medicamento"
          width="100%"
          // notification={documentReferenceNote?.length}
          onClick={() =>
            isMobile ? setOpenNewMedication(true) : navigate("/newmedication")
          }
        />

        <CardJourney
          icon={<ContentPaste sx={{ color: "primary700.main" }} />}
          text="Procedimentos"
          width="100%"
          // notification={documentReferenceNote?.length}
          onClick={() =>
            isMobile ? setOpenProcedures(true) : navigate("/procedures")
          }
        />

        <CardJourney
          icon={<FileCopy sx={{ color: "primary700.main" }} />}
          text="Relatórios médicos"
          width="100%"
          notification={documentReferenceNote?.length}
          onClick={() =>
            isMobile
              ? setOpenMedicalCertificate(true)
              : navigate("/medicalnote")
          }
        />

        <CardJourney
          icon={<Search sx={{ color: "primary700.main" }} />}
          text="Agendamento"
          width="100%"
          onClick={() => navigate("/schedulingsearch")}
        />
        <CardJourney
          icon={<Restore sx={{ color: "primary700.main" }} />}
          text="Histórico de acessos"
          width="100%"
          onClick={() => navigate("/history")}
        />
      </Stack>

      {openFamily && (
        <Myfamily open={openFamily} onClose={() => setOpenFamily(false)} />
      )}
      {openMedicalPrescription && (
        <ExamSolicitation
          open={openMedicalPrescription}
          onClose={() => setOpenMedicalPrescription(false)}
        />
      )}
      {openMedicalRequest && (
        <MedicalPrescription
          open={openMedicalRequest}
          onClose={() => setOpenMedicalRequest(false)}
        />
      )}
      {openMedicalCertificate && (
        <MedicalNote
          open={openMedicalCertificate}
          onClose={() => setOpenMedicalCertificate(false)}
        />
      )}

      {openProcedures && (
        <Procedures
          open={openProcedures}
          onClose={() => setOpenProcedures(false)}
        />
      )}
      {openNewMedication && (
        <NewMedication
          open={openNewMedication}
          onClose={() => setOpenNewMedication(false)}
        />
      )}
    </Stack>
  );
}
